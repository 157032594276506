import React from "react";
import SnackbarMessage from "../Shared/SnackbarMessage/SnackbarMessage";
import ReactDom from "react-dom";

const ShowPaymentLinkCell = (props) => {
  const value = props.getValue();
  let truncatedValues = value.substring(0, 10) + "...";

  return (
    <div className="link-container">
      {/* <a
        rel="noreferrer"
        href={value}
        target="_blank"
        style={{ color: "#0092ff" }}
      > */}
      {truncatedValues}
      {/* </a> */}
      <img
        onClick={() => {
          navigator.clipboard.writeText(value);
          ReactDom.render(
            <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
            document.getElementById("snackbar")
          );
        }}
        src="/images/copy-icon.svg"
        alt="copy"
      />
    </div>
  );
};

export default ShowPaymentLinkCell;
