import React from "react";
import ReactDOM from "react-dom";
import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// STYLES
import "./DataTableModal.scss";
// UTILS
import FormatBalance from "../../../utilities/FormatBalance";

function DataTableModal({ data }) {
  return (
    <div>
      <ModalComponent {...(data = data)} />
    </div>
  );
}

function ModalComponent(data) {
  // Modal unmount function
  let user = JSON.parse(localStorage.getItem("user"));

  const closeTableDetailModal = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("virtual-accounts-components")
    );
  };
  return (
    <React.Fragment>
      <Dialog
        className="va-details"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-popup">
          <CloseIcon onClick={closeTableDetailModal} />
        </div>
        <DialogContent>
          <div className="va-details-heading">
            <p>Virtual Account Details</p>
          </div>
          <div className="va-details-container_va">
            {/* LEFT - BLOCK */}

            <div className="left-detail-block">
              <table>
                <tbody>
                  <tr>
                    <th>Account Name</th>
                    <td>{data.account_name}</td>
                  </tr>

                  <tr>
                    <th>Company Name</th>
                    <td>{user?.name}</td>
                  </tr>

                  <tr>
                    <th>Customer ID</th>
                    <td>{data.customer_id}</td>
                  </tr>

                  <tr>
                    <th>Mobile Number</th>
                    <td>{data.mobile_number}</td>
                  </tr>

                  <tr>
                    <th>Virtual Account Number</th>
                    <td>{data.account_number}</td>
                  </tr>

                  <tr>
                    <th>IFSC Code</th>
                    <td>{data.ifsc_code}</td>
                  </tr>

                  {data?.upi_id && (
                    <tr>
                      <th>UPI ID</th>
                      <td>{data?.upi_id}</td>
                    </tr>
                  )}

                  {data?.display_name && (
                    <tr>
                      <th>Provider Bank</th>
                      <td>{data?.display_name}</td>
                    </tr>
                  )}

                  <tr>
                    <th>Current Balance</th>
                    <td>₹ {FormatBalance(data.current_balance)}</td>
                  </tr>

                  <tr>
                    <th>Allowed Methods</th>
                    <td>NEFT, IMPS, RTGS, UPI</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* RIGHT - BLOCK */}

            <div className="right-qr-block-va">
              <img
                className="qr-image-va"
                src="/images/No QR.svg"
                alt="QR code not found"
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default DataTableModal;
