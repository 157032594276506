import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Divider, TextField } from "@material-ui/core";
import KeyboardArrowDownSharp from "@material-ui/icons/KeyboardArrowDownSharp";
import track, { useTracking } from "react-tracking";
import "./MoneyTransferDetails.scss";
import DropdownSelect from "../RecurringCollection/Emandate/EmandateDataTable/components/SelectComponent/DropdownSelect";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/core_banking/endpoint";
function MoneyTransferDetails(props) {
  const { trackEvent } = useTracking();
  let username;
  let useremail;
  const closeAccountDetailsDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("virtual-accounts-components")
    );
  };

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0092ff",
      },
    },
  });

  const classes = useStyles();

  const [customerDetails, setCustomerDetails] = React.useState({
    purposeMessageInput: "",
    mobileInput: "",
    transferAmountInput: "",
    customerIdInput: "",
    fromAccountInput: "",
  });

  let [inputData, setInputData] = React.useState({
    reference_id: "",
    purpose_message: "",
    mobile_number: "",
    transfer_amount: "",
    from_customer_id: "",
    from_account: "",
    currency_code: "INR",
  });
  const [fromOptions, setFromOptions] = React.useState([]);
  async function fetchFromAccountOptions() {
    APIConfig.API_Client.post(
      apiEndpointList.TRANSFER_ACCOUNT_LIST.baseUrl +
        apiEndpointList.TRANSFER_ACCOUNT_LIST.endpoint
    ).then((res) => {
      setFromOptions(
        res.data.map((item) => ({
          label: item.va_numbers,
          value: item.va_numbers,
        }))
      );
    });
  }
  useEffect(() => {
    fetchFromAccountOptions();
  }, []);
  const [inputValidation, setInputValidation] = React.useState({
    purposeMessageError: "",
    mobileNoError: "",
    transferAmountError: "",
    referenceIdError: "",
    customerIdError: "",
    fromAccountError: "",
    // toUPIError: "",
  });

  const buttonValidations = !(
    Object.keys(inputData).length === 8 &&
    !Object.values(inputData).includes("")
  );

  useEffect(() => {
    let temp = JSON.parse(localStorage.getItem("user"));
    username = temp.name;
    useremail = temp.email;
  }, []);

  const handleBlur = (e) => {
    let { name, value } = e.target;
    if (name == "reference_id") {
      let referenceIdRegex = /^[a-z0-9-]+$/;
      if (!value.match(referenceIdRegex)) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          referenceIdError: "Invalid format",
        });
      }
    }
    if (name === "purpose_message") {
      let purposeMessageRegex = /^[^*|":<>[\]{}`\\()';@&$]+$/;
      if (!value.match(purposeMessageRegex)) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          purposeMessageError: "Invalid format",
        });
      }
    }

    if (name === "mobile_number") {
      let mobileRegex = /^[6-9]\d{9}$/;
      if (!value.match(mobileRegex)) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          mobileNoError: "Invalid format",
        });
      }
    }

    if (name === "transfer_amount") {
      let transferAmountRegex = /^\d*(\.\d+)?$/;
      if (
        !value.match(transferAmountRegex) ||
        value.length == 0 ||
        parseInt(value) == 0
      ) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          transferAmountError: "Invalid format",
        });
      }
    }

    if (name === "from_customer_id") {
      // let customerIdRegex = /^[a-zA-Z0-9]+$/;
      let customerIdRegex = /^[a-z0-9-]+$/;
      if (!value.match(customerIdRegex) || value.length == 0) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          customerIdError: "Invalid format",
        });
      }
    }
    if (name === "from_account") {
      let fromAccountRegex = /^\d{9,18}$/;
      if (!value.match(fromAccountRegex)) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          fromAccountError: "Invalid format",
        });
      }
    }
  };

  const handleContactDetails = (e) => {
    let { name, value } = e.target;

    if (name === "reference_id") {
      let referenceIdRegex = /^[a-z0-9-]+$/;
      if (value.match(referenceIdRegex)) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, referenceIdError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
      }
    }

    if (name === "purpose_message") {
      setCustomerDetails({ ...customerDetails, purposeMessageInput: value });
      let purposeMessageRegex = /^[^*|":<>[\]{}`\\()';@&$]+$/;

      if (value.match(purposeMessageRegex)) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, purposeMessageError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
      }
    }

    if (name === "mobile_number") {
      setCustomerDetails({ ...customerDetails, mobileInput: value });
      let mobileRegex = /^[6-9]\d{9}$/;

      if (value.match(mobileRegex)) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, mobileNoError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
      }
    }

    if (name === "transfer_amount") {
      setCustomerDetails({ ...customerDetails, transferAmountInput: value });
      let transferAmountRegex = /^\d*(\.\d+)?$/;

      if (value.match(transferAmountRegex) && value.length > 0) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, transferAmountError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
      }
    }

    if (name === "from_customer_id") {
      let customerIdRegex = /^[a-zA-Z0-9]+$/;
      setCustomerDetails({ ...customerDetails, customerIdInput: value });
      if (value.match(customerIdRegex)) {
        if (value.length > 0) {
          setInputData({ ...inputData, [name]: value.trim() });
          setInputValidation({ ...inputValidation, customerIdError: "" });
        } else {
          setInputData({ ...inputData, [name]: "" });
        }
      } else {
        setInputData({ ...inputData, [name]: "" });
      }
    }
    if (name === "from_account") {
      setCustomerDetails({ ...customerDetails, fromAccountInput: value });
      setInputData({ ...inputData, [name]: value });
      setInputValidation({ ...inputValidation, fromAccountError: "" });
    }
  };

  const customerInputDetails = (e) => {
    trackEvent({
      action: "Next button Clicked",
      username: username,
      useremail: useremail,
      CTA_clciked_at: new Date(),
      entity: "NextBtn",
    });
    props.customerInputDetail(inputData);
  };

  return (
    <React.Fragment>
      <Dialog
        className="money-transfer"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-popup">
          <CloseIcon onClick={closeAccountDetailsDialog} />
        </div>

        <DialogContent>
          <div className="money-transfer-heading">
            <p>Transfer Money</p>
          </div>
          <p className="money-transfer-subtitle">
            Fill details to initiate money transfer.
          </p>
          <p className="money-transfer-subtitle-all">
            All the fields are Mandatory
          </p>

          <div className="money-transfer-container">
            <div className="field-input">
              <label htmlFor="reference-id-label">
                <span className="label">Reference ID</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.referenceIdError) && classes.root
                } w-beneficiary-input`}
                id="reference-id-label"
                name="reference_id"
                variant="outlined"
                placeholder="Enter reference ID"
                value={inputData.reference_id}
                onChange={handleContactDetails}
                onBlur={handleBlur}
                error={Boolean(inputValidation.referenceIdError)}
                autoComplete="off"
                spellCheck="false"
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.referenceIdError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid input icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="customer-id">
                <span className="label">Customer ID</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.customerIdError) && classes.root
                } w-beneficiary-input`}
                id="customer-id"
                name="from_customer_id"
                variant="outlined"
                placeholder="Enter customer's ID"
                value={customerDetails.customerIdInput}
                error={Boolean(inputValidation.customerIdError)}
                autoComplete="off"
                spellCheck="false"
                onBlur={handleBlur}
                inputProps={{ maxLength: 32 }}
                onChange={handleContactDetails}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.customerIdError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid input icon"
              />
            </div>

            <div className="transfer-provider-code">
              <label htmlFor="provider-code-dropmenu">
                <span className="label">Transfer type</span>
              </label>
              <TransferType
                setInputData={setInputData}
                inputData={inputData}
                customerDetails={customerDetails}
                setCustomerDetails={setCustomerDetails}
              />
            </div>

            <div className="field-input">
              <label htmlFor="from-account">
                <span className="label">From Account</span>
              </label>
              <DropdownSelect
                className="w-beneficiary-input"
                itemId="from_account_dropdown"
                placeholder="Enter Account Number"
                searchable
                options={fromOptions}
                setSelectedOption={(e) => {
                  handleContactDetails({
                    target: {
                      name: "from_account",
                      value: e,
                    },
                  });
                }}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.fromAccountError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid input icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="transfer-amount">
                <span className="label">Transfer amount</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.transferAmountError) && classes.root
                } w-beneficiary-input`}
                id="transfer-amount"
                name="transfer_amount"
                variant="outlined"
                placeholder="Enter transfer amount "
                value={customerDetails.transferAmountInput}
                error={Boolean(inputValidation.transferAmountError)}
                autoComplete="off"
                inputProps={{ maxLength: 7, pattern: "^d*(.d+)?$" }}
                spellCheck="false"
                onBlur={handleBlur}
                onChange={handleContactDetails}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.transferAmountError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid input icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="purposeMessage-label">
                <span className="label">Purpose message</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.purposeMessageError) && classes.root
                } w-beneficiary-input`}
                id="purposeMessage-label"
                name="purpose_message"
                variant="outlined"
                placeholder="Enter purpose message"
                value={customerDetails.purposeMessageInput}
                error={Boolean(inputValidation.purposeMessageError)}
                autoComplete="off"
                spellCheck="false"
                onBlur={handleBlur}
                onChange={handleContactDetails}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.purposeMessageError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid input icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="mobile-number">
                <span className="label">Mobile Number</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.mobileNoError) && classes.root
                } w-beneficiary-input`}
                id="mobile-number"
                name="mobile_number"
                variant="outlined"
                placeholder="Enter customer’s mobile number"
                value={customerDetails.mobileInput}
                error={Boolean(inputValidation.mobileNoError)}
                autoComplete="off"
                inputProps={{ maxLength: 10 }}
                onBlur={handleBlur}
                onChange={handleContactDetails}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.mobileNoError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid input icon"
              />
            </div>

            <div className="next-btn-container">
              <button
                disabled={buttonValidations}
                onClick={customerInputDetails}
                className={`submit-transfer-details
              ${buttonValidations ? "" : "active-next-btn"}`}
              >
                Next
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function TransferType(props) {
  const [TaskMenuItem, setTaskMenuItem] = React.useState(
    "Select Transfer type"
  );
  let { transfer_type } = props.inputData;
  React.useEffect(() => {
    props.setCustomerDetails({
      ...props.customerDetails,
      toUPIInput: "",
    });

    delete props.inputData["to_upi"];
  }, [transfer_type]);

  return (
    <div className="provider-dropdown">
      <span className="provider-text" onClick={() => ToggleActionMenu()}>
        {TaskMenuItem === "Select Transfer type" ? (
          <span className="placeholder-text">{TaskMenuItem}</span>
        ) : (
          <span className="provider-text-span">{TaskMenuItem}</span>
        )}
        <KeyboardArrowDownSharp />
      </span>
      <div className="provider-bank-option">
        <div className="provider-options">
          <div
            onClick={() => {
              document
                .querySelector(".provider-text")
                .classList.remove("showl");
              document
                .querySelector(".provider-bank-option")
                .classList.remove("active");

              setTaskMenuItem("NEFT");
              props.setInputData({ ...props.inputData, transfer_type: "NEFT" });
            }}
            className="option"
          >
            NEFT
          </div>

          <div
            onClick={() => {
              document
                .querySelector(".provider-bank-option")
                .classList.remove("active");
              document
                .querySelector(".provider-text")
                .classList.remove("showl");
              setTaskMenuItem("IMPS");
              props.setInputData({ ...props.inputData, transfer_type: "IMPS" });
            }}
            className="option"
          >
            IMPS
          </div>

          <div
            onClick={() => {
              document
                .querySelector(".provider-bank-option")
                .classList.remove("active");
              document
                .querySelector(".provider-text")
                .classList.remove("showl");
              setTaskMenuItem("RTGS");
              props.setInputData({ ...props.inputData, transfer_type: "RTGS" });
            }}
            className="option"
          >
            RTGS
          </div>

          <div
            onClick={() => {
              document
                .querySelector(".provider-bank-option")
                .classList.remove("active");
              document
                .querySelector(".provider-text")
                .classList.remove("showl");
              setTaskMenuItem("UPI");
              props.setInputData({ ...props.inputData, transfer_type: "UPI" });
            }}
            className="option"
          >
            UPI
          </div>
        </div>
      </div>
    </div>
  );
}

function ToggleActionMenu() {
  document.querySelector(".provider-bank-option").classList.toggle("active");
  document.querySelector(".provider-text").classList.add("showl");
}

export default MoneyTransferDetails;

export const TrackedMoneyTransferDetails = track(
  { entity: "Moneyy Transfer Form" },
  { dispatchOnMount: (contextData) => ({ action: "entityLoaded" }) }
)(MoneyTransferDetails);
