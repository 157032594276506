import React, { useContext } from "react";
import ReactDOM from "react-dom";

import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";

import SnackbarMessage from "../Shared/SnackbarMessage/SnackbarMessage";

import "./VirtualAccountsSuccessDialog.scss";

let successData;

function VirtualAccountsSuccessDialog(props) {
  const closeSuccessDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("virtual-accounts-components")
    );
    window.location.reload();
  };

  successData = props?.data;
  return (
    <React.Fragment>
      <Dialog
        className="virtual-accounts-success"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-success-popup">
          <CloseIcon onClick={closeSuccessDialog} />
        </div>

        <DialogContent>
          <div className="dialog-heading">
            <p>Create Virtual Accounts</p>
          </div>
          <div className="dialog-content-container">
            <div className="success-message">
              <img src="/images/onsuccess-tick.svg" alt="success-icon" />
              <h3>
                {props?.data?.length === 1
                  ? "Virtual Account Created Successfully!"
                  : "Virtual Accounts Created Successfully!"}
              </h3>
            </div>
            {props?.data?.length === 1 ? <BankDetail /> : <BankDetailTab />}
            <div className="success-btn-container">
              <button onClick={closeSuccessDialog} className="success-btn-done">
                Done
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function BankDetailTab(props) {
  const [toggleState, setToggleState] = React.useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const copyToClipBoard = async (copyLink) => {
    try {
      await navigator.clipboard.writeText(copyLink);
      ReactDOM.render(
        <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
        document.getElementById("snackbar")
      );
    } catch (err) { }
  };
  return (
    <React.Fragment>
      <div className="container">
        <div className="tabs-block">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            {successData[0]?.bank.includes("ICICI") === true
              ? "ICICI BANK"
              : "YES BANK"}
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            {successData[1]?.bank.includes("ICICI") === true
              ? "ICICI BANK"
              : "YES BANK"}
          </button>
        </div>

        <div className="content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <div className="success-account-details">
              <table>
                <tbody>
                  <tr>
                    <td className="accounts-heading">Account Number</td>
                    <td className="accounts-details">
                      {successData[0]?.accountNumber}
                      <img
                        onClick={() =>
                          copyToClipBoard(successData[0]?.accountNumber)
                        }
                        className="copy-link-icon"
                        src="/images/copy-icon.svg"
                        alt="copy-icon"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="accounts-heading">IFSC Code</td>
                    <td className="accounts-details">
                      {successData[0]?.ifsc}
                      <img
                        onClick={() => copyToClipBoard(successData[0]?.ifsc)}
                        className="copy-link-icon"
                        src="/images/copy-icon.svg"
                        title="Copy text"
                        alt="copy-icon"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="accounts-heading">Currency</td>
                    <td className="accounts-details">INR</td>
                  </tr>
                  <tr>
                    <td className="accounts-heading">Allowed Methods</td>
                    <td className="accounts-details">
                      {successData[0]?.allowedMethods
                        .toString()
                        .replace(/,/g, ", ")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <div className="success-account-details">
              <table>
                <tbody>
                  <tr>
                    <td className="accounts-heading">Account Number</td>
                    <td className="accounts-details">
                      {successData[1]?.accountNumber}
                      <img
                        onClick={() =>
                          copyToClipBoard(successData[1]?.accountNumber)
                        }
                        className="copy-link-icon"
                        src="/images/copy-icon.svg"
                        alt="copy-icon"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="accounts-heading">IFSC Code</td>
                    <td className="accounts-details">
                      {successData[1]?.ifsc}
                      <img
                        onClick={() => copyToClipBoard(successData[1]?.ifsc)}
                        className="copy-link-icon"
                        src="/images/copy-icon.svg"
                        alt="copy-icon"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="accounts-heading">Currency</td>
                    <td className="accounts-details">INR</td>
                  </tr>
                  <tr>
                    <td className="accounts-heading">Allowed Methods</td>
                    <td className="accounts-details">
                      {successData[1]?.allowedMethods
                        .toString()
                        .replace(/,/g, ", ")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function BankDetail(props) {
  const copyToClipBoard = async (copyLink) => {
    try {
      await navigator.clipboard.writeText(copyLink);
      ReactDOM.render(
        <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
        document.getElementById("snackbar")
      );
    } catch (err) { }
  };
  return (
    <React.Fragment>
      <div className="success-account-details">
        <table>
          <tbody>
            <tr>
              <td className="accounts-heading">Account Number</td>
              <td className="accounts-details">
                {successData[0]?.accountNumber}
                <img
                  onClick={() => copyToClipBoard(successData[0]?.accountNumber)}
                  className="copy-link-icon"
                  src="/images/copy-icon.svg"
                  alt="copy-icon"
                />
              </td>
            </tr>
            <tr>
              <td className="accounts-heading">IFSC Code</td>
              <td className="accounts-details">
                {successData[0]?.ifsc}
                <img
                  onClick={() => copyToClipBoard(successData[0]?.ifsc)}
                  className="copy-link-icon"
                  src="/images/copy-icon.svg"
                  title="Copy text"
                  alt="copy-icon"
                />
              </td>
            </tr>
            <tr>
              <td className="accounts-heading">Currency</td>
              <td className="accounts-details">INR</td>
            </tr>
            <tr>
              <td className="accounts-heading">Allowed Methods</td>
              <td className="accounts-details">
                {successData[0]?.allowedMethods.toString().replace(/,/g, ", ")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default VirtualAccountsSuccessDialog;
