import React, { useContext } from "react";
import ReactDOM from "react-dom";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";
import SnackbarMessage from "../Shared/SnackbarMessage/SnackbarMessage";
import "./UpiCollectionSuccessDialog.scss";
import { PaymentsCollectionContextProvider } from "../PaymentsCollectionDataTable/PaymentsCollectionContext";

let successDetails;

function UpiCollectionSuccessDialog(props) {
  // const { refreshTable } = useContext(PaymentsCollectionContextProvider);
  const closeSuccessDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("payments-components")
    );
    // refreshTable();
  };

  const copyToClipBoard = async (copyLink) => {
    try {
      await navigator.clipboard.writeText(copyLink);
      ReactDOM.render(
        <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
        document.getElementById("snackbar")
      );
    } catch (err) {}
  };

  successDetails = props.successResponse;

  return (
    <React.Fragment>
      <Dialog
        className="upi-collection-success"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-success-popup">
          <CloseIcon onClick={closeSuccessDialog} />
        </div>

        <DialogContent>
          <div className="dialog-heading">
            <p>UPI Collection Request</p>
          </div>
          <div className="dialog-content-container">
            <div className="success-message">
              <img src="/images/onsuccess-tick.svg" alt="success-icon" />
              <h3>Transaction initiated successfully!</h3>
              <p>{successDetails.message}</p>
            </div>
            <div className="success-account-details">
              <table>
                <tbody>
                  <tr>
                    <td className="accounts-heading">Transaction Status</td>
                    <td className="accounts-details title-case">
                      {successDetails.data.transactionStatus}
                    </td>
                  </tr>
                  <tr>
                    <td className="accounts-heading">Transaction Id</td>
                    <td className="accounts-details">
                      {successDetails.data.transactionId}
                      <img
                        onClick={() =>
                          copyToClipBoard(successDetails.data.transactionId)
                        }
                        className="copy-link-icon"
                        src="/images/copy-icon.svg"
                        title="Copy text"
                        alt="copy-icon"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="accounts-heading">Bank Reference Number</td>
                    <td className="accounts-details">
                      {successDetails.data.bankReferenceNumber}
                      <img
                        onClick={() =>
                          copyToClipBoard(
                            successDetails.data.bankReferenceNumber
                          )
                        }
                        className="copy-link-icon"
                        src="/images/copy-icon.svg"
                        title="Copy text"
                        alt="copy-icon"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="accounts-heading">NPCI Transaction Id</td>
                    <td className="accounts-details">
                      {successDetails.data.npciTransactionId}
                      <img
                        onClick={() =>
                          copyToClipBoard(successDetails.data.npciTransactionId)
                        }
                        className="copy-link-icon"
                        src="/images/copy-icon.svg"
                        title="Copy text"
                        alt="copy-icon"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="success-btn-container">
              <button onClick={closeSuccessDialog} className="success-btn-done">
                Done
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default UpiCollectionSuccessDialog;
