import React, { useState, useEffect, useRef, useMemo } from "react";

// styles import
import "./DropDownTypeReport.scss";

function DropDownTypeReport({
  options = [],
  setSelectedOption = (e) => e,
  selectedOption,
  itemId,
  placeholder = "Select Option",
  searchable = false,
  style = {},
  className = "",
  showArrow,
  disabledField = false,
}) {
  function ToggleActionMenu() {
    document.querySelector(`.${itemId}`).classList.toggle("active-dropdown");
    document.querySelector(`.${itemId}-show`).classList.toggle("show-border");
    if (
      searchValue !== localSelectedOption &&
      localSelectedOption !== placeholder
    ) {
      setSearchValue(localSelectedOption);
    }
  }
  const [searchValue, setSearchValue] = useState("");
  const [localSelectedOption, setLocalSelectedOption] = React.useState(
    selectedOption ?? placeholder
  );
  const [MenuActive, setMenuActive] = React.useState(false);

  const optionsToUse = useMemo(() => {
    if (options.length < 1) {
      return "";
    }
    if (searchable) {
      return options
        .filter((item) =>
          item.reportLabel?.toLowerCase().includes(searchValue?.toLowerCase())
        )
        .filter(
          (item) =>
            item.reportLabel !== searchValue ||
            (item.reportLabel === searchValue &&
              item.reportLabel !== localSelectedOption)
        );
    }

    return options.filter((data) => data.reportLabel !== localSelectedOption);
  }, [options, searchValue]);

  const itemRef = useRef(null);
  function handleOutsideClick(e) {
    if (e.target !== itemRef.current && !itemRef.current?.contains(e.target)) {
      document.querySelector(`.${itemId}`).classList.remove("active-dropdown");
      document.querySelector(`.${itemId}-show`).classList.remove("show-border");
      // * down arrow flip state
      setMenuActive(false);
      if (
        searchValue !== localSelectedOption &&
        localSelectedOption !== placeholder
      ) {
        setSearchValue(localSelectedOption);
      }
    }
  }

  function handleFilter(e) {
    setSearchValue(e.target.reportType);
    // ToggleActionMenu();
    if (
      document.querySelector(`.${itemId}`).classList.contains("active-dropdown")
    ) {
      return;
    }
    document.querySelector(`.${itemId}`).classList.add("active-dropdown");
    document.querySelector(`.${itemId}-show`).classList.add("show-border");
  }

  useEffect(() => {
    document.removeEventListener("click", handleOutsideClick);
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  });

  return (
    <div
      style={style}
      ref={itemRef}
      className={`provider-dropdowns ${className} ${
        MenuActive ? "max-z-index" : ""
      } ${disabledField ? "reportType-disable-dropdown" : ""}`}
    >
      <span
        className={`${itemId}-show provider-show`}
        onClick={() => {
          setMenuActive(!MenuActive);
          ToggleActionMenu();
        }}
      >
        {searchable ? (
          <input
            className="dropdown-input"
            onChange={handleFilter}
            style={{ width: "100%" }}
            value={searchValue}
            placeholder={placeholder}
          />
        ) : localSelectedOption === placeholder ? (
          <span className="placeholder-text">{localSelectedOption}</span>
        ) : (
          <span className="provider-text-er">{localSelectedOption}</span>
        )}
        {showArrow !== false ? (
          //  <KeyboardArrowDownSharp />
          <img
            style={{
              transform: MenuActive ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="/images/arrow-down.svg"
            alt="dropdwon-arrow"
          />
        ) : (
          ""
        )}
      </span>
      <div className={`${itemId} provider-bank-option`}>
        <div className="provider-options">
          {optionsToUse ? (
            optionsToUse
              .filter((data) => data.reportLabel !== selectedOption)
              .map((data, index) => (
                <div key={index}>
                  <div
                    onClick={() => {
                      setMenuActive(!MenuActive);
                      setLocalSelectedOption(data.reportLabel);
                      setSelectedOption(data.reportType);
                      ToggleActionMenu();
                      if (searchable) {
                        setSearchValue(data.reportLabel);
                      }
                    }}
                    className="option"
                  >
                    {data?.reportLabel}
                  </div>
                </div>
              ))
          ) : (
            <span style={{ padding: "10px 0", paddingLeft: "10px" }}>
              Loading...
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default DropDownTypeReport;
