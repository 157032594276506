import React from "react";
import TransactionModal from '../TransactionModal/TransactionModal';
import  ReactDOM  from 'react-dom';

const ShowTransactionCell = (props) => {
  const rowDatadata = props.row.original
  const value = props.getValue();
  
  function openTransactionDialog(){
    ReactDOM.render(
        <TransactionModal data={rowDatadata} />,
        document.getElementById("virtual-accounts-components")
      );
  }
  return (
    <div style={{"color":"#0092ff","cursor":"pointer"}} onClick={openTransactionDialog}>
      {value}
    </div>
  );
};

export default ShowTransactionCell;
