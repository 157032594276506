import React, { createContext, useState, useEffect } from "react";
import apiEndpointList from "../../config/core_banking/endpoint";
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";

export const KycAadharContextProvider = createContext();

const KycAadharContext = ({ children }) => {
  const [tableData, setTableData] = useState([]);
  const [isLazyFetching, setIsLazyFetching] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [initialLoading, setInitialLoading] = useState(tableData?.length === 0);
  const [newTokenVa, setNewToken] = useState(null);
  const [noDataFound, setNoDataFound] = useState(false);
  const [firstApiCall, setfirstApiCall] = useState(true);
  const [refreshing, setIsRefreshing] = useState(false);
  let [totalcountNotset, settotalcountNotset] = useState(true);
  const [isError, setError] = useState(false);
  const [errorResponse, setErrorResponse] = useState();
  const limit = 200;
  const [filterList, setFilterList] = useState({
    search: "",
    resultsPerPage: 10,
    currentPage: 1,
  });
  let lastInterval;
  let initialStartDate = new Date();
  const [dateFilters, setDateFilters] = useState([
    initialStartDate,
    new Date(),
  ]);
  initialStartDate.setDate(initialStartDate.getDate() - 14);

  const getDateFormatted = (date) => {
    const dateObj = new Date(date);
    const month = dateObj.getMonth() + 1;
    const dateTime = dateObj.getDate();
    return `${dateObj.getFullYear()}-${month > 9 ? month : `0${month}`}-${
      dateTime > 9 ? dateTime : `0${dateTime}`
    }`;
  };
  const [lastApiTime, setLastApiTime] = useState(0);

  const fetchData = async (
    lastItem,
    filterData,
    customDate,
    clearOld,
    isLazy
  ) => {
    if (filterList.search && isLazy) return;

    if (!isLazy && firstApiCall) {
      setIsLoading(true);
    } else {
      setIsLazyFetching(true);
    }
    if (newTokenVa) {
      newTokenVa.cancel();
      setNewToken(null);
    }
    const newToken = axios.CancelToken.source();
    setNewToken(newToken);

    let filters = filterData ?? filterList;
    let dateData = customDate ?? dateFilters;
    const countApiPath =
      apiEndpointList.FETCH_KYC_ANDHAAR_XML_COUNT.baseUrl +
      apiEndpointList.FETCH_KYC_ANDHAAR_XML_COUNT.endpoint;

    const dataApiPath =
      apiEndpointList.FETCH_KYC_ANDHAAR_XML_DETAILS.baseUrl +
      apiEndpointList.FETCH_KYC_ANDHAAR_XML_DETAILS.endpoint;
    const startIndex = (filterList.currentPage - 1) * filterList.resultsPerPage;
    const endIndex = startIndex + filterList.resultsPerPage;
    const searchTerm = filters?.search !== "" ? filters.search : null;
    let body = {
      ...(searchTerm
        ? {
            decentro_company_urn: searchTerm,
            offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
            limit,
          }
        : {
            limit,
            end_date: getDateFormatted(dateData[1]),
            offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
            start_date: getDateFormatted(dateData[0]),
          }),
    };
    const userData = await APIConfig.API_Client.post(dataApiPath, body, {
      cancelToken: newToken.token,
    })
      .then((res) => {
        setIsRefreshing(false);
        setInitialLoading(false);
        setIsLazyFetching(false);
        if (res.data?.length >= 0) {
          if ((filters.currentPage === 1 || clearOld) && !isLazy) {
            setTableData(res.data);
          } else {
            setTableData([...tableData, ...res.data]);
          }
        }
        return res.data;
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          console.log(e);
        } else {
          setError(true);
          setErrorResponse(e);
        }
      });

    let promiseList = [userData];
    if (!isLazy && firstApiCall) {
      const dataCount = APIConfig.API_Client.post(countApiPath, body, {
        cancelToken: newToken.token,
      })
        .then((res) => {
          return res.data;
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            console.log(e);
          } else {
            setError(true);
            setErrorResponse(e);
          }
        });
      promiseList.push(dataCount);
    }
    axios
      .all(promiseList)
      .then((res) => {
        setIsLoading(false);
        setIsLazyFetching(false);
        if (!res[0]?.length) {
          setNoDataFound(true);
        }
        setTotalCount(res[1][0].total_count);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    lastInterval = setInterval(() => {
      setLastApiTime((pre) => pre + 1);
    }, 60000);
    return () => {
      if (lastInterval) {
        clearInterval(lastInterval);
      }
      if (newTokenVa) {
        newTokenVa.cancel();
      }
    };
  }, []);

  return (
    <KycAadharContextProvider.Provider
      value={{
        setDateFilters,
        setFilterList,
        setInitialLoading,
        setIsLazyFetching,
        setIsLoading,
        setNewToken,
        setSelectedTransaction,
        setTotalCount,
        setTableData,
        fetchData,
        setNoDataFound,
        getDateFormatted,
        isLazyFetching,
        isLoading,
        tableData,
        filterList,
        limit,
        totalCount,
        noDataFound,
        initialLoading,
        selectedTransaction,
        dateFilters,
        lastApiTime,
        setLastApiTime,
        lastInterval,
        settotalcountNotset,
        setIsRefreshing,
        refreshing,
        setError,
        setErrorResponse,
        isError,
        errorResponse,
        setfirstApiCall,
        firstApiCall,
      }}
    >
      {children}{" "}
    </KycAadharContextProvider.Provider>
  );
};

export default KycAadharContext;
