import React, { useEffect } from "react";
import "./DateSelectorSingle.scss";
import PropTypes from "prop-types";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import subDays from "date-fns/subDays";
import subMonths from "date-fns/subMonths";

const { afterToday, before, combine } = DateRangePicker;
const Locale = {
  sunday: "Sun",
  monday: "Mon",
  tuesday: "Tue",
  wednesday: "Wed",
  thursday: "Thu",
  friday: "Fri",
  saturday: "Sat",
  ok: "Apply",
  formattedMonthPattern: "MMM YYYY",
};

const ranges = [
  {
    label: "today",
    value: [new Date(), new Date()],
  },
  {
    label: "yesterday",
    value: [subDays(new Date(), 1), subDays(new Date(), 1)],
  },
];

let initialStartDate = new Date();
initialStartDate.setDate(initialStartDate.getDate() - 1);

const DateSelectorSingle = (props) => {
  let [selectedDateValue, setSelectedDateValue] = React.useState(
    props.selectedDate
  );

  // const updateDateToSingle = () => {
  //     var dateElement = document.getElementsByClassName('rs-picker-toggle-value')[0]
  //     dateElement.innerText = dateElement.innerText.split("~")[0]
  //     console.log(dateElement.innerText)
  // }

  // useEffect(() => {
  //     console.log("In Effect")
  //     if (document.getElementsByClassName('dataTables_wrapper no-footer')) {
  //         console.log("Updating now...")
  //         updateDateToSingle();
  //     }
  //     else {
  //         console.log("Not found")
  //     }
  // }, [selectedDateValue])

  return (
    <div className="field">
      <DateRangePicker
        format="DD MMM 'YY"
        cleanable={false}
        appearance="default"
        locale={Locale}
        disabledDate={combine(before(subMonths(new Date(), 6)), afterToday())}
        oneTap
        showOneCalendar
        value={selectedDateValue}
        onChange={(item) => {
          setSelectedDateValue(item);
          props.setSelectedDate(item);
        }}
        ranges={ranges}
      />
    </div>
  );
};

DateSelectorSingle.propTypes = {
  selectedDate: PropTypes.any,
  setSelectedDate: PropTypes.any,
};

DateSelectorSingle.defaultProps = {
  selectedDate: [initialStartDate, initialStartDate],
  setSelectedDate: {},
};

export default DateSelectorSingle;
