import React, { useContext } from "react";
import { BeneficiaryContextProvider } from "./ManageBeneficiaryContext";
import "./EditCell.scss";

const EditCell = (props) => {
  const { setSelectedAccount, setShowModalDialog, setShowModify } = useContext(
    BeneficiaryContextProvider
  );
  const viewTransactions = (props) => {
    if (props.row.original !== undefined) {
      setSelectedAccount(props.row.original);
      setShowModalDialog(true);
      setShowModify(true);
    }
  };

  return (
    <>
      <a onClick={() => viewTransactions(props)}>Edit</a>
    </>
  );
};
export default EditCell;
