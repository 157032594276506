// React Imports
import { useContext, useEffect, useState } from "react";
// Contexts
import CallbackActionContext from "../../../../contexts/CallbackActionContext";
// Styles
import "./HttpTypeDropDownStyles.scss";
// Utils
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/core_banking/endpoint";
import HandleOutsideClick from "../../../Common/HandleOutsideClick/HandleOutsideClick";

const HttpTypeDropdown = ({ setHttpTypeName }) => {
  const [httpMethod, setHttpMethod] = useState("");
  const [selected, setSelected] = useState("Choose Http Type");
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const { tableEditData } = useContext(CallbackActionContext);

  const httpMethodList = () => {
    const check = localStorage.getItem("callbackMethodType");
    if (check) {
      setHttpMethod(JSON.parse(check));
    } else {
      setIsLoading(true)
      APIConfig.API_Client.post(
        apiEndpointList.CALLBACK_HTTP_METHOD_TYPE.baseUrl +
        apiEndpointList.CALLBACK_HTTP_METHOD_TYPE.endpoint
      )
        .then((response) => {
          setIsLoading(false)
          setHttpMethod(response.data);
        })
        .catch((error) => {
          setIsLoading(false)
          console.log("error from New Callback", error);
        });
    }
  };

  useEffect(() => {
    httpMethodList();
  }, []);

  useEffect(() => {
    if (tableEditData.edit) {
      setSelected(
        tableEditData?.item?.http?.replace(/_/g, " ") || "Choose HTTP Type"
      );
      setHttpTypeName(tableEditData?.item?.http || "Choose HTTP Type");
    }
  }, [tableEditData]);

  if (isLoading) {
    return (
      <div className="httptype-loader-skeleton">
        <span className="skeleton-loader"></span>
      </div>
    );
  }
  return (
    <div className="dropdown-http-type">
      <div className="dropdown-http-label">HTTP</div>
      <div
        className={`dropdown-btn ${tableEditData.edit || httpMethod.length < 1 ? "disabled" : ""} ${selected === "Choose Http Type" ? "text-light" : "text-dark"
          } ${isActive && " border-radius__invert"}`}
        onClick={(e) => httpMethod.length > 0 && setIsActive(!isActive)}
      >
        {httpMethod.length > 0 ? selected : "No Data Available"}
        {httpMethod.length > 0 && selected.length !== 1 && (
          <span>
            <DownArrow isActive={isActive} />
          </span>
        )}
      </div>
      {isActive &&
        <HandleOutsideClick onClickOutside={() => setIsActive(false)}>
          (
          <div className="http-type-content">
            {httpMethod.map((option) => (
              <div key={option.id}>
                {option.name !== selected && (
                  <div
                    key={option.id}
                    onClick={(e) => {
                      setSelected(option.name);
                      setIsActive(false);
                      setHttpTypeName(option.name);
                    }}
                    className="dropdown-item"
                  >
                    {option.name}
                  </div>
                )}
              </div>
            ))}
          </div>
          )
        </HandleOutsideClick>
      }
    </div>
  );
};
function DownArrow({ isActive }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-6 w-6 dropdown-arrow ${isActive && "dropdown-arrow__rotate"
        }`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}
export default HttpTypeDropdown;
