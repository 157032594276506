// React Imports
import React, { useEffect, useState } from "react";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
// Utils
import axios from "axios";
import jwt_decode from "jwt-decode";
import apiEndpointList from "../../config/core_banking/endpoint";
import { APIConfig } from "../../services/apiConfiguration.js";
import { getAccessToken } from "axios-jwt";
import { randomUUID } from "../../services/randomUUID";
// Components
import moment from "moment";
import { ReactMultiEmail } from "react-multi-email";
import unMountPreviousComponent from "../../utilities/unMountPreviousComponent";
import { Loader } from "rsuite";
import SuccessDialog from "../../components/ReportsSuccessDialog/SuccessDialog.js";
import FailureDialog from "../../components/Shared/FailureDialog/FailureDialog.js";
import DateSelectorReports from "../DateSelectorReports/DateSelectorReports.js";
import Tooltip from "../Shared/Tooltip/Tooltip";
import DropDownSelectReports from "./DropDownSelectReports/DropDownSelectReports";
import DropDownTypeReport from "./DropDownTypeReport/DropDownTypeReport.js";
import DropDownSelectFileType from "./DropDownSelectFileType/DropDownSelectFileType";
import DropDownSelectModules from "./DropDownSelectModules/DropDownSelectModules.js";
// postHog imports
import { capturePosthogEvent } from "../../utilities/posthogUtils.js";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils.js";
// styles import
import "./ReportsForm.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

function ReportsForm() {
  // adding empty array incase of no value in the local storage for default emails.
  const defaultEmails = !!localStorage.getItem("reportsDefaultEmail")
    ? [localStorage.getItem("reportsDefaultEmail")]
    : [];
  const [disabled, setDisabled] = useState(true);
  const [emails, setEmails] = useState(defaultEmails);

  const closeDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("reports-component")
    );
  };

  // to store report type from api
  const [reportModuleTypeList, setReportModuleTypeList] = useState([]);

  // to disable report type dropdown
  const [reportTypeDisable, setReportTypeDisable] = useState(false);

  // transformReportData  report_label renamed to reportLabel and report_type renamed to reportType:
  function transformReportData(reportData) {
    return reportData.map((report) => ({
      reportLabel: report.report_label,
      reportType: report.report_type,
    }));
  }

  const reportFormatTypeList = [
    {
      four_character_bank_code: "csv",
      provider_name: "CSV",
    },
    {
      four_character_bank_code: "xlsx",
      provider_name: "XLSX",
    },
    {
      four_character_bank_code: "json",
      provider_name: "JSON",
    },
  ];

  // const [getEmail, setGetEmail] = useState("");
  const getCommonName = localStorage.getItem("commonName");
  // const [getCommonName, setGetCommonName] = useState("");
  const [selectedOptionFormat, setSelectedOptionFormat] = React.useState(
    "Consolidated Account statement"
  );

  // & file format option selection
  const [selectedOption, setSelectedOption] = React.useState(
    reportFormatTypeList[0].provider_name
  );

  // & provider option selection
  const [selectedProvider, setSelectedProvider] = React.useState(null);
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [providerFieldDisabled, setProviderFieldDisabled] = useState(true);

  // & modules option selection
  const [selectedModule, setSelectedModule] = React.useState(null);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [moduleFieldDisabled, setModuleFieldDisabled] = useState(true);

  // & module report type selection
  const [selectedReportModuleType, setSelectedReportModuleType] =
    useState(null);

  let initialStartDate = new Date();
  initialStartDate.setDate(initialStartDate.getDate() - 30);

  let [selectedDateRange, setSelectedDateRange] = React.useState([
    initialStartDate,
    new Date(),
  ]);

  let selectedDateRangeForSummary = [
    selectedDateRange[0],
    selectedDateRange[1],
  ];

  const [finallist, setFinalist] = React.useState([]);
  const [modulesList, setModulesList] = React.useState([]);
  const [providerLoading, setProviderLoading] = React.useState(false);

  // ^ Provider List CAS
  function getProvider() {
    setProviderLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.ACCOUNTS_REPORTS_PROVIDER.baseUrl +
      apiEndpointList.ACCOUNTS_REPORTS_PROVIDER.endpoint,
      {
        // company_id: decodedCompanyId,
        report_type: selectedReportModuleType.toLowerCase(),
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        const responseResult = response.data.map((item) =>
          item.provider_name === "All"
            ? { ...item, four_character_bank_code: "all" }
            : item
        );
        setProviderLoading(false);
        setFinalist(responseResult);

        setProviderFieldDisabled(false);
      })
      .catch((error) => {
        setProviderFieldDisabled(true);
        setDisabled(true);
        setProviderLoading(false);
      });
  }

  // get report type
  function getReportType() {
    // setProviderLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.ACCOUNTS_REPORTS_TYPE_PROVIDER.baseUrl +
      apiEndpointList.ACCOUNTS_REPORTS_TYPE_PROVIDER.endpoint,
      {
        // company_id: decodedCompanyId,
        // report_type: selectedReportModuleType.toLowerCase(),
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        setReportModuleTypeList(transformReportData(response.data));
      })
      .catch((error) => {
        setReportTypeDisable(true);
      });
  }

  // to get the report type data from api
  useEffect(() => {
    getReportType();
  }, []);

  // ^ Getting Modules List
  function getModules() {
    APIConfig.API_Client.post(
      apiEndpointList.ACCOUNTS_REPORTS_MODULES_.baseUrl +
      apiEndpointList.ACCOUNTS_REPORTS_MODULES_.endpoint,
      { cancelToken: source.token }
    )
      .then((response) => {
        const responseResult = response.data.map((item) =>
          item.name === "All" ? { ...item, code: "all" } : item
        );
        // * Filters only active modules
        let onlyActiveModule = responseResult.filter(
          (moduleIsActive) => moduleIsActive.is_active === true
        );

        setModulesList(onlyActiveModule);
        setModuleFieldDisabled(false);
      })
      .catch((error) => {
        setModuleFieldDisabled(true);
        setDisabled(true);
      });
  }

  // ^ Reports Download CAS // Accounts
  async function reportsDownloadAccounts(data) {
    ReactDOM.render(
      <Loader className="reportsloader" />,
      document.getElementById("report-loader")
    );

    let casPayload =
      data.selectedProviderOrModule === "all"
        ? {
          reference_id: randomUUID(),
          to: moment(data.selectedDateRange[1].toString()).format(
            "YYYY-MM-DD"
          ),
          from: moment(data.selectedDateRange[0].toString()).format(
            "YYYY-MM-DD"
          ),
          to_emails: emails,
          response_format: data.selectedOption,
        }
        : {
          reference_id: randomUUID(),
          to: moment(data.selectedDateRange[1].toString()).format(
            "YYYY-MM-DD"
          ),
          from: moment(data.selectedDateRange[0].toString()).format(
            "YYYY-MM-DD"
          ),
          to_emails: emails,
          provider_code: selectedProvider,
          response_format: data.selectedOption,
        };

    await APIConfig.API_Client.post(
      apiEndpointList.ACCOUNTS_REPORTS.baseUrl +
      apiEndpointList.ACCOUNTS_REPORTS.endpoint,
      casPayload,
      { cancelToken: source.token }
    )
      .then((response) => {
        if (response) {
          unmountComponentAtNode(document.getElementById("report-loader"));
          unMountPreviousComponent("reports-component");
          ReactDOM.render(
            <SuccessDialog closeDialog={closeDialog} />,
            document.getElementById("reports-component")
          );
        } else {
          Promise.reject();
          ReactDOM.unmountComponentAtNode(
            document.getElementById("report-loader")
          );
          unMountPreviousComponent("reports-component");
          ReactDOM.render(
            <FailureDialog header={"Reports"} msg={"Something went wrong"} />,
            document.getElementById("failure-popup")
          );
        }
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("report-loader")
        );
        unMountPreviousComponent("reports-component");
        ReactDOM.render(
          <FailureDialog header={"Reports"} msg={error.message} />,
          document.getElementById("failure-popup")
        );
      });
  }

  // ^ Reports Download Credits Consumption
  async function reportsDownloadCreditConsumption(data, reportFileFormat) {
    ReactDOM.render(
      <Loader className="reportsloader" />,
      document.getElementById("report-loader")
    );
    let paymentsReportsPayload =
      data.selectedProviderOrModule === "all"
        ? {
          end_date: moment(data.selectedDateRange[1].toString()).format(
            "YYYY-MM-DD"
          ),
          start_date: moment(data.selectedDateRange[0].toString()).format(
            "YYYY-MM-DD"
          ),
          email_params: {
            to_emails: emails,
            common_name: getCommonName,
            end_date: moment(data.selectedDateRange[1].toString()).format(
              "YYYY-MM-DD"
            ),
            start_date: moment(data.selectedDateRange[0].toString()).format(
              "YYYY-MM-DD"
            ),
          },
        }
        : {
          end_date: moment(data.selectedDateRange[1].toString()).format(
            "YYYY-MM-DD"
          ),
          start_date: moment(data.selectedDateRange[0].toString()).format(
            "YYYY-MM-DD"
          ),
          email_params: {
            to_emails: emails,
            common_name: getCommonName,
            end_date: moment(data.selectedDateRange[1].toString()).format(
              "YYYY-MM-DD"
            ),
            start_date: moment(data.selectedDateRange[0].toString()).format(
              "YYYY-MM-DD"
            ),
          },
          module_id: +selectedModuleId,
        };

    await APIConfig.API_Client.post(
      apiEndpointList.CREDIT_CONSUMPTION_REPORTS.baseUrl +
      `/decentro/read/${reportFileFormat}/fetch/credits/details`,
      paymentsReportsPayload,
      { cancelToken: source.token }
    )
      .then((response) => {
        if (response) {
          unmountComponentAtNode(document.getElementById("report-loader"));
          unMountPreviousComponent("reports-component");
          ReactDOM.render(
            <SuccessDialog closeDialog={closeDialog} />,
            document.getElementById("reports-component")
          );
        } else {
          Promise.reject();
          ReactDOM.unmountComponentAtNode(
            document.getElementById("report-loader")
          );
          unMountPreviousComponent("reports-component");
          ReactDOM.render(
            <FailureDialog header={"Reports"} msg={"Something went wrong"} />,
            document.getElementById("failure-popup")
          );
        }
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("report-loader")
        );
        unMountPreviousComponent("reports-component");
        ReactDOM.render(
          <FailureDialog header={"Reports"} msg={error.message} />,
          document.getElementById("failure-popup")
        );
      });
  }

  // ^ Reports Download Beneficiaries and Virtual Accounts
  async function reportsDownload(
    data,
    reportFileFormat,
    selectedReportModuleType
  ) {
    ReactDOM.render(
      <Loader className="reportsloader" />,
      document.getElementById("report-loader")
    );
    let paymentsReportsPayload = {
      end_date: moment(data.selectedDateRange[1].toString()).format(
        "YYYY-MM-DD"
      ),
      start_date: moment(data.selectedDateRange[0].toString()).format(
        "YYYY-MM-DD"
      ),
      email_params: {
        to_emails: emails,
        common_name: getCommonName,
        end_date: moment(data.selectedDateRange[1].toString()).format(
          "YYYY-MM-DD"
        ),
        start_date: moment(data.selectedDateRange[0].toString()).format(
          "YYYY-MM-DD"
        ),
      },
    };

    let endPoint;

    if (selectedReportModuleType === "beneficiaries") {
      endPoint = `/decentro/read/${reportFileFormat}/fetch/manage/beneficiary`;
    }

    if (selectedReportModuleType === "fabric_transactions") {
      endPoint = `/decentro/read/${reportFileFormat}/fetch/transaction/fabric`;
    }

    if (selectedReportModuleType === "virtual_accounts") {
      endPoint = `/decentro/read/${reportFileFormat}/search/virtual_account_number`;
    }

    if (selectedReportModuleType === "emandate") {
      endPoint = `/decentro/read/${reportFileFormat}/search/emandate/registration`;
    }

    await APIConfig.API_Client.post(
      apiEndpointList.REPORTS.baseUrl + endPoint,
      paymentsReportsPayload,
      { cancelToken: source.token }
    )
      .then((response) => {
        if (response) {
          unmountComponentAtNode(document.getElementById("report-loader"));
          unMountPreviousComponent("reports-component");
          ReactDOM.render(
            <SuccessDialog closeDialog={closeDialog} />,
            document.getElementById("reports-component")
          );
        } else {
          Promise.reject();
          ReactDOM.unmountComponentAtNode(
            document.getElementById("report-loader")
          );
          unMountPreviousComponent("reports-component");
          ReactDOM.render(
            <FailureDialog header={"Reports"} msg={"Something went wrong"} />,
            document.getElementById("failure-popup")
          );
        }
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("report-loader")
        );
        unMountPreviousComponent("reports-component");
        ReactDOM.render(
          <FailureDialog header={"Reports"} msg={error.message} />,
          document.getElementById("failure-popup")
        );
      });
  }

  // ^ Reports Download for Payments
  async function reportsDownloadPayments(
    data,
    reportFileFormat,
    reportModuleType
  ) {
    ReactDOM.render(
      <Loader className="reportsloader" />,
      document.getElementById("report-loader")
    );

    let paymentsReportsPayload =
      data.selectedProviderOrModule === "all"
        ? {
          end_date: moment(data.selectedDateRange[1].toString()).format(
            "YYYY-MM-DD"
          ),
          start_date: moment(data.selectedDateRange[0].toString()).format(
            "YYYY-MM-DD"
          ),
          email_params: {
            to_emails: emails,
            common_name: getCommonName,
            end_date: moment(data.selectedDateRange[1].toString()).format(
              "YYYY-MM-DD"
            ),
            start_date: moment(data.selectedDateRange[0].toString()).format(
              "YYYY-MM-DD"
            ),
          },
        }
        : {
          end_date: moment(data.selectedDateRange[1].toString()).format(
            "YYYY-MM-DD"
          ),
          start_date: moment(data.selectedDateRange[0].toString()).format(
            "YYYY-MM-DD"
          ),
          email_params: {
            to_emails: emails,
            common_name: getCommonName,
            end_date: moment(data.selectedDateRange[1].toString()).format(
              "YYYY-MM-DD"
            ),
            start_date: moment(data.selectedDateRange[0].toString()).format(
              "YYYY-MM-DD"
            ),
          },
          provider_id: +selectedProviderId,
        };

    await APIConfig.API_Client.post(
      apiEndpointList.PAYMENTS_REPORTS.baseUrl +
      `/decentro/read/${reportFileFormat}/search/transaction/${reportModuleType}`,
      paymentsReportsPayload,
      { cancelToken: source.token }
    )
      .then((response) => {
        if (response) {
          unmountComponentAtNode(document.getElementById("report-loader"));
          unMountPreviousComponent("reports-component");
          ReactDOM.render(
            <SuccessDialog closeDialog={closeDialog} />,
            document.getElementById("reports-component")
          );
        } else {
          Promise.reject();
          ReactDOM.unmountComponentAtNode(
            document.getElementById("report-loader")
          );
          unMountPreviousComponent("reports-component");
          ReactDOM.render(
            <FailureDialog header={"Reports"} msg={"Something went wrong"} />,
            document.getElementById("failure-popup")
          );
        }
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("report-loader")
        );
        unMountPreviousComponent("reports-component");
        ReactDOM.render(
          <FailureDialog header={"Reports"} msg={error.message} />,
          document.getElementById("failure-popup")
        );
      });
  }

  // ^ Get registered Email's
  // function getRegisteredEmail() {
  //   APIConfig.API_Client.post(
  //     apiEndpointList.ACCOUNTS_REGISTERED_EMAIL_REPORTS.baseUrl +
  //       apiEndpointList.ACCOUNTS_REGISTERED_EMAIL_REPORTS.endpoint,
  //     {},
  //     { cancelToken: source.token }
  //   )
  //     .then((response) => {
  //       setGetCommonName(response.data[0].common_name);
  //     })
  //     .catch((error) => {});
  // }

  // useEffect(() => {
  //   getRegisteredEmail();
  // }, []);

  const [reportsInputDetails, setreportsInputDetails] = React.useState({
    emailInput: "",
  });

  const submitCustomerData = () => {
    // if module is selected or provider
    const selectedProviderOrModule = selectedProvider ?? selectedModule;

    let reportsInfo = {
      email: reportsInputDetails.emailInput,
      selectedOption,
      selectedOptionFormat,
      selectedProviderOrModule,
      selectedDateRange,
    };

    if (selectedReportModuleType.toLowerCase() === "credits_consumption") {
      reportsDownloadCreditConsumption(
        reportsInfo,
        selectedOption.toLowerCase()
      );
    }

    if (selectedReportModuleType.toLowerCase() === "beneficiaries") {
      reportsDownload(
        reportsInfo,
        selectedOption.toLowerCase(),
        selectedReportModuleType
      );
    }

    if (selectedReportModuleType.toLowerCase() === "fabric_transactions") {
      reportsDownload(
        reportsInfo,
        selectedOption.toLowerCase(),
        selectedReportModuleType
      );
    }

    if (selectedReportModuleType.toLowerCase() === "emandate") {
      reportsDownload(
        reportsInfo,
        selectedOption.toLowerCase(),
        selectedReportModuleType
      );
    }

    if (selectedReportModuleType.toLowerCase() === "virtual_accounts") {
      reportsDownload(
        reportsInfo,
        selectedOption.toLowerCase(),
        selectedReportModuleType
      );
    }

    if (
      selectedReportModuleType.toLowerCase() ===
      "consolidated_account_statement"
    ) {
      reportsDownloadAccounts(reportsInfo);
    }

    if (selectedReportModuleType.toLowerCase() === "payment_links") {
      reportsDownloadPayments(
        reportsInfo,
        selectedOption.toLowerCase(),
        selectedReportModuleType
      );
    }

    if (selectedReportModuleType.toLowerCase() === "collection_requests") {
      reportsDownloadPayments(
        reportsInfo,
        selectedOption.toLowerCase(),
        selectedReportModuleType
      );
    }

    if (selectedReportModuleType.toLowerCase() === "payouts") {
      reportsDownloadPayments(
        reportsInfo,
        selectedOption.toLowerCase(),
        selectedReportModuleType.toLowerCase()
      );
    }
  };

  useEffect(() => {
    if (!!selectedReportModuleType) {
      if (selectedReportModuleType !== "fabric_transactions") {
        selectedReportModuleType === "credits_consumption"
          ? getModules()
          : getProvider();
      }
      else {
        setFinalist([]);
      }

      // if credit_consumption is selected then setting provider as null and vice versa.
      selectedReportModuleType === "credits_consumption"
        ? setSelectedProvider(null)
        : setSelectedModule(null);
    }
  }, [selectedReportModuleType]);

  useEffect(() => {
    if (
      (selectedProvider ||
        selectedModule ||
        selectedReportModuleType === "beneficiaries" ||
        selectedReportModuleType === "fabric_transactions" ||
        selectedReportModuleType === "emandate" ||
        selectedReportModuleType === "virtual_accounts") &&
      emails.length > 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedProvider, emails, selectedModule, selectedReportModuleType]);

  return (
    <div className="ReportsForm">
      <div className="showLoader" id="showLoader"></div>

      <div className="title">Generate a new Report</div>

      {/* Alert Container */}
      {/* <div className="cas-message-container">
        <img src="/images/banner-info-icon.svg" alt="tooltip-info" />
        <p className="cas-message">
          <span className="important-text-highlight">Important :</span> CAS
          stands for Consolidated Account Statement. You can generate reports
          for data upto 365 days. Please reach out to
          <span className="text-highlight"> support@decentro.tech</span> for
          more data.
        </p>
      </div> */}

      {/* ... 1 */}
      <div className="input-user-form-fields">
        <div className="user-name-main">
          <div className="name">
            <div className="label-tag">Report Type</div>
            <div className="user-input">
              <DropDownTypeReport
                itemId={"reportType1"}
                placeholder="Select Report Type"
                options={reportModuleTypeList}
                setSelectedOption={setSelectedReportModuleType}
                selectedOption={selectedReportModuleType}
                disabledField={reportTypeDisable}
              />
            </div>
          </div>
        </div>

        <div className="user-name-main">
          {selectedReportModuleType === "credits_consumption" ? (
            <div className="name">
              <div className="label-tag-without-required">
                Modules
                <Tooltip
                  requiredField={true}
                  text="The module/s for which report has to be generated"
                >
                  <button className="info-icon">
                    <img src="/images/info-icon.svg" alt="information icon" />
                  </button>
                </Tooltip>
              </div>
              <div className="user-input">
                <DropDownSelectModules
                  itemId={"moduleCode1"}
                  placeholder="Select the Module"
                  options={modulesList}
                  setSelectedOption={setSelectedModule}
                  selectedOption={selectedModule}
                  setSelectedModuleId={setSelectedModuleId}
                  disabledField={moduleFieldDisabled}
                  showArrow={!moduleFieldDisabled}
                />
              </div>
            </div>
          ) : (
            <div className="name">
              <div className="label-tag-without-required">
                Bank Provider
                {/* Asterik only where dropdown is mandatory. */}
                <Tooltip
                  requiredField={
                    selectedReportModuleType !== "beneficiaries" &&
                      selectedReportModuleType !== "fabric_transactions" &&
                      selectedReportModuleType !== "emandate" &&
                      selectedReportModuleType !== "virtual_accounts"
                      ? true
                      : false
                  }
                  text="The bank provider/s for which report has to be generated"
                >
                  <button className="info-icon">
                    <img src="/images/info-icon.svg" alt="information icon" />
                  </button>
                </Tooltip>
              </div>
              <div className="user-input">
                <DropDownSelectReports
                  itemId={"providerCode1"}
                  placeholder={
                    selectedReportModuleType === "beneficiaries" ||
                      selectedReportModuleType === "fabric_transactions" ||
                      selectedReportModuleType === "emandate" ||
                      selectedReportModuleType === "virtual_accounts"
                      ? "Not Applicable"
                      : "Select the Bank Provider"
                  }
                  options={finallist}
                  setSelectedOption={setSelectedProvider}
                  selectedOption={selectedProvider}
                  setSelectedProviderId={setSelectedProviderId}
                  disabledField={
                    providerFieldDisabled ||
                    selectedReportModuleType === "beneficiaries" ||
                    selectedReportModuleType === "fabric_transactions" ||
                    selectedReportModuleType === "emandate" ||
                    selectedReportModuleType === "virtual_accounts"
                  }
                  showArrow={
                    !(
                      providerFieldDisabled ||
                      selectedReportModuleType === "beneficiaries" ||
                      selectedReportModuleType === "fabric_transactions" ||
                      selectedReportModuleType === "emandate" ||
                      selectedReportModuleType === "virtual_accounts"
                    )
                  }
                  loading={providerLoading}
                />
              </div>
            </div>
          )}
        </div>

        {/* <div className="user-name-main">
          <div className="name">
            <div className="label-tag-without-required">
              Date Range
              <Tooltip
                requiredField={true}
                text="A maximum date range of 365 days supported"
              >
                <button className="info-icon">
                  <img src="/images/info-icon.svg" alt="information icon" />
                </button>
              </Tooltip>
            </div>
            <div className="user-input date-selector-reports">
              <DateSelectorReports
                selectedDateRange={selectedDateRangeForSummary}
                setSelectedDateRange={(event) => {
                  setSelectedDateRange([event[0], event[1]]);
                }}
              />
            </div>
          </div>
        </div> */}
      </div>

      {/* ... 2 */}
      <div className="input-user-form-fields">
        {/* <div className="user-name-main">
          <div className="name">
            <div className="label-tag-without-required">
              Provider
              <Tooltip
                requiredField={true}
                text="The provider/s for which report has to be generated"
              >
                <button className="info-icon">
                  <img src="/images/info-icon.svg" alt="information icon" />
                </button>
              </Tooltip>
            </div>
            <div className="user-input">
              <DropDownSelectReports
                itemId={"providerCode1"}
                placeholder="Select the Bank"
                options={finallist}
                setSelectedOption={setSelectedProvider}
                selectedOption={selectedProvider}
                setSelectedProviderId={setSelectedProviderId}
                disabledField={providerFieldDisabled}
                showArrow={!providerFieldDisabled}
              />
            </div>
          </div>
        </div> */}

        <div className="user-name-main">
          <div className="name">
            <div className="label-tag-without-required">
              Date Range
              <Tooltip
                requiredField={true}
                text="A maximum date range of 365 days supported"
              >
                <button className="info-icon">
                  <img src="/images/info-icon.svg" alt="information icon" />
                </button>
              </Tooltip>
            </div>
            <div className="user-input date-selector-reports">
              <DateSelectorReports
                selectedDateRange={selectedDateRangeForSummary}
                setSelectedDateRange={(event) => {
                  setSelectedDateRange([event[0], event[1]]);
                }}
              />
            </div>
          </div>
        </div>

        <div className="user-name-main">
          <div className="name">
            <div className="label-tag-without-required">
              Email
              <Tooltip
                requiredField={true}
                text="A maximum of 3 email ids are supported"
              >
                <button className="info-icon">
                  <img src="/images/info-icon.svg" alt="information icon" />
                </button>
              </Tooltip>
            </div>

            <div className="user-input">
              <ReactMultiEmail
                placeholder="Enter email address"
                emails={emails}
                onChange={(_emails) => {
                  setEmails(_emails.slice(0, 3));
                }}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
                disabled={emails.length >= 3}
              />
            </div>
          </div>
        </div>
      </div>

      {/* ... 3*/}
      <div className="input-user-form-fields">
        <div className="user-name-main">
          <div className="name">
            <div className="label-tag">Report Format</div>
            <div className="user-input">
              <DropDownSelectFileType
                itemId={"reportFormat1"}
                placeholder="Select Report Format"
                options={reportFormatTypeList}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
              />
            </div>
          </div>
        </div>

        {/* Kept for future versions */}
        <div className="user-name-main">
          <div className="name">
            {/* <div className="label-tag-without-required">
              Date Range
              <Tooltip
                requiredField={true}
                text="A maximum date range of 365 days supported"
              >
                <button className="info-icon">
                  <img src="/images/info-icon.svg" alt="information icon" />
                </button>
              </Tooltip>
            </div>
            <div className="user-input">
              <DateSelectorReports
                selectedDateRange={selectedDateRangeForSummary}
                setSelectedDateRange={(event) => {
                  setSelectedDateRange([event[0], event[1]]);
                }}
              />
            </div> */}
          </div>
        </div>
      </div>

      {/* <div className="input-user-form-fields">
        <div className="user-name-main">
          <div className="name">
            <div className="label-tag">Email</div>
            <div className="user-input">
              <ReactMultiEmail
                placeholder="Enter email address"
                emails={emails}
                onChange={(_emails) => {
                  setEmails(_emails);
                }}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="user-name-main" style={{ visibility: "hidden" }}>
          <div className="name">
            <div className="label-tag">XXX Type</div>
            <div className="user-input">
              <DropdownSelect
                options={options}
                selectedOption={selectedOptionFormat}
                setSelectedOption={setSelectedOptionFormat}
                placeholder="Select Report Type"
                itemId={"reportFormat"}
                showArrow={false}
              />
            </div>
          </div>
        </div>
      </div> */}

      <div className="input-user-form-fields">
        <div className="user-name-main"></div>
        <div
          className="user-name-main"
          style={{ flexDirection: "row-reverse" }}
        >
          <div className="generate-btn__container">
            <button
              className={`generateButton ${disabled ? "inactiveBtn" : "activeBtn"
                }`}
              disabled={disabled}
              onClick={() => {
                if (
                  process.env.REACT_APP_ENV_NAME === "staging" ||
                  process.env.REACT_APP_ENV_NAME === "production"
                ) {
                  if (window?.location?.origin && window?.location?.pathname) {
                    try {
                      capturePosthogEventCustom(
                        window.location.origin,
                        window.location.pathname,
                        "Generate Reports CTA Submit",
                        {
                          additionalInfo:
                            "User submitted Generate Reports details",
                        }
                      );
                    } catch (error) {
                      console.error("Error capturing Posthog event:", error);
                    }
                  } else {
                    console.warn(
                      "Unable to capture event: window.location properties are not fully defined"
                    );
                  }
                }
                submitCustomerData();
                // if (
                //   process.env.REACT_APP_ENV_NAME === "staging" ||
                //   process.env.REACT_APP_ENV_NAME === "production"
                // ) {
                //   capturePosthogEvent(
                //     window.location.origin,
                //     window.location.pathname,
                //     "submit"
                //   );
                // }
              }}
            >
              Generate Report
              <span id="report-loader"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportsForm;
