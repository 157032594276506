import React from "react";

// styles import
import "./Enable2FA.scss";
import Toggle2FA from "./Toggle2FA/Toggle2FA";

const Enable2FA = () => {
  return (
    <div className="enable-2fa">
      {/* heading text */}
      <div className="manage-2fa-heading">
        <p>Manage 2FA</p>
      </div>

      {/* Manage 2FA toggle Card */}
      <Toggle2FA />
    </div>
  );
};

export default Enable2FA;
