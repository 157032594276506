import { useFormik } from "formik";
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import Axios from "axios";
// Formik
import * as Yup from "yup";
// Components
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import ManageIPSuccessDialog from "../ManageIPSuccessDialog/ManageIPSuccessDialog";
// Contexts
import { ManageIPContextProvider } from "../ManageIPContext";
// Utils
import { REGEXP } from "../../utilities/validators/inputValidators";
import { APIConfig } from "../../../services/apiConfiguration";
import FailureForCTA from "../../Shared/FailureForCTA/FailureForCTA";
import apiEndpointList from "../../../config/core_banking/endpoint";
import unMountPreviousComponent from "../../../utilities/unMountPreviousComponent";

const AddIPForm = () => {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const { allowedCount } = useContext(ManageIPContextProvider);

  // Schema for validating form fields corresponding to regex imported.
  const validationSchema = Yup.object({
    ip_address: Yup.string().required().matches(REGEXP.ipAddressRegex),
  });

  const initialValues = {
    ip_address: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    // This function will run when user will submit the form after it is validated.
    onSubmit: (values, action) => {
      const payload = {
        ip_address: [values.ip_address],
        request_type: allowedCount > 0 ? "update" : "create",
      };
      SubmitIPForm(payload, action);
    },
  });

  const SubmitIPForm = (payloadData, action) => {
    action.setSubmitting(true);
    APIConfig.API_Client.post(
      apiEndpointList.MANAGE_IP.baseUrl + apiEndpointList.MANAGE_IP.endpoint,
      payloadData,
      { cancelToken: source.token }
    )
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        if (response?.data && response?.data?.error) {
          // API call successful but with an error response
          ReactDOM.render(
            <FailureForCTA
              header={"Manage IP"}
              msg={response?.data?.message}
            />,
            document.getElementById("failure-popup")
          );
        } else {
          // Handle successful response
          if (response?.data?.status === "Failed") {
            ReactDOM.render(
              <FailureForCTA
                header={"Manage IP"}
                msg={response?.data?.message}
              />,
              document.getElementById("failure-popup")
            );
          } else if (response?.data?.status === "Success") {
            ReactDOM.render(
              <ManageIPSuccessDialog successResponse={response.data} />,
              document.getElementById("payments-components")
            );
            action.resetForm();
          }
        }
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );
        unMountPreviousComponent("payments-components");
        ReactDOM.render(
          <FailureForCTA
            header={"Manage IP"}
            msg={error?.response?.data?.message}
          />,
          document.getElementById("failure-popup")
        );
      })
      .finally(() => {
        // finally setting form submission false.
        action.setSubmitting(false);
      });
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="ip_address"
                name="ip_address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ip_address}
                touched={formik.touched.ip_address}
                error={formik.errors.ip_address}
                placeholder="Enter IP Address"
                label="IP Address"
                required={true}
                disabled={false}
                isToolTip={
                  "Upto 4 IPs can be added. To add more, please contact operations team."
                }
              />
            </div>

            <div className="ui-button-container">
              <button
                type="submit"
                className={`submit-btn ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
                <span
                  id="user-config-loader"
                  style={{ display: "flex" }}
                ></span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddIPForm;
