import React, { useState, useEffect, useRef, useMemo } from "react";
import KeyboardArrowDownSharp from "@material-ui/icons/KeyboardArrowDownSharp";
import "./DropdownSelect.scss";

import { VirtualAccountsTaskMenu as DropdownSelectMenu } from "../../../../../CreateVirtualAccountDetails/CreateVirtualAccountDetails";
import { TextField } from "@material-ui/core";

function DropdownSelect({
  options = [],
  setSelectedOption = (e) => e,
  selectedOption,
  itemId,
  placeholder = "Select Option",
  searchable = false,
  style = {},
  className = "",
  showArrow,
}) {
  function ToggleActionMenu() {
    document.querySelector(`.${itemId}`).classList.toggle("active-dropdown");
    document.querySelector(`.${itemId}-show`).classList.toggle("show-border");
    // if (
    //   searchValue !== localSelectedOption &&
    //   localSelectedOption !== placeholder
    // ) {
    //   setSearchValue(localSelectedOption);
    // }
  }
  const [searchValue, setSearchValue] = useState("");
  const [localSelectedOption, setLocalSelectedOption] = React.useState(
    selectedOption ?? placeholder
  );
  const [MenuActive, setMenuActive] = React.useState(false);
  
  const optionsToUse = useMemo(() => {
    // if (options.length < 1) {
    //   return "";
    // }
    if (searchable) {
      return options.filter((item) =>
        item.label?.toLowerCase().includes(searchValue?.toLowerCase())
      );
      // .filter(
      //   (item) =>
      //     item.label !== searchValue ||
      //     (item.label === searchValue && item.label !== localSelectedOption)
      // );
    }

    return options.filter((data) => data.label !== localSelectedOption);
  }, [options, searchValue]);

  const itemRef = useRef(null);
  function handleOutsideClick(e) {
    if (e.target !== itemRef.current && !itemRef.current?.contains(e.target)) {
      document.querySelector(`.${itemId}`).classList.remove("active-dropdown");
      document.querySelector(`.${itemId}-show`).classList.remove("show-border");
          // * down arrow flip state
          setMenuActive(false);
      // if (
      //   searchValue !== localSelectedOption &&
      //   localSelectedOption !== placeholder
      // ) {
      //   setSearchValue(localSelectedOption);
      // }
    }
  }

  function handleFilter(e) {
    setSelectedOption(e.target.value);
    setSearchValue(e.target.value);
    // ToggleActionMenu();
    if (
      document.querySelector(`.${itemId}`).classList.contains("active-dropdown")
    ) {
      return;
    }
    document.querySelector(`.${itemId}`).classList.add("active-dropdown");
    document.querySelector(`.${itemId}-show`).classList.add("show-border");
  }

  useEffect(() => {
    document.removeEventListener("click", handleOutsideClick);
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  });


  return (
    <div
      style={style}
      ref={itemRef}
      className={`provider-dropdowns ${className}`}
    >
      <span
        className={`${itemId}-show provider-show`}
        onClick={
          // options.length > 0 &&  ToggleActionMenu
          () => {
            if (options.length > 0) {
              setMenuActive(!MenuActive);
              ToggleActionMenu();
            }
          }
        }
      >
        {searchable ? (
          <input
            className="dropdown-input"
            onChange={handleFilter}
            style={{ width: "100%" }}
            value={searchValue}
            placeholder={placeholder}
          />
        ) : localSelectedOption === placeholder ? (
          <span className="placeholder-text">{localSelectedOption}</span>
        ) : (
          <span className="provider-text-er">
            {localSelectedOption?.toUpperCase()}
          </span>
        )}
        {showArrow !== false ? (
          // <KeyboardArrowDownSharp />
          <img
            style={{
              transform: MenuActive ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="/images/arrow-down.svg"
            alt="dropdwon-arrow"
          />
        ) : (
          ""
        )}
      </span>
      <div className={`${itemId} provider-bank-option`}>
        <div className="provider-options">
          {optionsToUse ? (
            optionsToUse
              .filter(
                (data) =>
                  data.label.toLowerCase() !== selectedOption?.toLowerCase()
              )
              .map((data, index) => (
                <div key={index}>
                  <div
                    onClick={() => {
                      setMenuActive(!MenuActive);
                      setLocalSelectedOption(data?.label);
                      setSelectedOption(data?.value);
                      ToggleActionMenu();
                      if (searchable) {
                        setSearchValue(data?.label);
                      }
                    }}
                    className="option"
                  >
                    {data?.label?.toUpperCase()}
                  </div>
                </div>
              ))
          ) : (
            <span style={{ padding: "10px 0", paddingLeft: "10px" }}>
              Loading...
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default DropdownSelect;
