import React from "react";
// Component Imports
import { Carousel } from "react-responsive-carousel";
//  Style imports
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./InfoCarousel.scss";

const InfoCarousel = ({
  slides,
  infiniteLoop = true,
  showIndicators = false,
  showThumbs = true,
  emulateTouch = true,
  autoPlay = false,
  showArrows = true,
}) => {
  return (
    <Carousel
      infiniteLoop={infiniteLoop}
      showIndicators={showIndicators}
      showThumbs={showThumbs}
      emulateTouch={emulateTouch}
      autoPlay={autoPlay}
      showArrows={showArrows}
      interval={5000}
    >
      {slides.map((slide, index) => (
        <div key={index}>
          <h3 className="module-header">{slide.heading}</h3>
          <p className="module-description">{slide.text}</p>
        </div>
      ))}
    </Carousel>
  );
};

export default InfoCarousel;
