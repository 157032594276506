function formatDateToCustomFormat(dateTimeString) {
  const dateTime = new Date(dateTimeString);

  const day = dateTime.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[dateTime.getMonth()];
  const year = dateTime.getFullYear();
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Format hour in 12-hour format and ensure single-digit minutes are padded with a leading zero.
  const formattedHour = (hours % 12 || 12).toString().padStart(2, "0");

  const formattedDate = `${day} ${month} ${year}, ${formattedHour}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;

  return formattedDate;
}

export { formatDateToCustomFormat };
