import React, { useContext } from "react";
import StickyFooter from "../../components/StickyFooter/StickyFooter.js";
import OTPVerify from "./OTPVerify/OTPVerify.js";

import LoginContext from "../../contexts/LoginContext.js";
import OTPLogin from "./OTPLogin/OTPLogin.js";
import DecentroBanner from "../../components/Shared/DecentroBanner/DecentroBanner.js";
// styles import
import "./Login2FA.scss";

const Login2FA = () => {
  let label = { light: "Welcome", bold: "Back!" };

  // ^ context
  const { cardType } = useContext(LoginContext);

  const LOGIN_CARD = {
    loginOTP: <OTPLogin />,
    verifyOTP: <OTPVerify />,
  };

  return (
    <React.Fragment>
      <div className="login">
        <DecentroBanner label={label} />
        <div className="login-section">{LOGIN_CARD[cardType]}</div>
      </div>
      <StickyFooter />
    </React.Fragment>
  );
};

export default Login2FA;
