import NewCallback from "../../NewCallbackConfiguration/NewCallback/NewCallback.js"
import "./AddCallback.scss"
const AddCallback = () => {
    return (
        <>
            <div className="add-callback-page">
                <div className="add-callback-content">
                    <div className="add-callback-card">
                        <NewCallback />
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddCallback