import posthog from "posthog-js";

export const capturePosthogEvent = (origin, pathname, action) => {
  let target = origin + pathname;
  if (origin && pathname) {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (action == "refresh") {
        switch (pathname) {
          case "/dashboard/payments/transactions":
            posthog.capture("All Transaction Refresh Button", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/payments/payment-links":
            posthog.capture("Payment Links Refresh Button", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/payments/manage-beneficiaries":
            posthog.capture("Manage Beneficiaries Refresh Button", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/payments/payouts":
            posthog.capture("Initiate Payout Refresh Button", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/payments/collection-requests":
            posthog.capture("Collection Requests Refresh Button", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/accounts/all-accounts":
            posthog.capture("All Accounts Refresh Button", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/accounts/account-balances":
            posthog.capture("Account Balances Refresh Button", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/recurring-collection/emandate":
            posthog.capture("eMandate Refresh Button", {
              path: { target },
            });
            break;
          default:
            break;
        }
      } else if (action == "cta") {
        switch (pathname) {
          case "/dashboard/payments/payment-links":
            posthog.capture("Generate Payment Link CTA Open", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/payments/manage-beneficiaries":
            posthog.capture("Add Beneficiary CTA Open", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/payments/payouts":
            posthog.capture("Initiate Payout CTA Open", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/payments/collection-requests":
            posthog.capture("Send Collection Request CTA Open", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/accounts/all-accounts":
            posthog.capture("Create Virtual Account CTA Open", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/login":
            posthog.capture("Login Page CTA Submit", {
              path: { target },
            });
            break;
          case "/dashboard/recurring-collection/emandate":
            posthog.capture("Create eMandate CTA Open", {
              path: { target },
            });
            break;
          default:
            break;
        }
      } else if (action == "submit") {
        switch (pathname) {
          case "/dashboard/payments/payment-links":
            posthog.capture("Generate Payment Link CTA Submit", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/payments/manage-beneficiaries":
            posthog.capture("Add Beneficiary CTA Submit", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/payments/payouts":
            posthog.capture("Initiate Payout CTA Submit", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/payments/collection-requests":
            posthog.capture("Send Collection Request CTA Submit", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/accounts/all-accounts":
            posthog.capture("Create Virtual Account CTA Submit", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/dashboard/reports/generate-reports":
            posthog.capture("Reports CTA Submit", {
              path: { target },
              customer_name: JSON.parse(localStorage.getItem("user")).client_id,
              user_email: JSON.parse(localStorage.getItem("user")).email,
            });
            break;
          case "/login":
            posthog.capture("Login Successful", {
              path: { target },
            });
            break;
          case "/dashboard/recurring-collection/emandate":
            posthog.capture("Create eMandate CTA Submit", {
              path: { target },
            });
            break;
          default:
            break;
        }
      } else if (action == "logout") {
        posthog.capture("Logout CTA", {
          path: { target },
        });
      }
    }
  }
};
