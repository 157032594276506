import React from "react";
import PropTypes from "prop-types";
import { Locale } from "../../contexts/constants";
import { DateRangePicker } from "rsuite";
import { dateValues } from "../../contexts/constants";
import { Ranges } from "../../contexts/constants";
import subDays from "date-fns/subDays";

import "rsuite/dist/styles/rsuite-default.css";
import "./DateSelectorReports.scss";

let initialStartDate = new Date();
initialStartDate.setDate(initialStartDate.getDate() - dateValues.dateEnd);

function DateSelectorReports(props) {
  const [selectedDateRangeValue, setSelectedDateRangeValue] = React.useState(
    props.selectedDateRange
  );

  const minDate = subDays(new Date(), 365); // 365 days from today

  return (
    <div className="datereports">
      <DateRangePicker
        format={dateValues.dateFromat}
        cleanable={false}
        value={selectedDateRangeValue}
        ranges={Ranges}
        locale={Locale}
        disabledDate={(date) => {
          const today = new Date();
          if (
            selectedDateRangeValue &&
            selectedDateRangeValue[0] &&
            selectedDateRangeValue[1]
          ) {
            const startDate = selectedDateRangeValue[0];
            const endDate = selectedDateRangeValue[1];
            if (date >= startDate && date <= endDate) {
              return false; // Allow dates within the selected range
            }
          }
          return date < minDate || date > today;
        }}
        onChange={(item) => {
          setSelectedDateRangeValue(item);
          props.setSelectedDateRange(item);
        }}
      />
    </div>
  );
}

DateSelectorReports.propTypes = {
  selectedDateRange: PropTypes.any,
  setSelectedDateRange: PropTypes.any,
};

DateSelectorReports.defaultProps = {
  selectedDateRange: [initialStartDate, new Date()],
  setSelectedDateRange: {},
};

export default DateSelectorReports;
