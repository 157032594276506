import React, { createContext, useState } from "react";
import ReactDOM from "react-dom";
import * as forge from "node-forge";
import axios from "axios";
import { randomUUID } from "../services/randomUUID";
import SnackbarMessage from "../components/Shared/SnackbarMessage/SnackbarMessage";

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [cardType, setCardType] = useState("loginOTP");
  // ^ card types
  // loginOTP
  // verifyOTP

  // & user otp response state
  const [userOtpTransactionID, setUserOtpTransactionID] = useState("");
  // & email password state
  const [loginDetails, setLoginDetails] = useState({});
  // & mobile access message state
  const [otpSentMessage, setOtpSentMessage] = useState("");

  const toVerifyOTP = () => {
    setCardType("verifyOTP");
  };

  const toLoginOTP = () => {
    setCardType("loginOTP");
  };

  const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

  // & login api for resend
  const resendOTPApi = (event) => {
    event.preventDefault();
    const publicKey = process.env.REACT_APP_AUTH_ENCRYPTION_PUBLIC_KEY;
    const forgedPublicKey = forge.pki.publicKeyFromPem(publicKey);
    let encryptedPassword = forgedPublicKey.encrypt(
      loginDetails?.password,
      "RSA-OAEP",
      {
        md: forge.md.sha256.create(),
        mgf1: forge.mgf1.create(),
      }
    );
    const finalEncryptedPassword = forge.util.encode64(encryptedPassword);
    axios
      .post(BASE_URL + "/v2/auth/user/otp", {
        email: loginDetails?.email,
        password: finalEncryptedPassword,
        source: "CUSTOMER",
        reference_id: randomUUID(),
        consent: true,
      })
      .then((response) => {
        // * Store Transition ID
        setUserOtpTransactionID(response.data.decentroTxnId);

        // * snackbar message
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={"OTP sent successfully"} />,
          document.getElementById("snackbar")
        );
      })
      .catch((err) => {});
  };

  return (
    <LoginContext.Provider
      value={{
        cardType,
        setCardType,
        userOtpTransactionID,
        setUserOtpTransactionID,
        setLoginDetails,
        loginDetails,
        toVerifyOTP,
        toLoginOTP,
        resendOTPApi,
        otpSentMessage,
        setOtpSentMessage,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
