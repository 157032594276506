import React, { useContext, useEffect } from "react";

//styles
import "./CreateVirtualAccount.scss";
// Components
import CreateVirtualAccountFormFormik from "../CreateVirtualAccountForm/CreateVirtualAccountFormFormik";
import FormDivider from "../../../UI/Divider/FormDivider";

function CreateVirtualAccount({ goBackToTable }) {

  return (
    <>
      <div className="add-user-page">
        <div className="add-user-content">
          <div className="add-user-card">
            <div className="back-btn">
              <img
                src="/images/back.svg"
                alt="back-icon"
                onClick={() => goBackToTable()}
              />
              <p className="heading">Create Virtual Accounts</p>
            </div>
            <FormDivider />
            {/* <CreateVirtualAccountForm refresh={refreshTable} /> */}
            <CreateVirtualAccountFormFormik />
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateVirtualAccount;
