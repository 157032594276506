import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import "./SendCredSuccessDialog.scss";

function SendCredSuccessDialog(props) {
  return (
    <React.Fragment>
      <Dialog className="send-cred-success" PaperProps={{ style: { borderRadius: 10 } }} open={true} aria-labelledby="form-dialog-title">
        <div className="close-popup">
          <CloseIcon onClick={props.closeDialog} />
        </div>

        <DialogContent>
          <div className="report-success-heading">
            <p>Credentials</p>
          </div>
          <div className="submit-success-content">
            <img src="/images/onsuccess-tick.svg" alt="success-icon" />
            <div>
              <h3>Your request is in process.</h3>
            </div>
            <div>
              <p>
              The Credentials have been shared on your registered company email. Please check your Inbox and Spam folder (If it’s not being shown in the Inbox)
              </p>
              <p> Please check your inbox </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default SendCredSuccessDialog;
