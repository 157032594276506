import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
const HandleOutsideClick = ({ children, onClickOutside }) => {
  const clickRef = useRef(null);
  const handleClick = (e) => {
    if (!clickRef.current || clickRef.current.contains(e.target)) {
      return;
    }
    onClickOutside();
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);
  return <div ref={clickRef}>{children}</div>;
};
export default HandleOutsideClick;
