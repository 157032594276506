import React from "react";
import ReactDOM from "react-dom";

import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";
import SnackbarMessage from "../Shared/SnackbarMessage/SnackbarMessage";
import "./MoneyTransferSuccessDialog.scss";
import { PayoutsContextProvider } from "../Payouts/PayoutsContext";
import { useContext } from "react";

let successResponseData;

function MoneyTransferSuccessDialog(props) {
  // Commenting this out here since it was always showing the failure dialog even if the response code is success - will refresh the page itself for now to handle the table refresh data
  // const { refreshTable } = useContext(PayoutsContextProvider);
  const closeSuccessDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("virtual-accounts-components")
    );
    // Table is already getting refreshed on pressing back
    // refreshTable();
  };

  const copyToClipBoard = async (copyLink) => {
    try {
      await navigator.clipboard.writeText(copyLink);
      ReactDOM.render(
        <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
        document.getElementById("snackbar")
      );
    } catch (err) {}
  };

  successResponseData = props.data;
  return (
    <React.Fragment>
      <Dialog
        className="money-transfer-success"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-success-popup">
          <CloseIcon onClick={closeSuccessDialog} />
        </div>

        <DialogContent>
          <div className="dialog-heading">
            <p>Money Transfer</p>
          </div>
          <div className="dialog-content-container">
            <div className="success-message">
              <img src="/images/onsuccess-tick.svg" alt="success-icon" />
              <h3>Transaction initiated successfully!</h3>
              <p>{successResponseData.message}</p>
            </div>
            <div className="success-account-details">
              <table>
                <tbody>
                  <tr>
                    <td className="accounts-heading">Status</td>
                    <td className="accounts-details title-case">
                      {successResponseData.status}
                    </td>
                  </tr>
                  <tr>
                    <td className="accounts-heading">Transaction ID</td>
                    <td className="accounts-details">
                      {successResponseData.decentroTxnId}
                      <img
                        onClick={() =>
                          copyToClipBoard(successResponseData.decentroTxnId)
                        }
                        className="copy-link-icon"
                        src="/images/copy-icon.svg"
                        title="Copy text"
                        alt="copy-icon"
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="accounts-heading">Beneficiary Code</td>
                    <td className="accounts-details">
                      {successResponseData?.beneficiaryCode
                        ? successResponseData.beneficiaryCode
                        : "NA"}
                      {successResponseData?.beneficiaryCode && (
                        <img
                          onClick={() =>
                            copyToClipBoard(successResponseData.beneficiaryCode)
                          }
                          className="copy-link-icon"
                          src="/images/copy-icon.svg"
                          title="Copy text"
                          alt="copy-icon"
                        />
                      )}
                    </td>
                  </tr> */}
                  <tr>
                    <td className="accounts-heading">Balance</td>
                    <td className="accounts-details">
                      {successResponseData.newBalance}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="success-btn-container">
              <button onClick={closeSuccessDialog} className="success-btn-done">
                Done
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default MoneyTransferSuccessDialog;
