import React, { useEffect, useState } from "react";
//component imports
import InitiatePayoutModalForm from "./InitiatePayoutModalForm/InitiatePayoutModalForm";
// api config imports
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/core_banking/endpoint";
// context imports
import { PayoutsContextProvider } from "../PayoutsContext";
//styles
import "./InitiatePayoutModal.scss";
// Components
import InitiatePayoutModalFormFormik from "./InitiatePayoutModalForm/InitiatePayoutModalFormFormik";
import FormDivider from "../../UI/Divider/FormDivider";

function InitiatePayoutModal({ goBackToTable }) {
  const [handle2faCheck, setHandle2faCheck] = useState(false);

  //*  MFA toggle status api
  const mfaToggleStatus = () => {
    APIConfig.API_Client.get(
      apiEndpointList.MFA_TOGGLE_DETAIL.baseUrl +
      apiEndpointList.MFA_TOGGLE_DETAIL.endpoint
    )
      .then((response) => {
        setHandle2faCheck(response?.data?.sms_mfa_enabled);
        if (!response?.data?.sms_mfa_enabled) {
          goBackToTable();
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      mfaToggleStatus();
    }
  }, []);

  // useEffect(() => {
  //   if (!handle2faCheck) {
  //     goBackToTable();
  //   }
  // }, [handle2faCheck]);

  return (
    <>
      <div className="add-user-page">
        <div className="add-user-content">
          <div className="add-user-card">
            {/* {tableEditRow && (
              <div className="back-btn" onClick={()=>goBackToTable()}>
                <img src="/images/back.svg" alt="back-icon" />
                <h1 className="heading">
                  <span className="company-name-highlight">
                    Modify Beneficiary
                  </span>
                </h1>
              </div>
            )} */}

            <div className="back-btn">
              <img
                src="/images/back.svg"
                alt="back-icon"
                onClick={() => goBackToTable()}
              />
              <p className="heading">Initiate Payouts</p>
            </div>

            <FormDivider />
            {/* <InitiatePayoutModalForm /> */}
            <InitiatePayoutModalFormFormik />
          </div>
        </div>
      </div>
    </>
  );
}

export default InitiatePayoutModal;
