import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import FailureDialog from "../Shared/FailureDialog/FailureDialog";
import SkeletonDatable from "../SkeletonComponents/SkeletonDatable";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/core_banking/endpoint";
import { Loader } from "rsuite";
import moment from "moment";
import Axios from "axios";
import "jszip";
import "../AllTransactionsDatatable/AllTransactionsDatatable.scss";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";
import DateSelector from "../DateSelector/DateSelector";
import axios from "axios";
import TransactionModal from "./TransactionModal/TransactionModal";
import MoneyTransfer from "../MoneyTransfer/MoneyTransfer";
import Empty from "../SkeletonComponents/SkeletonEmptyDialogue";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import posthog from "posthog-js";

function AllTransactionsDatatable(props) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let initialStartDate = new Date();
  let updateColumnFilters = false;
  let empty = [];
  let offset = 0;
  const [Refresh, setRefresh] = useState(props.setRefresh);
  let contentinfo = "Loading records ";
  initialStartDate.setDate(initialStartDate.getDate() - 14);
  let limitConst = 200;
  let [selectedDateRange, setSelectedDateRange] = React.useState([
    initialStartDate,
    new Date(),
  ]);
  let selectedDateRangeForLogs = [selectedDateRange[0], selectedDateRange[1]];
  let dataSet;
  let startDate = moment(selectedDateRangeForLogs[0].toString()).format(
    "YYYY-MM-DD"
  );
  let endDate = moment(selectedDateRangeForLogs[1].toString()).format(
    "YYYY-MM-DD"
  );
  let tran_table; //first table va accounts reference variable
  // let vaAccounts = [];
  let date;
  let query;
  let lenght = 1;

  // Handling count loader
  let [countLoaderFlag, setCountLoaderFlag] = React.useState(true);
  React.useEffect(() => {
    if (
      document.getElementById("count_loader") != null &&
      document.getElementById("count_loader") != undefined
    ) {
      if (!countLoaderFlag)
        ReactDOM.unmountComponentAtNode(
          document.getElementById("count_loader")
        );

      //   ReactDOM.render(<Loader className="loader" size="xs" />, document.getElementById("count_loader"))
      // else
    }
  }, [countLoaderFlag]);

  let [totalcount, setTotalCount] = useState(0);
  const $ = require("jquery");
  require("datatables.net-buttons/js/buttons.html5.js")();
  require("datatables.net-buttons/js/buttons.print.js")();
  $.DataTable = require("datatables.net");
  const closeDialog = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById("loading_tran"));
  };

  const swapValue = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (!obj[key]) {
        obj[key] = "-";
      }
    });
    return obj;
  };
  let newToken;

  const getCountForTransactions = () => {
    let val;
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_ACCOUNTS_TRANSACTIONS_COUNT.baseUrl +
        apiEndpointList.FETCH_ACCOUNTS_TRANSACTIONS_COUNT.endpoint, //url shows the transaction results
      {
        limit: limitConst,
        start_date: startDate,
        offset: offset,
        end_date: endDate,
      },

      { cancelToken: source.token }
    ).then((res) => {
      val = res;
    });
    return val;
  };
  function fallbackTransactions(consolidated) {
    if (newToken) {
      newToken.cancel("Transactions Tab closed");
    }
    ReactDOM.unmountComponentAtNode(document.getElementById("loading1"));
    var datatable = $(tran_table).dataTable().api();
    datatable.clear();
    datatable.rows.add(consolidated);
    datatable.draw(false);
    $("#selected3").remove();
    $("#selected4").remove();
    $("#selected5").remove();
    createColumnFilters(datatable);
  }

  async function searchAllTransactions(term) {
    if (newToken) {
      newToken.cancel("Search changed or cancelled");
    }
    newToken = axios.CancelToken.source();

    ReactDOM.render(
      <Loader className="loader" right size="xs" />,
      document.getElementById("loading1")
    );
    try {
      let bank_ref = APIConfig.API_Client.post(
        apiEndpointList.FETCH_VA_ACCOUNT_TRANSACTIONS.baseUrl +
          apiEndpointList.FETCH_VA_ACCOUNT_TRANSACTIONS.endpoint, //url shows the transaction results
        {
          limit: limitConst,
          //   decentro_company_urn: term,
          bank_reference_number: term,
        },

        { cancelToken: newToken.token }
      );
      let decentro_urn = APIConfig.API_Client.post(
        apiEndpointList.FETCH_VA_ACCOUNT_TRANSACTIONS.baseUrl +
          apiEndpointList.FETCH_VA_ACCOUNT_TRANSACTIONS.endpoint, //url shows the transaction results
        {
          limit: limitConst,
          decentro_company_urn: term,
        },

        { cancelToken: newToken.token }
      );
      let customer_ref = APIConfig.API_Client.post(
        apiEndpointList.FETCH_VA_ACCOUNT_TRANSACTIONS.baseUrl +
          apiEndpointList.FETCH_VA_ACCOUNT_TRANSACTIONS.endpoint, //url shows the transaction results
        {
          limit: limitConst,
          customer_reference_number: term,
        },

        { cancelToken: newToken.token }
      );
      return await axios.all([bank_ref, decentro_urn, customer_ref]).then(
        axios.spread((...responses) => {
          responses[0].data.map((d) => swapValue(d));
          responses[1].data.map((d) => swapValue(d));
          responses[2].data.map((d) => swapValue(d));
          let searchResponses = [];
          for (let i = 0; i < 3; i++) {
            responses[i].data.map((d) => {
              searchResponses.push(swapValue(d));
            });
          }
          let jsonSearchObject = searchResponses.map(JSON.stringify);
          let uniqueSearchSet = new Set(jsonSearchObject);
          let uniqueSearchResponses = Array.from(uniqueSearchSet).map(
            JSON.parse
          );
          ReactDOM.unmountComponentAtNode(document.getElementById("loading1"));
          var datatable = $(tran_table).dataTable().api();
          datatable.clear();
          datatable.rows.add(uniqueSearchResponses);
          datatable.draw(false);
          $("#selected3").remove();
          $("#selected4").remove();
          $("#selected5").remove();
          createColumnFilters(datatable);
          // use/access the results
        })
      );
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log(error);
      } else {
        ReactDOM.unmountComponentAtNode(document.getElementById("loading1"));
        ReactDOM.render(
          <SkeletonEmptyDialogue type={error} />,
          document.getElementById("main_transaction")
        );

        ReactDOM.render(
          <FailureDialog closeDialog={closeDialog()} />,
          document.getElementById("failure-popup")
        );
      }
    }
  }

  function createColumnFilters(datatable) {
    datatable.columns([3, 4, 5]).every(function () {
      var column = this;
      var columnfilters = [];
      var notchecked = [];
      var uniqueitems = [];
      var select = $(
        '<div id="selected' +
          column.index() +
          '"><label class="labelforfilter"></label></div>'
      )
        .appendTo($(column.header()))
        .on("change", function () {
          updateColumnFilters = true;
          uniqueitems = [];
          table
            .column(3)
            .data()
            .unique()
            .sort()
            .each(function (d, j) {
              var str;
              str = d.replace(/\s/g, "_");
              uniqueitems.push(str);
            });
          table
            .column(4)
            .data()
            .unique()
            .sort()
            .each(function (d, j) {
              var str;
              str = d.replace(/\s/g, "_");
              uniqueitems.push(str);
            });
          table
            .column(5)
            .data()
            .unique()
            .sort()
            .each(function (d, j) {
              var str;
              str = d.replace(/\s/g, "_");
              uniqueitems.push(str);
            });
          columnfilters = [];
          notchecked = [];
          if (uniqueitems.length != 0) {
            uniqueitems.map((u) => {
              var el = document.getElementById(u);
              if (el.checked == true) {
                columnfilters.push($(el).val());
              } else {
                notchecked.push($(el).val());
              }
            });
          }

          localStorage.setItem("columnfilters", JSON.stringify(columnfilters));
          var vals = $(":checked", select)
            .map(function (index, element) {
              return $.fn.dataTable.util.escapeRegex($(element).val());
            })
            .toArray()
            .join("|");
          column
            .search(vals.length > 0 ? "^(" + vals + ")$" : "", true, false)
            .draw();
        });
      column
        .data()
        .unique()
        .sort()
        .each(function (d, j) {
          var str = d.replace(/\s/g, "_");
          select.append(
            '<label id="label' +
              j +
              '" class="labelforfilter">' +
              d +
              "<input id=" +
              str +
              ' type="checkbox" value="' +
              d +
              '"></label>'
          );
        });
    });
    $("#selected3").css("display", "none");
    $("#selected4").css("display", "none");
    $("#selected5").css("display", "none");
    var checkedItems = JSON.parse(localStorage.getItem("columnfilters"));
    $("#selected3").append(
      '<div id="Sorting0">Sort <img id="my1" src="/images/Sort.svg"></div>'
    );
    $("#selected4").append(
      '<div id="Sorting1">Sort <img id="my2" src="/images/Sort.svg"></div>'
    );
    $("#selected5").append(
      '<div id="Sorting2">Sort <img id="my3" src="/images/Sort.svg"></div>'
    );
    $("#selected3").append(
      '<div class="clear_filter" id="Clear0"><span class="clear_icon">x</span>Clear Filter</div>'
    );
    $("#selected4").append(
      '<div class="clear_filter" id="Clear1"><span class="clear_icon">x</span>Clear Filter</div>'
    );
    $("#selected5").append(
      '<div class="clear_filter" id="Clear2"><span class="clear_icon">x</span>Clear Filter</div>'
    );
    var table = $(tran_table).DataTable();
    if (updateColumnFilters == false) {
      if (checkedItems != null) {
        checkedItems.map((c) => {
          let d = c.replace(/\s/g, "_");
          var newEl = document.getElementById(d);
          newEl.checked = true;
        });
      }

      // localStorage.removeItem("columnfilters")
      localStorage.setItem("columnfilters", JSON.stringify(checkedItems));
      checkedItems = [];
    }
    $("#Clear0").on("click", function () {
      table
        .columns([3])
        .data()
        .unique()
        .sort()
        .each(function (d, j) {
          this.columns().search("").draw();
        });
      table
        .columns([3])
        .data()
        .unique()
        .sort()
        .each(function (d, j) {
          d.forEach((element) => {
            var ele = element.replace(/\s/g, "_");

            $("#" + ele + "").prop("checked", false);
          });
        });
    });
    $("#Clear1").on("click", function () {
      table
        .columns([4])
        .data()
        .unique()
        .sort()
        .each(function (d, j) {
          this.columns().search("").draw();
        });
      table
        .columns([4])
        .data()
        .unique()
        .sort()
        .each(function (d, j) {
          d.forEach((element) => {
            var ele = element.replace(/\s/g, "_");

            $("#" + ele + "").prop("checked", false);
          });
        });
    });
    $("#Clear2").on("click", function () {
      table
        .columns([5])
        .data()
        .unique()
        .sort()
        .each(function (d, j) {
          this.columns().search("").draw();
        });
      table
        .columns([5])
        .data()
        .unique()
        .sort()
        .each(function (d, j) {
          d.forEach((element) => {
            var ele = element.replace(/\s/g, "_");

            $("#" + ele + "").prop("checked", false);
          });
        });
    });
    $("#Sorting0").on("click", function () {
      table.order.listener("#Sorting0", 3);
      $("#Sorting0").toggleClass("showImage0");
      if ($("#Sorting0").hasClass("showImage0")) {
        $("#my1").attr("src", "/images/Sort_Up.svg");
      } else {
        $("#my1").attr("src", "/images/Sort_Down.svg");
      }
      table.order(table.columns([3]), "asc").draw();
    });
    $("#Sorting1").on("click", function () {
      table.order.listener("#Sorting1", 4);
      $("#Sorting1").toggleClass("showImage1");
      if ($("#Sorting1").hasClass("showImage1")) {
        $("#my2").attr("src", "/images/Sort_Up.svg");
      } else {
        $("#my2").attr("src", "/images/Sort_Down.svg");
      }
      table.order(table.columns([4]), "asc").draw();
    });
    $("#Sorting2").on("click", function () {
      table.order.listener("#Sorting2", 5);
      $("#Sorting2").toggleClass("showImage2");
      if ($("#Sorting2").hasClass("showImage2")) {
        $("#my3").attr("src", "/images/Sort_Up.svg");
      } else {
        $("#my3").attr("src", "/images/Sort_Down.svg");
      }
      table.order(table.columns([5]), "asc").draw();
    });
    $("#selected3").css("display", "none");
    $("#selected4").css("display", "none");
    $("#selected5").css("display", "none");
  }

  function LoadmoreTransactions(offset) {
    ReactDOM.render(
      <Loader center size="md" backdrop content={contentinfo} />,
      document.getElementById("loading1")
    );
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_VA_ACCOUNT_TRANSACTIONS.baseUrl +
        apiEndpointList.FETCH_VA_ACCOUNT_TRANSACTIONS.endpoint, //url shows the transaction results
      {
        limit: limitConst,
        start_date: startDate,
        offset: offset,
        end_date: endDate,
      },

      { cancelToken: source.token }
    )
      .then((res) => {
        let temp;
        updateColumnFilters = false;

        temp = res.data.map((d) => swapValue(d));
        let response = temp;
        if (temp.length === 0) {
          $("div.datatables_paginate").toggleClass("hideOptions");
        }
        dataSet = response;
        ReactDOM.unmountComponentAtNode(document.getElementById("loading1"));
        var datatable = $(tran_table).dataTable().api();
        datatable.rows.add(dataSet);
        datatable.draw(false);
        $("#selected3").remove();
        $("#selected4").remove();
        $("#selected5").remove();
        createColumnFilters(datatable);
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          console.log(err);
        } else {
          ReactDOM.unmountComponentAtNode(document.getElementById("loading1"));
          ReactDOM.unmountComponentAtNode(
            document.getElementById("main_transaction")
          );
          ReactDOM.render(
            <SkeletonEmptyDialogue type={err} />,
            document.getElementById("main_transaction")
          );
          ReactDOM.render(
            <FailureDialog closeDialog={closeDialog()} />,
            document.getElementById("failure-popup")
          );
        }
      });
  }

  async function showTransactionsAccounts() {
    ReactDOM.render(
      <SkeletonDatable />,
      document.getElementById("loading_tran")
    );
    let response;
    let temp;
    let actualCount;

    try {
      // let totalCountApiCall =

      await APIConfig.API_Client.post(
        apiEndpointList.FETCH_VA_ACCOUNT_TRANSACTIONS.baseUrl +
          apiEndpointList.FETCH_VA_ACCOUNT_TRANSACTIONS.endpoint, //url to show transactions
        {
          limit: limitConst,
          start_date: startDate,
          offset: offset,
          end_date: endDate,
        }, //the va number clicked will be sent

        { cancelToken: source.token }
      ).then((response) => {
        // setTotalCount(response[0].data[0].total_count)
        ReactDOM.unmountComponentAtNode(
          document.getElementById("loading_tran")
        );
        let res = response;
        temp = res.data.map((d) => swapValue(d));
        if (temp.length === 0) {
          $("div.datatables_paginate").toggleClass("hideOptions");
        }
        // response = temp;
        let consolidated = temp;
        ReactDOM.render(
          <TransactionTable />,
          document.getElementById("main_transaction")
        );
        dataSet = temp;
        localStorage.setItem("columnfilters", JSON.stringify(empty));
        $(tran_table).DataTable({
          data: dataSet,
          destroy: "true",
          searching: "true",
          paging: "true",
          bSort: true,
          order: [],
          lengthChange: "true",
          heightMatch: "none",

          dom:
            "<'topwrapper'>" +
            "<'tran-wrapper'f<'tran-date'><'show_entries'><'#colvis'><'#download'><'#loading1'>>" + //should add a back button to call method to destroy the table
            "<'row scrollme'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-4'i><'col-sm-4 text-center'><'col-sm-4'p>>",
          language: {
            search: "",
          },
          buttons: [
            { extend: "copy", className: "copy-button" },
            { extend: "pdf", className: "pdf-button" },
          ],

          infoCallback: function (
            settings,
            total,
            pre,
            recordsDisplay,
            start,
            end,
            max
          ) {
            let val = 0;
            let sValue = 1;
            var api = this.api();
            var pageInfo = api.page.info();
            $(".dataTables_filter input").attr("placeholder", "Search ");
            // APIConfig.API_Client.post(
            //   apiEndpointList.FETCH_ACCOUNTS_TRANSACTIONS_COUNT.baseUrl +
            //     apiEndpointList.FETCH_ACCOUNTS_TRANSACTIONS_COUNT.endpoint, //url shows the transaction results
            //   {
            //     limit: limitConst,
            //     start_date: startDate,
            //     offset: offset,
            //     end_date: endDate,
            //   },

            //   { cancelToken: source.token }
            // )
            //   .then((response) => {
            //     if (response.data[0].total_count > 0) {
            //       actualCount = response.data[0].total_count;
            //     }
            //     if (pageInfo.start == 0 && response.data[0].total_count > 0) {
            //       console.log(response.data[0].total_count);
            //       return (
            //         "Showing " +
            //         sValue +
            //         " to " +
            //         pageInfo.end +
            //         " of " +
            //         val +
            //         " entries"
            //       );

            //     } else {
            //       return (
            //         "Showing " +
            //         pageInfo.start +
            //         " to " +
            //         pageInfo.end +
            //         " of " +
            //         val +
            //         " entries"
            //       );
            //     }
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
          },
          initComplete: function () {
            $.fn.dataTable.ext.classes.sLengthSelect = "myClass";
            var colvis = new $.fn.dataTable.Buttons($(tran_table), {
              //ref(tab)
              buttons: [
                {
                  extend: "colvis",
                  className: "colvis-button",
                  text:
                    "manage columns" + '<img src="/images/bluedownarrow.svg">',
                },
              ],
            });
            var csv = new $.fn.dataTable.Buttons($(tran_table), {
              //ref(tab)
              buttons: [
                {
                  extend: "csv",
                  className: "button_container",
                  text: "Download",
                  filename: getExportFileName(),
                  exportOptions: {
                    format: {
                      header: function (data, content, index, column) {
                        let names = [
                          "Decentro URN",
                          "Bank Reference No",
                          "Date-Time",
                          "Status",
                          "Provider",
                          "Transfer Type",
                          "Transaction Type",
                          "Credit/Debit",
                          "Amount",
                          "Customer Reference No",
                          "Payee Account",
                          "Beneficiary Name",
                          "Beneficiary IFSC",
                          "Payer Account",
                          "Payee VPA",
                          "Payer VPA",
                          "Provider Message",
                        ];
                        for (let i = 0; i <= names.length; i++) {
                          if (content === i) {
                            return names[i];
                          }
                        }
                      },
                    },
                  },
                },
              ],
            });
            colvis.container().appendTo("#colvis");
            $('input[type="search"]').on("input", function () {
              query = $(this).val();
              var info = $(tran_table).DataTable().page.info(); //ref(tab)
              lenght = info.recordsDisplay;
              if (lenght === 0 && query.length > 5) {
                searchAllTransactions(query); //call to search in the transaction table
              }
              if (query.length === 0) {
                //call to search in the va_table
                fallbackTransactions(consolidated);
              }
            });
            var api = this.api();
            var table = $(tran_table).DataTable();

            $(
              '<button id="show_en" class="buttons-colvis">' +
                `Show ${table.page.info().length} entries ` +
                "</button>"
            ).appendTo($(".show_entries"));
            $("#show_en").on("click", function () {
              $(".new_show_entries").toggle();
            });

            $("div.tran-date").html('<div id="DateContainer"></div>');
            APIConfig.API_Client.post(
              apiEndpointList.FETCH_ACCOUNTS_TRANSACTIONS_COUNT.baseUrl +
                apiEndpointList.FETCH_ACCOUNTS_TRANSACTIONS_COUNT.endpoint, //url shows the transaction results
              {
                limit: limitConst,
                start_date: startDate,
                offset: offset,
                end_date: endDate,
              },

              { cancelToken: source.token }
            ).then((response) => {
              setCountLoaderFlag(false);
              $("div#count_holder").html(
                response.data[0].total_count + " Entries Found"
              );
            });
            $("div.topwrapper").html(
              "<div class='title'>All Transactions <div id='count_holder'><img class='count_loader_img' src='/images/loader.gif' /></div></div>"
            );
            $("div.topwrapper").append(
              '<div id="create_virtual_acc_btn"></div>'
            );
            $(
              '<div class="new_show_entries" id="container_entries" ><span id="10"> 10 entries </span><span id="25"> 25 entries </span><span id="50"> 50 entries </span><span id="100"> 100 entries </span></div>'
            ).appendTo($(".show_entries"));
            $("#10").on("click", function () {
              table.page.len(10).draw();
              $(".new_show_entries").toggle();
              $("#show_en").html(`Show ${table.page.info().length} entries `);
            });

            $("#25").on("click", function () {
              table.page.len(25).draw();
              $(".new_show_entries").toggle();
              $("#show_en").html(`Show ${table.page.info().length} entries `);
            });
            $("#50").on("click", function () {
              table.page.len(50).draw();
              $(".new_show_entries").toggle();
              $("#show_en").html(`Show ${table.page.info().length} entries `);
            });

            $("#100").on("click", function () {
              table.page.len(100).draw();
              $(".new_show_entries").toggle();
              $("#show_en").html(`Show ${table.page.info().length} entries `);
            });
            $("div#container_entries").on("mouseleave", function () {
              $(".new_show_entries").hide();
            });

            // ReactDOM.render(
            //   <MoneyTransfer setRefreshCall={setRefresh} />,
            //   document.getElementById("create_virtual_acc_btn")
            // );
            csv.container().appendTo("#download");
            $("div#DatContainer").appendTo("#transactions_filter");
            $("#DateContainer").append(date);
            $("div.showhideButton").val(this.checked);
            table.on("page.dt", function () {
              let info = table.page.info();
              if (info.page + 1 === info.pages) {
                offset += 200;
                LoadmoreTransactions(offset);
              } else {
              }
            });
            table.columns([3]).header().to$().addClass("showCon");
            table.columns([4]).header().to$().addClass("showCon2");
            table.columns([5]).header().to$().addClass("showCon3");
            $(".mt-head").off("click.DT");
            $(".showCon").on("mouseenter", function () {
              $("#selected3").show();
            });
            $(".showCon2").on("mouseenter", function () {
              $("#selected4").show();
            });
            $(".showCon3").on("mouseenter", function () {
              $("#selected5").show();
            });
            $(".showCon").on("mouseleave", function () {
              $("#selected3").hide();
            });
            $(".showCon2").on("mouseleave", function () {
              $("#selected4").hide();
            });
            $(".showCon3").on("mouseleave", function () {
              $("#selected5").hide();
            });
            $("#transactions tbody").on(
              "click",
              ".bank_ref_click",
              function () {
                var data = table.row($(this)).data();
                ReactDOM.render(
                  <TransactionModal data={data} />,
                  document.getElementById("virtual-accounts-components")
                );
              }
            );
            $("#transactions tbody").on("click", ".dcn_urn_click", function () {
              var data = table.row($(this)).data();
              ReactDOM.render(
                <TransactionModal data={data} />,
                document.getElementById("virtual-accounts-components")
              );
            });
            //column headers for the transaction table
            api.columns([3, 4, 5]).every(function () {
              var column = this;
              var columnfilters = [];

              let notchecked = [];
              var uniqueitems = [];
              var showSelection = $(
                '<div><img src="/images/arrow_downward.svg"></div>'
              ).appendTo($(column.header()));

              var select = $(
                '<div id="selected' +
                  column.index() +
                  '"><label class="labelforfilter"></label></div>'
              )
                .appendTo($(column.header()))
                .on("change", function () {
                  localStorage.removeItem("columnfilters");
                  uniqueitems = [];

                  table
                    .column(3)
                    .data()
                    .unique()
                    .sort()
                    .each(function (d, j) {
                      var str;

                      str = d.replace(/\s/g, "_");

                      uniqueitems.push(str);
                    });
                  table
                    .column(4)
                    .data()
                    .unique()
                    .sort()
                    .each(function (d, j) {
                      var str;

                      str = d.replace(/\s/g, "_");

                      uniqueitems.push(str);
                    });
                  table
                    .column(5)
                    .data()
                    .unique()
                    .sort()
                    .each(function (d, j) {
                      var str;

                      str = d.replace(/\s/g, "_");

                      uniqueitems.push(str);
                    });
                  columnfilters = [];
                  notchecked = [];
                  uniqueitems.map((u) => {
                    var el = document.getElementById(u);
                    if (el.checked == true) {
                      columnfilters.push($(el).val());
                    } else {
                      notchecked.push($(el).val());
                    }
                  });
                  localStorage.setItem(
                    "columnfilters",
                    JSON.stringify(columnfilters)
                  );
                  var vals = $(":checked", select)
                    .map(function (index, element) {
                      return $.fn.dataTable.util.escapeRegex($(element).val());
                    })
                    .toArray()
                    .join("|");

                  column
                    .search(
                      vals.length > 0 ? "^(" + vals + ")$" : "",
                      true,
                      false
                    )
                    .draw();
                });

              column
                .data()
                .unique()
                .sort()
                .each(function (d, j) {
                  var str = d.replace(/\s/g, "_");

                  select.append(
                    '<label id="label' +
                      j +
                      '" class="labelforfilter">' +
                      d +
                      "<input id=" +
                      str +
                      ' type="checkbox" value="' +
                      d +
                      '"></label>'
                  );
                });
            });
            function getExportFileName() {
              var holder =
                "Transactions Summary from " + startDate + " to " + endDate;
              return holder;
            }

            $("#selected3").append(
              '<div id="Sorting0">Sort <img id="my1" src="/images/Sort.svg"></div>'
            );
            $("#selected4").append(
              '<div id="Sorting1">Sort <img id="my2" src="/images/Sort.svg"></div>'
            );
            $("#selected5").append(
              '<div id="Sorting2">Sort <img id="my3" src="/images/Sort.svg"></div>'
            );
            $("#selected3").append(
              '<div class="clear_filter" id="Clear0"><span class="clear_icon">x</span>Clear Filter</div>'
            );
            $("#selected4").append(
              '<div class="clear_filter" id="Clear1"><span class="clear_icon">x</span>Clear Filter</div>'
            );
            $("#selected5").append(
              '<div class="clear_filter" id="Clear2"><span class="clear_icon">x</span>Clear Filter</div>'
            );
            $("#Clear0").on("click", function () {
              table
                .columns([3])
                .data()
                .unique()
                .sort()
                .each(function (d, j) {
                  this.columns([3]).search("").draw();
                });

              table
                .columns([3])
                .data()
                .unique()
                .sort()
                .each(function (d, j) {
                  d.forEach((element) => {
                    var ele = element.replace(/\s/g, "_");
                    $("#" + ele + "").prop("checked", false);
                  });
                });
            });
            $("#Clear1").on("click", function () {
              table
                .columns([4])
                .data()
                .unique()
                .sort()
                .each(function (d, j) {
                  this.columns([4]).search("").draw();
                });
              table
                .columns([4])
                .data()
                .unique()
                .sort()
                .each(function (d, j) {
                  d.forEach((element) => {
                    var ele = element.replace(/\s/g, "_");
                    $("#" + ele + "").prop("checked", false);
                  });
                });
            });
            $("#Clear2").on("click", function () {
              table
                .columns([5])
                .data()
                .unique()
                .sort()
                .each(function (d, j) {
                  this.columns([5]).search("").draw();
                });

              table
                .columns([5])
                .data()
                .unique()
                .sort()
                .each(function (d, j) {
                  d.forEach((element) => {
                    var ele = element.replace(/\s/g, "_");
                    $("#" + ele + "").prop("checked", false);
                  });
                });
            });
            function col(val) {
              $(
                '<div><img id="cl' +
                  val.index() +
                  '" src="/images/Sort.svg"></div>'
              ).appendTo($(val.header()));
              $(val.header()).on("click", function () {
                if ($(val.header()).hasClass("sorting_asc")) {
                  $("img#cl" + val.index() + "").attr(
                    "src",
                    "/images/Sort_Up.svg"
                  );
                } else if ($(val.header()).hasClass("sorting_desc")) {
                  $("img#cl" + val.index() + "").attr(
                    "src",
                    "/images/Sort_Down.svg"
                  );
                } else if ($(val.header()).hasClass(".sorting_asc_disabled")) {
                  $("img#cl" + val.index() + "").attr(
                    "src",
                    "/images/Sort.svg"
                  );
                } else if ($(val.header()).hasClass("sorting_desc_disabled")) {
                  $("img#cl" + val.index() + "").attr(
                    "src",
                    "/images/Sort.svg"
                  );
                }
              });
            }

            api.columns([0, 1, 6, 7, 8, 9, 10, 11, 12, 13]).every(function () {
              var cl = this;
              col(cl);
            });
            $("#Sorting0").on("click", function () {
              table.order.listener("#Sorting0", 3);
              $("#Sorting0").toggleClass("showImage0");
              if ($("#Sorting0").hasClass("showImage0")) {
                $("#my1").attr("src", "/images/Sort_Up.svg");
              } else {
                $("#my1").attr("src", "/images/Sort_Down.svg");
              }
              table.order(table.columns([3]), "asc").draw();
            });
            $("#Sorting1").on("click", function () {
              table.order.listener("#Sorting1", 4);
              $("#Sorting1").toggleClass("showImage1");
              if ($("#Sorting1").hasClass("showImage1")) {
                $("#my2").attr("src", "/images/Sort_Up.svg");
              } else {
                $("#my2").attr("src", "/images/Sort_Down.svg");
              }
              table.order(table.columns([4]), "asc").draw();
            });
            $("#Sorting2").on("click", function () {
              table.order.listener("#Sorting2", 5);
              $("#Sorting2").toggleClass("showImage2");
              if ($("#Sorting2").hasClass("showImage2")) {
                $("#my3").attr("src", "/images/Sort_Up.svg");
              } else {
                $("#my3").attr("src", "/images/Sort_Down.svg");
              }
              table.order(table.columns([5]), "asc").draw();
            });
            $("#textbox1").val(this.checked);
            $("#selected3").css("display", "none");
            $("#selected4").css("display", "none");
            $("#selected5").css("display", "none");
          },

          columns: [
            {
              data: "decentro_transaction_id",
              title: "Decentro URN",
              name: "Decentro URN",
            },
            {
              data: "bank_reference_number",
              title: "Bank Ref",
              name: "Bank Ref",
            },
            {
              data: "timestamp",
              title: "Date-Time",
              type: "date",
            },
            { data: "status", title: "status", name: "status" },
            { data: "provider", title: "provider", name: "provider" },
            { data: "transaction_type", title: "Transfer type", name: "txn" },
            { data: "transfer_type", title: "Txn type", name: "txn" },
            { data: "credit_or_debit", title: "Credit/Debit" },
            { data: "amount", title: "amount", name: "amount" },
            {
              data: "customer_reference_number",
              title: "Customer ref num",
              name: "Customer",
            },
            {
              data: "beneficiary_consumer_bank_account_number",
              title: "Payee Account",
              name: "Payee Account",
            },
            {
              data: "beneficiary_consumer_bank_account_name",
              title: "Beneficiary Name",
            },
            {
              data: "beneficiary_ifsc",
              title: "Beneficiary IFSC",
            },
            {
              data: "remitter_consumer_bank_account_number",
              title: "Payer Account",
              name: "Payer Account",
            },
            {
              data: "beneficiary_vpa",
              title: "Payee VPA",
              name: "Payee VPA",
            },
            { data: "remitter_vpa", title: "Payer VPA", name: "Payer VPA" },
            {
              data: "provider_message",
              title: "Provider Message",
              name: "Provider Message",
            },
            //table data for transaction table
          ],
          columnDefs: [
            { targets: [0, 1, 6], orderable: true },
            { targets: [2], orderable: false, width: 100 },
            { targets: [3, 4, 5], className: "mt-head" },
            { targets: [1, 4, 3, 5, 6], width: 130 },
            {
              targets: [9, 10, 11, 12, 13, 14],
              visible: false,
            },
            {
              targets: [0],
              createdCell: function (td) {
                $(td).css("padding-left", "25px");
              },
            },
            {
              targets: 1,
              // className: "bank_ref_click",
              render: function (data) {
                return "<a>" + data + "</a>";
              },
            },
            {
              targets: 0,
              className: "dcn_urn_click",
              render: function (data) {
                return "<a>" + data + "</a>";
              },
            },
            {
              targets: 2,

              render: function (data) {
                // var date = new Date(data);
                // var orginalDate = moment(date.toString()).format(
                //   "DD/MM/YYYY"
                // );
                // var orginalTime = moment(date.toString()).format("LT");
                // return orginalTime + "<br>" + orginalDate;
                var date = new Date(data);
                var options = {
                  hour: "2-digit",
                  minute: "2-digit",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                };
                // return date.toLocaleTimeString("en-IN",timeOptions) + "<br>" + date.toLocaleDateString("en-IN", dateOptions)
                var final = date.toLocaleString("en-IN", options).toString();
                return final;
              },
            },
            {
              targets: 3,
              orderable: true,
              //custom styling
              createdCell: function (td, cellData, rowData, row, col) {
                switch (cellData) {
                  case "PENDING":
                    $(td).addClass("pending-column");
                    break;
                  case "FAILURE":
                    $(td).addClass("failure-column");
                    break;
                  case "SUCCESS":
                    $(td).addClass("success-column");
                    break;
                  default:
                    $(td).addClass("success-column");
                    break;
                }
              },
            },
            {
              targets: 1,
              className: "edit",
            },
          ],
        });
      });
    } catch (error) {
      if (Axios.isCancel(error)) {
      } else {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("loading_tran")
        );
        ReactDOM.unmountComponentAtNode(
          document.getElementById("main_transaction")
        );
        ReactDOM.render(
          <SkeletonEmptyDialogue type={error} />,
          document.getElementById("main_transaction")
        );
        ReactDOM.render(
          <FailureDialog closeDialog={closeDialog()} />,
          document.getElementById("failure-popup")
        );
      }
    }
  }
  // function showVaAccounts(data) {
  //   data.map((data) => {
  //     vaAccounts.push(data.account_number);
  //   });
  // }
  useEffect(() => {
    showTransactionsAccounts();
    return () => {
      source.cancel("Transactions Tab Closed");
    };
  }, []);

  return (
    <div className="content-wrapper">
      <div className="loading" id="loading_tran"></div>
      <div className="loading" id="loadingspinner"></div>
      <div className="main_container_transaction" id="main_transaction"></div>
    </div>
  );
  function TransactionTable() {
    return (
      <>
        <div id="datecont" ref={(el) => (date = el)}>
          {" "}
          {/* first table rendered at the intial rendering of this component*/}
          <DateSelector
            selectedDateRange={selectedDateRangeForLogs}
            setSelectedDateRange={(event) => {
              setSelectedDateRange([event[0], event[1]]);
              offset = 0;
              startDate = moment(event[0].toString()).format("YYYY-MM-DD");
              endDate = moment(event[1].toString()).format("YYYY-MM-DD");
              $(tran_table).DataTable().clear().destroy();
              $(tran_table).empty();
              showTransactionsAccounts();
            }}
          />
        </div>
        <table
          id="transactions"
          className="display"
          width="100%"
          ref={(el) => (tran_table = el)}
        ></table>
        <div id="showModal"></div>
        <div id="loadmore"></div>
      </>
    );
  }
}

export default AllTransactionsDatatable;
