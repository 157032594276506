import React, { useEffect, useState } from "react";
// Utils
import FormDivider from "../../UI/Divider/FormDivider";
// Components
import BulkForm from "../BulkCapabilitiesForm/BulkForm";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/core_banking/endpoint";
function BulkCapabilitiesLink({ goBackToTable }) {
  const [handle2faCheck, setHandle2faCheck] = useState(false);

  //*  MFA toggle status api
  const mfaToggleStatus = () => {
    APIConfig.API_Client.get(
      apiEndpointList.MFA_TOGGLE_DETAIL.baseUrl +
      apiEndpointList.MFA_TOGGLE_DETAIL.endpoint
    )
      .then((response) => {
        setHandle2faCheck(response?.data?.sms_mfa_enabled);
        if (!response?.data?.sms_mfa_enabled) {
          goBackToTable();
        }
      })
      .catch((error) => {
        console.error("error from Bulk Capabilities Link", error)
      });
  };

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      mfaToggleStatus();
    }
  }, []);

  return (
    <>
      <div className="add-user-page">
        <div className="add-user-content">
          <div className="add-user-card">
            <div className="back-btn">
              <img src="/images/back.svg" alt="back-icon" onClick={() => goBackToTable()} />
              <p className="heading">Bulk Upload</p>
            </div>
            <FormDivider />
            <BulkForm />
          </div>
        </div>
      </div>
    </>
  );
}
export default BulkCapabilitiesLink;