import React from 'react';
import SkeletonElements from './SkeletonElements';
import Shimmer from './Shimmer';

function SkeletonDatable() {
  return (
    <div className='skeleton-datatable-wrapper'>
        <div className='title-holder'>
        <SkeletonElements type="title"/>
        <SkeletonElements type="buttons"/>
        </div>
        
        <div className='top-wrapper'>
        <div className='button-div'>
        <SkeletonElements type="edit"/> {[1,2,3,4].map((n)=><SkeletonElements type="buttons3" key={n}/>)}
        </div>
       
       
        </div>
        {<SkeletonElements type="header"/>}
        {[1,2].map((n)=> <SkeletonElements type="row" key={n}/>)}
        <Shimmer/>
       
        


    </div>
  )
}

export default SkeletonDatable