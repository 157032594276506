import React, { createContext, useState, useEffect } from "react";
import { APIConfig } from "../services/apiConfiguration";
import axios from "axios";
import ReactDOM from "react-dom";
// import UserManagementSuccess from "../components/UserManagement/UserManagementSuccess/UserManagementSuccess";
import { Loader } from "rsuite";

import SnackbarMessage from "../components/Shared/SnackbarMessage/SnackbarMessage";
import apiEndpointList from "../config/core_banking/endpoint";
import ManageBeneficiarySuccess from "../components/ManageBeneficiary/ManageBeneficiarySuccess/ManageBeneficiarySuccess";

const ManageBeneficiaryContext = createContext();
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const ManageBeneficairyActionProvider = ({ children }) => {
  // edit user state
  const [tableEditRow, setTableEditRow] = useState({ item: "", edit: false });
  const [currentSearchScope, setCurrentSearchScope] = useState("");

  const modify = () => {
    // setCardType("userSummary");
    setTableEditRow({ item: "", edit: true });
  };

  const addBeneficiaryApi = (payload, resetInputs, provider_code) => {
    ReactDOM.render(
      <Loader size="xs" />,
      document.getElementById("user-config-loader")
    );
    APIConfig.API_Client.post(
      apiEndpointList.MANAGE_BENEFICIARY_API.baseUrl +
        apiEndpointList.MANAGE_BENEFICIARY_API.endpoint,

      // APIConfig.BASE_URL + apiEndpointList.USER_REGISTER
      payload,
      { headers: { provider_code: provider_code } },
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <ManageBeneficiarySuccess
            data={response.data.data}
            type={payload.action}
          />,
          document.getElementById("manage-beneficiary-components")
        );
        resetInputs();
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };
  const editBeneficiaryApi = (payload, code) => {
    ReactDOM.render(
      <Loader size="xs" />,
      document.getElementById("user-config-loader")
    );

    APIConfig.API_Client.post(
      apiEndpointList.MANAGE_BENEFICIARY_API.baseUrl +
        apiEndpointList.MANAGE_BENEFICIARY_API.endpoint,
      // APIConfig.BASE_URL + apiEndpointList.USER_UPDATE_REGISTER,
      payload,
      { headers: { provider_code: code } },
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <ManageBeneficiarySuccess
            data={response.data.data}
            type={payload.action}
          />,
          document.getElementById("manage-beneficiary-components")
        );
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  return (
    <ManageBeneficiaryContext.Provider
      value={{
        tableEditRow,
        setTableEditRow,
        addBeneficiaryApi,
        editBeneficiaryApi,
        modify,
      }}
    >
      {children}
    </ManageBeneficiaryContext.Provider>
  );
};

export default ManageBeneficiaryContext;
