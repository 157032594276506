import React, { createContext, useState, useEffect } from "react";
import apiEndpointList from "../../config/core_banking/endpoint";
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";

export const ValidateAccountsContextProvider = createContext();

const ValidateAccountsContext = ({ children }) => {
    const [tableData, setTableData] = useState([]);
    const [isLazyFetching, setIsLazyFetching] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [refreshing, setIsRefreshing] = useState(false);
    const [initialLoading, setInitialLoading] = useState(tableData?.length === 0);
    const [newTokenVa, setNewToken] = useState(null);
    const [noDataFound, setNoDataFound] = useState(false);
    const [firstApiCall, setfirstApiCall] = useState(true);
    let [totalcountNotset, settotalcountNotset] = useState(true);
    const [showModalDialog, setShowModalDialog] = useState(false);
    const [searchParameter, setSearchParameter] = useState({
        bank_ref_number: false,
        decentro_urn: false,
        customer_ref_number: false,
    });
    const [currentSearchScope, setCurrentSearchScope] = useState("");
    const limit = 200;
    let lastInterval;
    const [filterList, setFilterList] = useState({
        search: "",
        resultsPerPage: 10,
        currentPage: 1,
    });
    const [errorResponse, setErrorResponse] = useState();
    const [isError, setError] = useState(false);
    let initialStartDate = new Date();
    initialStartDate.setDate(initialStartDate.getDate() - 14);
    const [dateFilters, setDateFilters] = useState([
        initialStartDate,
        new Date(),
    ]);
    const getDateFormatted = (date) => {
        const dateObj = new Date(date);
        const month = dateObj.getMonth() + 1;
        const dateTime = dateObj.getDate();
        return `${dateObj.getFullYear()}-${month > 9 ? month : `0${month}`}-${dateTime > 9 ? dateTime : `0${dateTime}`
            }`;
    };
    const [lastApiTime, setLastApiTime] = useState(0);

    const fetchData = async (
        lastItem,
        filterData,
        customDate,
        clearOld,
        isLazy
    ) => {
        if (!isLazy && firstApiCall) {
            setIsLoading(true);
        } else {
            setIsLazyFetching(true);
        }
        if (newTokenVa) {
            newTokenVa.cancel();
            setNewToken(null);
        }
        const newToken = axios.CancelToken.source();
        setNewToken(newToken);

        let filters = filterData ?? filterList;
        let dateData = customDate ?? dateFilters;

        const countApiPath =
            apiEndpointList.VALIDATE_BANK_ACCOUNT_COUNT.baseUrl +
            apiEndpointList.VALIDATE_BANK_ACCOUNT_COUNT.endpoint;

        const dataApiPath =
            apiEndpointList.VALIDATE_BANK_ACCOUNT_TRANSACTIONS.baseUrl +
            apiEndpointList.VALIDATE_BANK_ACCOUNT_TRANSACTIONS.endpoint;
        const startIndex = (filterList.currentPage - 1) * filterList.resultsPerPage;
        const endIndex = startIndex + filterList.resultsPerPage;
        const searchTerm = filters?.search !== "" ? filters.search : null;
        let body = {
            ...(searchTerm
                ? searchParameter.decentro_urn
                    ? {
                        decentro_company_urn: searchTerm,
                        limit,
                        offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
                    }
                    : searchParameter.customer_ref_number
                        ? {
                            customer_reference_number: searchTerm,
                            limit,
                            offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
                        }
                        : searchParameter.bank_ref_number
                            ? {
                                bank_reference_number: searchTerm,
                                limit,
                                offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
                            }
                            : {
                                decentro_company_urn: searchTerm,
                                limit,
                                offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
                            }
                : {
                    limit,
                    end_date: getDateFormatted(dateData[1]),
                    offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
                    start_date: getDateFormatted(dateData[0]),
                }),
        };
        const userData = await APIConfig.API_Client.post(dataApiPath, body, {
            cancelToken: newToken.token,
        })
            .then((res) => {
                setIsRefreshing(false);
                setInitialLoading(false);
                setIsLazyFetching(false);
                if (res.data?.length >= 0) {
                    if ((filters.currentPage === 1 || clearOld) && !isLazy) {
                        setTableData(res.data);
                    } else {
                        setTableData([...tableData, ...res.data]);
                    }
                }
                return res.data;
            })
            .catch((e) => {
                if (axios.isCancel(e)) {
                    console.error("error from line 133", e);
                } else {
                    setError(true);
                    setErrorResponse(e);
                }
            });

        let promiseList = [userData];
        if (!isLazy && firstApiCall) {
            const dataCount = await APIConfig.API_Client.post(countApiPath, body, {
                cancelToken: newToken.token,
            }).then((res) => {
                return res.data;
            });
            promiseList.push(dataCount);
        }
        await axios
            .all(promiseList)
            .then((res) => {
                setIsLoading(false);
                setIsLazyFetching(false);
                if (!res[0]?.length) {
                    setNoDataFound(true);
                }
                setTotalCount(res[1][0].total_count);
            })
            .catch((e) => {
                console.error("error from line 160", e);
            });
    };
    function refreshTable() {
        setShowModalDialog(false);
        setNoDataFound(false);
        setInitialLoading(true);
        setfirstApiCall(true);
        clearInterval(lastInterval);
        setFilterList({ ...filterList, currentPage: 1 });
        fetchData(1, { ...filterList, currentPage: 1 }, undefined, true);
    }

    useEffect(() => {
        lastInterval = setInterval(() => {
            setLastApiTime((pre) => pre + 1);
        }, 60000);
        return () => {
            if (lastInterval) {
                clearInterval(lastInterval);
            }
            if (newTokenVa) {
                newTokenVa.cancel();
            }
        };
    }, []);

    return (
        <ValidateAccountsContextProvider.Provider
            value={{
                setDateFilters,
                setFilterList,
                setInitialLoading,
                setIsLazyFetching,
                setIsLoading,
                setNewToken,
                setSelectedTransaction,
                setTotalCount,
                setTableData,
                fetchData,
                setNoDataFound,
                getDateFormatted,
                isLazyFetching,
                isLoading,
                tableData,
                filterList,
                limit,
                totalCount,
                noDataFound,
                initialLoading,
                selectedTransaction,
                dateFilters,
                lastApiTime,
                setLastApiTime,
                lastInterval,
                refreshing,
                setIsRefreshing,
                settotalcountNotset,
                isError,
                errorResponse,
                setError,
                setErrorResponse,
                setfirstApiCall,
                setShowModalDialog,
                showModalDialog,
                refreshTable,
                firstApiCall,
                searchParameter,
                setSearchParameter,
                currentSearchScope,
                setCurrentSearchScope,
            }}
        >
            {children}
        </ValidateAccountsContextProvider.Provider>
    );
};

export default ValidateAccountsContext;
