// React Imports
import React, { createContext, useState, useEffect } from "react";
// Utils
import apiEndpointList from "../../config/core_banking/endpoint";
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";

export const CallBackContextProvider = createContext();

const CallBackContext = ({ children }) => {
  const [tableData, setTableData] = useState([]);
  const [errorResponse, setErrorResponse] = useState()
  const [isError, setError] = useState(false)
  const [isLazyFetching, setIsLazyFetching] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [initialLoading, setInitialLoading] = useState(tableData?.length === 0);
  const [newTokenVa, setNewToken] = useState(null);
  const [noDataFound, setNoDataFound] = useState(false);
  const [firstApiCall, setfirstApiCall] = useState(true);
  let [totalcountNotset, settotalcountNotset] = useState(true);
  const [cardType, setCardType] = useState("allCallback");


  const limit = 200;
  const [filterList, setFilterList] = useState({
    search: "",
    resultsPerPage: 10,
    currentPage: 1,
  });
  let lastInterval;
  let initialStartDate = new Date()
  initialStartDate.setDate(initialStartDate.getDate() - 14)
  const [dateFilters, setDateFilters] = useState([
    initialStartDate,
    new Date(),
  ]);
  const getDateFormatted = (date) => {
    const dateObj = new Date(date);
    const month = dateObj.getMonth() + 1;
    const dateTime = dateObj.getDate();
    return `${dateObj.getFullYear()}-${month > 9 ? month : `0${month}`}-${dateTime > 9 ? dateTime : `0${dateTime}`
      }`;
  };
  const [lastApiTime, setLastApiTime] = useState(0);

  const fetchData = async (
    lastItem,
    filterData,
    customDate,
    clearOld,
    isLazy
  ) => {
    if (filterList.search && isLazy) return;

    if (!isLazy) {
      setIsLoading(true);
    } else {
      setIsLazyFetching(true);
    }
    if (newTokenVa) {
      newTokenVa.cancel();
      setNewToken(null);
    }
    const newToken = axios.CancelToken.source();
    setNewToken(newToken);

    let filters = filterData ?? filterList;
    let dateData = customDate ?? dateFilters;
    // const countApiPath =
    //   apiEndpointList.FETCH_PAYMENT_LINKS_COUNT.baseUrl +
    //   apiEndpointList.FETCH_PAYMENT_LINKS_COUNT.endpoint;

    const dataApiPath = apiEndpointList.CALLBACK_SUBSCRIPTION.baseUrl + apiEndpointList.CALLBACK_SUBSCRIPTION.endpoint;
    const startIndex = (filterList.currentPage - 1) * filterList.resultsPerPage;
    const endIndex = startIndex + filterList.resultsPerPage;
    const searchTerm = filterList?.search !== "" ? filterList.search : null;
    let body = {
      //   ...(searchTerm && { decentro_company_urn: searchTerm }),

      //   limit,
      //   end_date: getDateFormatted(dateData[1]),
      //   offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
      //   start_date: getDateFormatted(dateData[0]),
    };
    let promiseList = [];
    const userData = APIConfig.API_Client.post(dataApiPath, body).then((res) => {

      setInitialLoading(false);
      if ((filters.currentPage === 1 || clearOld) && !isLazy) {
        setTableData(res.data);
      } else {
        setTableData([...tableData, ...res.data]);
      }
      return res.data;
    });
    promiseList.push(userData);

    await axios
      .all(promiseList)
      .then((res) => {
        setIsLoading(false);
        setIsLazyFetching(false);
        if (!res[0]?.length) {
          setNoDataFound(true);
        }
      })
      .catch((e) => {
        console.error("error from callbackContext", e)
        setError(true)
        setErrorResponse(e)
      });
  };
  useEffect(() => {
    const prefetchLimit = 150;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;

    if (totalCount > 0) {
      if (
        tableData.length < totalCount &&
        tableData.length < limitConstant &&
        isLazyFetching === false
      ) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, true);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true &&
      isLazyFetching === false
    ) {
      setfirstApiCall(false);
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }


  }, [tableData, filterList.currentPage]);

  useEffect(() => {
    lastInterval = setInterval(() => {
      setLastApiTime((pre) => pre + 1);
    }, 60000);
    return () => {
      if (lastInterval) {
        clearInterval(lastInterval);
      }
      if (newTokenVa) {
        newTokenVa.cancel()
      }
    };
  }, []);

  const addNewCallback = () => {
    setCardType('newCallback');
  };

  return (
    <CallBackContextProvider.Provider
      value={{
        setDateFilters,
        setFilterList,
        setInitialLoading,
        setIsLazyFetching,
        setIsLoading,
        setNewToken,
        setSelectedTransaction,
        setTotalCount,
        setTableData,
        fetchData,
        setNoDataFound,
        getDateFormatted,
        isLazyFetching,
        isLoading,
        tableData,
        filterList,
        limit,
        totalCount,
        noDataFound,
        initialLoading,
        selectedTransaction,
        dateFilters,
        lastApiTime,
        setLastApiTime,
        lastInterval,
        settotalcountNotset,
        isError,
        errorResponse,
        setfirstApiCall
      }}
    >
      {children}{" "}
    </CallBackContextProvider.Provider>
  );
};

export default CallBackContext;
