import React from "react";
import "./StatusCell.scss";

const StatusCell = (props) => {
  const value = props.getValue();
  const formatColor = (data) => {
    switch (data) {
      case "PENDING":
      case "Pending":
      case "PROCESSING":
        return "pending";
      case "FAILURE":
      case "Failure":
      case "REJECTED":
      case "FAILED":
      case "Failed":
      case "Rejected":
      case "Inactive":
        return "failure";
      case "SUCCESS":
      case "COMPLETED":
      case "Success":
      case "ACTIVE":
      case "Active":
        return "success";
      case "VALID":
      case "Valid":
        return "Valid";
      case "INVALID":
      case "Invalid":
        return "Invalid";
      case "INCONCLUSIVE":
      case "Inconclusive":
        return "Inconclusive";
      default:
        return "black";
    }
  };
  const formattedData = formatColor(value);
  return <div className={formattedData}>{value}</div>;
};

export default StatusCell;
