import React from 'react';
import PropTypes from 'prop-types';
import './DecentroBanner.scss';

const DecentroBanner = (props) => (
    <div className="banner">
        <img src="/images/decentro-banner.png" alt="banner" />
        <div className="bannerText">
            <h3>{props.label.light}</h3>
            <h2>{props.label.bold}</h2>
        </div>
    </div>
);

DecentroBanner.propTypes = {
    label: PropTypes.any
}

DecentroBanner.defaultProps = {
    label: { 'light': "Welcome", 'bold': "Back!" }
}

export default DecentroBanner;
