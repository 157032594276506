import React from "react";
//To create a user componet
//styles

import "./Emandate.scss";
// Components
import EmandateForm from '../EmandateForm/EmandateForm';
import EmandateFormFormik from "../EmandateForm/EmandateFormFormik";
import FormDivider from "../../../../UI/Divider/FormDivider";


function Emandate({ goBackToTable }) {


  return (
    <>
      <div className="add-user-page">
        <div className="add-user-content">
          <div className="add-user-card">
            {/* {tableEditRow && (
              <div className="back-btn" onClick={()=>goBackToTable()}>
                <img src="/images/back.svg" alt="back-icon" />
                <h1 className="heading">
                  <span className="company-name-highlight">
                    Modify Beneficiary
                  </span>
                </h1>
              </div>
            )} */}

            <div className="back-btn">
              <img src="/images/back.svg" alt="back-icon" onClick={() => goBackToTable()} />
              <p className="heading">eMandate Registration</p>
            </div>

            <FormDivider />
            {/* <EmandateForm /> */}
            <EmandateFormFormik />
          </div>
        </div>
      </div>
    </>
  );
}

export default Emandate;
