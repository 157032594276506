const FormatBalance = (value) => {
    if (value === null || value === "-" || isNaN(value)) {
        return "-"
    }
    const formatter = new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
}

export default FormatBalance;