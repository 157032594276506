import React from "react";
//To create a user componet
import ManageBeneficairyForm from "../ManageBeneficiaryForm/ManageBeneficiaryForm";
//styles
import "./ManageBeneficiary.scss";
// Components
import ManageBeneficairyFormFormik from "../ManageBeneficiaryForm/ManageBeneficairyFormFormik";
import FormDivider from "../../UI/Divider/FormDivider";


function ManageBeneficiaryModal({ data, goBackToTable, tableEditRow }) {


  return (
    <>
      <div className="add-user-page">
        <div className="add-user-content">
          <div className="add-user-card">
            {tableEditRow && (
              <div className="back-btn" onClick={() => goBackToTable()}>
                <img src="/images/back.svg" alt="back-icon" />
                <h1 className="heading">
                  <span className="company-name-highlight">
                    Modify Beneficiary
                  </span>
                </h1>
              </div>
            )}
            {!tableEditRow && (
              <div className="back-btn" >
                <img src="/images/back.svg" alt="back-icon" onClick={() => goBackToTable()} />
                <p className="heading">Add Beneficiary</p>
              </div>
            )}
            <FormDivider />
            {/* <ManageBeneficairyForm data={data} tableEditRow={tableEditRow} /> */}
            <ManageBeneficairyFormFormik data={data} tableEditRow={tableEditRow} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageBeneficiaryModal;
