import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from 'axios-jwt';

const PrivateRoute: React.FC<{
    component: React.FC;
    path: string;
    exact: boolean;
}> = (props) => {

    const condition = isLoggedIn();

    return condition ?
        props.path === '/login' || props.path === '/signup' || props.path === '/forgot-password' || props.path === '/select-module' ?
            (<Redirect to='/dashboard/accounts/analytics' />)
            :
            (<Route path={props.path} component={props.component} />)
        :
        props.path === '/login' || props.path === '/signup' || props.path === '/forgot-password' || props.path === '/select-module' ?
            (<Route path={props.path} component={props.component} />)
            :
            (<Redirect to='/login' />)
}

export default PrivateRoute;