import React from "react";
import ReactDOM from "react-dom";

import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// STYLES
import "./TransactionModal.scss";
import { data } from "jquery";

function TransactionModal({ data }) {
  return (
    <div>
      <ModalComponent {...data = data} />
    </div>

  );
}

function ModalComponent(data) {
  // Modal unmount function
  const closeTableDetailModal = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("virtual-accounts-components")
    );
  };
  return (
    <React.Fragment>
      <Dialog
        className="va-details"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-popup">
          <CloseIcon onClick={closeTableDetailModal} />
        </div>

        <DialogContent>
          <div className="va-details-heading">
            <p>Transaction Details</p>
          </div>
          <div className="va-details-container">
            {/* LEFT - BLOCK */}

            <div className="left-detail-block">
              <table>
                <tbody>
                  <tr>
                    <th>Amount</th>
                    <td>{data?.amount || "NA"}</td>
                  </tr>

                  <tr>
                    <th>Bank</th>
                    <td>{data?.provider || "NA"}</td>
                  </tr>

                  <tr>
                    <th>Credit / Debit </th>
                    <td>{data?.credit_or_debit || "NA"}</td>
                  </tr>

                  <tr>
                    <th>Payer Account Number</th>
                    <td>{data?.remitter_consumer_bank_account_number || "NA"}</td>
                  </tr>
                  <tr>
                    <th>Payee Account Number</th>
                    <td>{data?.beneficiary_consumer_bank_account_number || "NA"}</td>
                  </tr>
                  <tr>
                    <th>IFSC Code</th>
                    <td>{data?.remitter_ifsc || "NA"}</td>
                  </tr>

                  <tr>
                    <th>UPI ID</th>
                    <td>{data?.remitter_vpa || "NA"}</td>
                  </tr>

                  <tr>
                    <th>Date</th>
                    <td>{data?.date || "NA"}</td>
                  </tr>

                  <tr>
                    <th>Customer Reference</th>
                    <td>{data?.customer_reference_number || "NA"}</td>
                  </tr>

                  <tr>
                    <th>Payment Method</th>
                    <td>{data?.transaction_type || "NA"}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default TransactionModal;
