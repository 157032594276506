import React from "react";
import moment from "moment";

const TimeCell = (props) => {
  const value = props.getValue();

  if (value === "-" || null) {
    return "-"
  }

  const formatDate = (data) => {
    var date = new Date(data);
    var originalDate = moment(date.toString()).format("DD/MM/YYYY");
    var originalTime = moment(date.toString()).format("LT");
    return { originalTime, originalDate };
  };

  // removing letters from timestamp incase there is any like in download reports. i.e: "2024-04-23T20:31:55.05Z" to "2024-04-23 20:31:55.05"
  const removeAlphabets = str => str.replace(/[a-zA-Z]/g, ' ')

  const removedAlphabets = removeAlphabets(value)
  const formattedDate = formatDate(removedAlphabets);

  return (
    <div>
      {formattedDate.originalDate} <br /> {formattedDate.originalTime}
    </div>
  );
};

export default TimeCell;