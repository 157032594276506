const REGEXP = {
  phoneNumberRegex: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
  emailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  otpRegex: /^[0-9]{1,6}$/,
};

const mobileRegexValidate = (phoneNumber) => {
  if (phoneNumber.match(REGEXP.phoneNumberRegex)) {
    return true;
  }
  return false;
};

const emailRegexValidate = (email) => {
  if (email.match(REGEXP.emailRegex)) {
    return true;
  }
  return false;
};

const otpRegexValidate = (otp) => {
  if (otp.match(REGEXP.otpRegex)) {
    return true;
  }
  return false;
};

export { mobileRegexValidate, emailRegexValidate, otpRegexValidate };