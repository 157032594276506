import React, { createContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";
// axios
import axios from "axios";
// utils
import apiEndpointList from "../../../config/core_banking/endpoint";
import { APIConfig } from "../../../services/apiConfiguration";
import SnackbarMessage from "../../Shared/SnackbarMessage/SnackbarMessage";

export const BulkCapabilitiesContextProvider = createContext();

const BulkCapabilitiesContext = ({ children }) => {
  const [userURN, setUserURN] = useState("");
  const [currentView, setCurrentView] = useState("parent");
  const [tableData, setTableData] = useState([]);
  const [childTableData, setChildTableData] = useState([]);
  const [isLazyFetching, setIsLazyFetching] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [childTotalCount, setChildTotalCount] = useState(0);
  const [initialLoading, setInitialLoading] = useState(tableData?.length === 0);
  const [refreshing, setIsRefreshing] = useState(false);
  const [newTokenVa, setNewToken] = useState(null);
  const [noDataFound, setNoDataFound] = useState(false);
  const [loadMoreLimit, setLoadMoreLimit] = useState(0);
  const [firstApiCall, setfirstApiCall] = useState(true);
  const [dateSearch, setDateSearch] = useState(false);
  const [showModalDialog, setShowModalDialog] = useState(false); // To use to control show of modals
  const [childPayload, setChildPayload] = useState({
    bulk_processing_urn: "",
    // type: "",
    // status: "",
  });
  const [searchParameter, setSearchParameter] = useState({
    bank_reference_number: false,
    decentro_urn: false,
    customer_ref_number: false,
  });
  const [currentSearchScope, setCurrentSearchScope] = useState("");
  let lastInterval;
  let [totalcountNotset, settotalcountNotset] = useState(true);
  const limit = 200;
  const [filterList, setFilterList] = useState({
    search: "",
    resultsPerPage: 10,
    currentPage: 1,
  });
  const [childFilterList, setChildFilterList] = useState({
    search: "",
    resultsPerPage: 10,
    currentPage: 1,
  });
  const [errorResponse, setErrorResponse] = useState();
  const [isError, setError] = useState(false);
  let initialStartDate = new Date();
  initialStartDate.setDate(initialStartDate.getDate() - 14);
  const [dateFilters, setDateFilters] = useState([
    initialStartDate,
    new Date(),
  ]);
  const getDateFormatted = (date) => {
    const dateObj = new Date(date);
    const month = dateObj.getMonth() + 1;
    const dateTime = dateObj.getDate();
    return `${dateObj.getFullYear()}-${month > 9 ? month : `0${month}`}-${
      dateTime > 9 ? dateTime : `0${dateTime}`
    }`;
  };
  const [lastApiTime, setLastApiTime] = useState(0);
  const fetchChildData = async (
    lastItem,
    filterData,
    customDate,
    clearOld,
    isLazy,
    urn
  ) => {
    if (!isLazy && firstApiCall) {
      setIsLoading(true);
    } else {
      setIsLazyFetching(true);
    }
    // if (newTokenVa) {
    //     newTokenVa.cancel();
    //     setNewToken(null);
    // }
    // const newToken = axios.CancelToken.source();
    // setNewToken(newToken);

    let filters = filterData ?? childFilterList;
    let dateData = customDate ?? dateFilters;
    const countApiPath =
      apiEndpointList.BULK_CHILD_COUNT_PAYMENTS.baseUrl +
      apiEndpointList.BULK_CHILD_COUNT_PAYMENTS.endpoint;

    const payload = urn;

    const dataApiPath =
      apiEndpointList.FETCH_BULK_CHILD_DATA_PAYMENTS.baseUrl +
      apiEndpointList.FETCH_BULK_CHILD_DATA_PAYMENTS.endpoint;
    const startIndex =
      (childFilterList.currentPage - 1) * childFilterList.resultsPerPage;
    const endIndex = startIndex + childFilterList.resultsPerPage;
    const searchTerm = filters?.search !== "" ? filters.search : null;
    let limitValue = (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit;
    setLoadMoreLimit(limitValue);
    // Find the key with value true in searchParameter
    const searchKey = Object.keys(searchParameter).find(
      (key) => searchParameter[key]
    );
    let body = {
      ...(dateSearch && searchTerm
        ? {
            // ...payload,
            // bulk_processing_urn: searchTerm,
            [searchKey || "bulk_processing_urn"]: searchTerm,
            // end_date: getDateFormatted(dateData[1]),
            limit,
            offset:
              (Math.ceil((lastItem ?? childTableData.length) / limit) - 1) *
              limit,
            // start_date: getDateFormatted(dateData[0]),
          }
        : dateSearch
        ? {
            bulk_processing_urn: payload,
            // end_date: getDateFormatted(dateData[1]),
            limit,
            offset:
              (Math.ceil((lastItem ?? childTableData.length) / limit) - 1) *
              limit,
            // start_date: getDateFormatted(dateData[0]),
          }
        : searchTerm
        ? {
            // bulk_processing_urn: payload,
            // bulk_processing_urn: searchTerm,
            bulk_processing_urn: childPayload.bulk_processing_urn,
            [searchKey || "bulk_processing_urn"]: searchTerm,
            limit,
            offset:
              (Math.ceil((lastItem ?? childTableData.length) / limit) - 1) *
              limit,
          }
        : {
            bulk_processing_urn: payload,
            limit,
            // end_date: getDateFormatted(dateData[1]),
            offset:
              (Math.ceil((lastItem ?? childTableData.length) / limit) - 1) *
              limit,
            // start_date: getDateFormatted(dateData[0]),
          }),
    };
    const userData = await APIConfig.API_Client.post(
      dataApiPath,
      body
      // {
      //     cancelToken: newToken.token,
      // }
    )
      .then((res) => {
        setIsLoading(false);
        setIsRefreshing(false);
        setInitialLoading(false);
        setIsLazyFetching(false);
        if (res.data?.length > 0) {
          if ((filters.currentPage === 1 || clearOld) && !isLazy) {
            setChildTableData(res.data);
          } else {
            setChildTableData([...childTableData, ...res.data]);
          }
        }
        return res.data;
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          console.log(e);
        } else {
          setError(true);
          setErrorResponse(e);
        }
      });

    let promiseList = [userData];
    // Removing limit and offset for the count api
    let childCountBody = Object.entries(body)
      .filter(([key]) => key !== "limit" && key !== "offset")
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    // if (!isLazy && firstApiCall) {
    const dataCount = APIConfig.API_Client.post(countApiPath, childCountBody, {
      // cancelToken: newToken.token,
    }).then((res) => {
      return res.data;
    });
    promiseList.push(dataCount);
    // }
    axios
      .all(promiseList)
      .then((res) => {
        // setInitialLoading(false);
        setIsLoading(false);
        setIsLazyFetching(false);
        if (!res[0]?.length) {
          setNoDataFound(true);
        }
        setChildTotalCount(res[1][0].total_count);
      })
      .catch((e) => {
        setIsLoading(false);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={e?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };
  // TODO: REFRESH FOR CHILD
  function refreshTable() {
    setCurrentView("parent");
    setShowModalDialog(false);
    setNoDataFound(false);
    setInitialLoading(true);
    setfirstApiCall(true);
    clearInterval(lastInterval);
    setFilterList({ ...filterList, currentPage: 1 });
    fetchData(1, { ...filterList, currentPage: 1 }, undefined, true);
  }

  const fetchData = async (
    lastItem,
    filterData,
    customDate,
    clearOld,
    isLazy
  ) => {
    if (!isLazy && firstApiCall) {
      setIsLoading(true);
    } else {
      setIsLazyFetching(true);
    }
    if (newTokenVa) {
      newTokenVa.cancel();
      setNewToken(null);
    }
    const newToken = axios.CancelToken.source();
    setNewToken(newToken);
    let filters = filterData ?? filterList;
    let dateData = customDate ?? dateFilters;
    const countApiPath =
      apiEndpointList.BULK_PARENT_COUNT_PAYMENTS.baseUrl +
      apiEndpointList.BULK_PARENT_COUNT_PAYMENTS.endpoint;

    const dataApiPath =
      apiEndpointList.FETCH_BULK_PARENT_DATA_PAYMENTS.baseUrl +
      apiEndpointList.FETCH_BULK_PARENT_DATA_PAYMENTS.endpoint;
    const startIndex = (filterList.currentPage - 1) * filterList.resultsPerPage;
    const endIndex = startIndex + filterList.resultsPerPage;
    const searchTerm = filters?.search !== "" ? filters.search : null;
    let limitValue = (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit;
    setLoadMoreLimit(limitValue);
    let body = {
      ...(dateSearch && searchTerm
        ? {
            // ...childPayload,
            bulk_processing_urn: searchTerm,
            end_date: getDateFormatted(dateData[1]),
            limit,
            offset:
              (Math.ceil((lastItem ?? tableData.length) / limit) - 1) * limit,
            start_date: getDateFormatted(dateData[0]),
          }
        : dateSearch
        ? {
            end_date: getDateFormatted(dateData[1]),
            limit,
            offset:
              (Math.ceil((lastItem ?? tableData.length) / limit) - 1) * limit,
            start_date: getDateFormatted(dateData[0]),
          }
        : searchTerm
        ? {
            end_date: getDateFormatted(dateData[1]),
            bulk_processing_urn: searchTerm,
            limit,
            offset:
              (Math.ceil((lastItem ?? tableData.length) / limit) - 1) * limit,
            start_date: getDateFormatted(dateData[0]),
          }
        : {
            limit,
            end_date: getDateFormatted(dateData[1]),
            offset:
              (Math.ceil((lastItem ?? tableData.length) / limit) - 1) * limit,
            start_date: getDateFormatted(dateData[0]),
          }),
    };
    // let body = {
    //     ...(searchTerm
    //         ? searchParameter.decentro_urn
    //             ? {
    //                 decentro_company_urn: searchTerm,
    //                 limit,
    //                 offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
    //             }
    //             : searchParameter.customer_ref_number
    //                 ? {
    //                     customer_reference_number: searchTerm,
    //                     limit,
    //                     offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
    //                 }
    //                 : searchParameter.bank_ref_number
    //                     ? {
    //                         bank_reference_number: searchTerm,
    //                         limit,
    //                         offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
    //                     }
    //                     : {
    //                         decentro_company_urn: searchTerm,
    //                         limit,
    //                         offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
    //                     }
    //         : {
    //             limit,
    //             end_date: getDateFormatted(dateData[1]),
    //             offset: (Math.ceil((lastItem ?? endIndex) / limit) - 1) * limit,
    //             start_date: getDateFormatted(dateData[0]),
    //         }),
    // };
    // let promiseList = [];
    const userData = await APIConfig.API_Client.post(dataApiPath, body, {
      cancelToken: newToken.token,
    })
      .then((res) => {
        setIsLoading(false);
        setIsRefreshing(false);
        setInitialLoading(false);
        setIsLazyFetching(false);
        if (res.data?.length > 0) {
          if ((filters.currentPage === 1 || clearOld) && !isLazy) {
            const modifiedData = res?.data?.map((each) => ({
              ...each,
              input_file:
                apiEndpointList.BULK_INPUT_FILE.baseUrl +
                apiEndpointList.BULK_INPUT_FILE.endpoint +
                `/${each.decentro_company_urn}`,
              output_file:
                apiEndpointList.BULK_OUTPUT_FILE.baseUrl +
                apiEndpointList.BULK_OUTPUT_FILE.endpoint +
                `/${each.decentro_company_urn}`,
            }));
            setTableData(modifiedData);
          } else {
            setTableData([...tableData, ...res.data]);
          }
        }
        return res.data;
      })
      .catch((e) => {
        setIsLoading(false);
        if (axios.isCancel(e)) {
          console.log(e);
        } else {
          setError(true);
          setErrorResponse(e);
        }
      });

    let promiseList = [userData];
    if (!isLazy && firstApiCall) {
      const dataCount = APIConfig.API_Client.post(countApiPath, body, {
        cancelToken: newToken.token,
      }).then((res) => {
        return res.data;
      });
      promiseList.push(dataCount);
    }
    axios
      .all(promiseList)
      .then((res) => {
        // setInitialLoading(false);
        setIsLoading(false);
        setIsLazyFetching(false);
        if (!res[0]?.length) {
          setNoDataFound(true);
        }
        setTotalCount(res[1][0].total_count);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    lastInterval = setInterval(() => {
      setLastApiTime((pre) => pre + 1);
    }, 60000);
    return () => {
      if (lastInterval) {
        clearInterval(lastInterval);
      }
      if (newTokenVa) {
        setTableData([]);
        newTokenVa.cancel();
      }
    };
  }, []);

  return (
    <BulkCapabilitiesContextProvider.Provider
      value={{
        setDateFilters,
        setFilterList,
        setInitialLoading,
        setIsLazyFetching,
        setIsLoading,
        setNewToken,
        setSelectedTransaction,
        setTotalCount,
        setChildTotalCount,
        setTableData,
        fetchData,
        setNoDataFound,
        getDateFormatted,
        isLazyFetching,
        isLoading,
        tableData,
        filterList,
        limit,
        totalCount,
        childTotalCount,
        noDataFound,
        initialLoading,
        selectedTransaction,
        dateFilters,
        settotalcountNotset,
        setLastApiTime,
        lastApiTime,
        setIsRefreshing,
        refreshing,
        isError,
        errorResponse,
        setError,
        setErrorResponse,
        setfirstApiCall,
        firstApiCall,
        searchParameter,
        setSearchParameter,
        currentSearchScope,
        setCurrentSearchScope,
        currentView,
        setCurrentView,
        childPayload,
        setChildPayload,
        fetchChildData,
        setSearchParameter,
        showModalDialog,
        setShowModalDialog,
        refreshTable,
        userURN,
        setUserURN,
        childTableData,
        setChildTableData,
        setChildFilterList,
        childFilterList,
      }}
    >
      {children}{" "}
    </BulkCapabilitiesContextProvider.Provider>
  );
};

export default BulkCapabilitiesContext;
