import React from 'react';
import './SkeletonElements.scss'

function SkeletonElements({type}) {
    const classes=`skeleton ${type}`

  return (
    <div
    className={classes}
    ></div>
  )
}

export default SkeletonElements