import React, { useState, useEffect } from "react";
import { useMemo } from "react";
//tanstack table imports
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
} from "@tanstack/react-table";
//image assets imports
import Sort_Up from "../../../../assets/images/Sort_Up.svg";
import Sort_Down from "../../../../assets/images/Sort_Down.svg";
import Sort from "../../../../assets/images/Sort.svg";
import ArrowDown from "../../../../assets/images/arrow_downward.svg";
//UI components import
import HandleOutsideClick from "../../HandleOutsideClick/HandleOutsideClick";
//style imports
import "./DataTable.scss";
import SkeletonElements from "../../../SkeletonComponents/SkeletonElements";
import { Placeholder } from "rsuite";

const DataTable = ({
  data,
  isLoading = false,
  columns,
  enablePagination = false,
  pageIndex = 1,
  setPageIndex = null,
  filteredOptions = {},
  maxPages = 1,
  totalMaxPages = 1,
  setFilter,
  optionList = {},
  refreshing,
}) => {
  const [sorting, setSorting] = useState([]);
  const table = useReactTable({
    data,
    columns: columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const renderPlaceholderGraph = (height) => (
    <>
      <Placeholder.Graph active style={{ marginTop: "0.1rem", height }} />
      <div style={{ padding: "0.2rem 0" }} />
    </>
  );

  return (
    <>
      <div className="tableWrapper" id="table-react">
        <div
          className={`hide ${refreshing && "show"}`}
          style={{
            position: "absolute",
            display: "flex",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            right: 0,
            background: "rgba(255,255,255,0.7)",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
          }}
        ></div>
        <div className={isLoading ? "tableContainerLoading" : "tableContainer"}>
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => {
                return (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const canSort = header.column.getCanSort();
                      //To show
                      const hasPopup = header.column.columnDef?.hasPopup;
                      //@Keshav To do to use a useRef for the header function
                      const headerFn = () => {
                        console.log(header.column);
                        if (!canSort) {
                          return;
                        }
                        header.column.columnDef?.headerFn(
                          header.column.columnDef?.accessorKey
                        );
                      };
                      const sortState = header.column.columnDef?.sortState;
                      const CustomDropdownItem =
                        header.column.columnDef?.customDropdownItem;
                      return (
                        <th
                          className={
                            canSort && !isLoading
                              ? "cursor-pointer"
                              : "cursor-pointer-disable"
                          }
                          // onClick={canSort && !hasPopup ? headerFn : () => {}}
                          onClick={
                            isLoading
                              ? () => { }
                              : canSort && !hasPopup
                                ? headerFn
                                : () => { }
                          }
                          key={header.id}
                        >
                          <div>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            {header.column.getCanSort() && !hasPopup && (
                              <div>
                                {!sortState ? (
                                  <img width={18} height={18} src={Sort} />
                                ) : sortState === "asc" ? (
                                  <img width={18} height={18} src={Sort_Up} />
                                ) : (
                                  <img
                                    width={18}
                                    height={18}
                                    src={Sort_Down}
                                  ></img>
                                )}
                              </div>
                            )}
                            {hasPopup && (
                              <>
                                <div className="angleDownIcon">
                                  <img width={18} height={18} src={ArrowDown} />
                                </div>
                                <div
                                  className={
                                    isLoading
                                      ? "popup-container popup-container-disable"
                                      : "popup-container"
                                  }
                                >
                                  <div
                                    className="sortBtn"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                    onClick={headerFn}
                                  >
                                    Sort{" "}
                                    {!sortState ? (
                                      <span className="sortArrowIcon">
                                        <img
                                          width={18}
                                          height={18}
                                          src={Sort}
                                        />
                                      </span>
                                    ) : sortState === "asc" ? (
                                      <span className="sortArrowIcon">
                                        <img
                                          width={18}
                                          height={18}
                                          src={Sort_Up}
                                        />
                                      </span>
                                    ) : (
                                      <span className="sortArrowIcon">
                                        <img
                                          width={18}
                                          height={18}
                                          src={Sort_Down}
                                        ></img>
                                      </span>
                                    )}
                                  </div>
                                  {optionList[
                                    header.column.columnDef?.accessorKey
                                  ] && (
                                      <>
                                        {optionList[
                                          header.column.columnDef?.accessorKey
                                        ].map((item) => {
                                          const isFiltered =
                                            filteredOptions[
                                            header.column.columnDef?.accessorKey
                                            ] === item;
                                          return (item !== "-")
                                            &&
                                            (
                                              <div
                                                key={header.id + item}
                                                className="statusSort"
                                              >
                                                <label
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                  }}
                                                  htmlFor={
                                                    header.id + "filter" + item
                                                  }
                                                >
                                                  <span>{item}</span>
                                                  <input
                                                    type="checkbox"
                                                    id={header.id + "filter" + item}
                                                    checked={isFiltered}
                                                    onChange={() => {
                                                      setFilter(
                                                        header.column.columnDef
                                                          ?.accessorKey,
                                                        isFiltered ? "" : item
                                                      );
                                                    }}
                                                  />
                                                </label>
                                              </div>
                                            );
                                        })}
                                      </>
                                    )}

                                  <div
                                    className="clearFilterBtn"
                                    onClick={() => {
                                      header.column.clearSorting();
                                      setFilter(
                                        header.column.columnDef?.accessorKey,
                                        ""
                                      );
                                    }}
                                  >
                                    {/* <span className="clearicon">X</span> */}
                                    Clear Filter
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            {data?.length > 0 && (
              <tbody className="tableBodyContainer">
                {table.getRowModel().rows.map((row) => (
                  <tr className="rowModelStyle text-center" key={row.id}>
                    {row &&
                      row.getVisibleCells().map((cell) => (
                        <td
                          style={{
                            cursor: cell.column?.columnDef?.clickFn
                              ? "pointer"
                              : "default",
                          }}
                          onClick={() => {
                            if (
                              cell.column &&
                              cell.column.columnDef &&
                              typeof cell.column.columnDef.clickFn ===
                              "function"
                            ) {
                              cell.column.columnDef.clickFn(
                                cell.getValue(),
                                cell.row.original
                              );
                            }
                          }}
                          key={cell.id}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {data?.length === 0 && (
          <div className="text-center noMatchingRecordsContainer">
            No Matching Records found
          </div>
        )}
        {data == null && (
          <div className="skeletonRecordsContainer">
            {Array.from({ length: 7 }, (_, index) =>
              renderPlaceholderGraph("3rem")
            )}
            {/* {renderPlaceholderGraph("8rem")} */}
          </div>
        )}

        {enablePagination && maxPages >= 1 && !isLoading && (
          <div className="flex pagination">
            {pageIndex > 1 && (
              <div
                className="previous-page-btn"
                onClick={() => setPageIndex(pageIndex - 1)}
              >
                <img src="/images/Back-active.svg" />
              </div>
            )}
            {pageIndex >= 4 && (
              <>
                <div onClick={() => setPageIndex(1)}>{1}</div>
                <span>...</span>
              </>
            )}

            {pageIndex > 1 && (
              <div onClick={() => setPageIndex(pageIndex - 1)}>
                {pageIndex - 1}
              </div>
            )}
            <div className="active" onClick={() => { }}>
              {pageIndex}
            </div>
            {pageIndex + 1 < maxPages && (
              <div onClick={() => setPageIndex(pageIndex + 1)}>
                {pageIndex + 1}
              </div>
            )}
            {maxPages >= 5 && pageIndex < maxPages && (
              <>
                <span>...</span>
              </>
            )}
            {pageIndex + 1 <= maxPages && (
              <div onClick={() => setPageIndex(maxPages)}>{maxPages}</div>
            )}

            {pageIndex < maxPages && (
              <div
                className="next-page-btn"
                onClick={() => {
                  setPageIndex(pageIndex + 1);
                }}
              >
                <img src="/images/Next-active.svg" />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DataTable;
