import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import ChangeLog from './change_log.md'
import './Changelog.scss';

const Changelog = () => {
    const [changeLogContents, setChangeLogContents] = useState("")

    useEffect(() => {
        fetch(ChangeLog)
            .then((response) => response.text())
            .then((text) => setChangeLogContents(text))
    }, []);

    useEffect(() => {
    }, [changeLogContents])

    return (
        <>
            <div className='change-log-contents'>
                <ReactMarkdown children={changeLogContents} />
            </div>
        </>
    )
}

export default Changelog;