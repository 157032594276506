let apiEndpointList = {
  FORGOT_PASSWORD: {
    endpoint: "/excalibur/password/reset_link",
    baseUrl: process.env.REACT_APP_BASE_ENDPOINT,
  },
  CREATE_VIRTUAL_ACCOUNT: {
    endpoint: "/v2/banking/account/virtual",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  MONEY_TRANSFER: {
    endpoint: "/core_banking/money_transfer/initiate",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  VA_SETTLEMENT: {
    endpoint: "/v2/banking/account/virtual/balance_settlement",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  FETCH_MASTER_CBA: {
    endpoint: "/decentro/read/fetch/master_cba_by_provider_code",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_VA_ACCOUNT: {
    endpoint: "/decentro/read/search/virtual_account_number",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_VA_ACCOUNT_COUNT: {
    endpoint: "/decentro/read/count/virtual_account_number",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_VA_ACCOUNT_TRANSACTIONS: {
    endpoint: "/decentro/read/search/transaction/accounts",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_VA_ACCOUNT_TRANSACTIONS_UPDATED: {
    endpoint: "/decentro/read/search/transaction/accounts/va",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_VA_ACCOUNT_TRANSACTIONS_COUNT: {
    endpoint: "/decentro/read/count/transaction/accounts",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_VA_ACCOUNT_TRANSACTIONS_UPDATED_COUNT: {
    endpoint: "/decentro/read/count/transaction/accounts/va",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  ACCOUNTS_VIEW_CREDITS: {
    endpoint: "/decentro/read/fetch/credits/accounts/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  ACCOUNTS_CREDITS_COUNT: {
    endpoint: "/decentro/read/count/credits/accounts/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PAYMENT_TRANSACTIONS: {
    endpoint: "/decentro/read/search/transaction/payments",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PAYMENT_TRANSACTIONS_COUNT: {
    endpoint: "/decentro/read/count/transaction/payments",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PAYMENT_LINKS: {
    endpoint: "/decentro/read/search/transaction/payment_links",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PAYMENT_LINKS_COUNT: {
    endpoint: "/decentro/read/count/transaction/payment_links",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PAYMENTS_PAYOUTS: {
    endpoint: "/decentro/read/search/transaction/payouts",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  ALLOW_PAYMENTS_PAYOUTS: {
    endpoint: "/decentro/read/company/frontend_payout",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PAYMENTS_PAYOUTS_COUNT: {
    endpoint: "/decentro/read/count/transaction/payouts",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  ALLOW_BULK_CAPABILITIES: {
    endpoint: "/decentro/read/company/bulk_frontend_payout",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  // TODO: On payments or banking
  FETCH_BULK_UPLOAD_TYPES: {
    endpoint: "/decentro/read/fetch/bulk_processing/types",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  BULK_FORM_SUBMISSION: {
    endpoint: "/decentro/bulk",
    baseUrl: process.env.REACT_APP_BULK_ENDPOINT,
  },
  MANAGE_IP: {
    endpoint: "/v2/request_ip_whitelist",
    baseUrl:
      process.env.REACT_APP_ENV_NAME === "production"
        ? process.env.REACT_APP_BASE_ENDPOINT
        : "https://in.staging.decentro.tech",
  },
  SUBMIT_MANAGE_IP: {
    endpoint: "/decentro/read/fetch/customer/ip/whitelisted",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_BULK_PARENT_DATA_PAYMENTS: {
    endpoint: "/decentro/read/search/bulk_processing/payments",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_BULK_CHILD_DATA_PAYMENTS: {
    endpoint: "/decentro/read/search/bulk_processing_line_item",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  BULK_PARENT_COUNT_PAYMENTS: {
    endpoint: "/decentro/read/count/bulk_processing/payments",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  BULK_CHILD_COUNT_PAYMENTS: {
    endpoint: "/decentro/read/count/bulk_processing_line_item",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  BULK_INPUT_FILE: {
    endpoint: "/decentro/bulk/download_input",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  BULK_OUTPUT_FILE: {
    endpoint: "/decentro/bulk/download_response",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  VALIDATE_BANK_ACCOUNT_COUNT: {
    endpoint: "/decentro/read/count/validate_bank_account/transactions",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  VALIDATE_BANK_ACCOUNT_TRANSACTIONS: {
    endpoint: "/decentro/read/search/validate_bank_account/transactions",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  VALIDATE_BANK_ACCOUNT: {
    endpoint: "/core_banking/money_transfer/validate_account",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  FETCH_PAYMENT_COLLECTION: {
    endpoint: "/decentro/read/search/transaction/collection_requests",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PAYMENT_COLLECTION_COUNT: {
    endpoint: "/decentro/read/count/transaction/collection_requests",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PAYMENTS_SETTLEMENTS: {
    endpoint: "/decentro/read/fetch/collections/settlement",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PAYMENTS_SETTLEMENTS_COUNT: {
    endpoint: "/decentro/read/count/collections/settlement",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_KYC_DETAILS: {
    endpoint: "/decentro/read/search/transaction/kyc",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_KYC_DETAILS_COUNT: {
    endpoint: "/decentro/read/count/transaction/kyc",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_CREDITS: {
    endpoint: "/decentro/read/fetch/user_credits",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_ISSUED_CARDS: {
    endpoint: "/decentro/read/search/issued_wallets",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_ISSUED_CARDS_COUNT: {
    endpoint: "/decentro/read/count/issued_wallets",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PPI_TRANSACTIONS: {
    endpoint: "/decentro/read/search/wallet_transactions",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PPI_TRANSACTIONS_COUNT: {
    endpoint: "/decentro/read/count/wallet_transactions",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PPI_WALLET_DETAILS: {
    endpoint: "/v2/prepaid/wallet/",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PPI_WALLET_POOL_BALANCE: {
    endpoint: "/v2/prepaid/pool/balance",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  FETCH_ACCOUNTS_TRANSACTIONS_COUNT: {
    endpoint: "/decentro/read/count/transaction/accounts",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_KYC_ANDHAAR_XML_DETAILS: {
    endpoint: "/decentro/read/search/transaction/kyc-aadhaar-xml",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_KYC_ANDHAAR_XML_COUNT: {
    endpoint: "/decentro/read/count/transaction/kyc-aadhaar-xml",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CKYC_SEARCH_AND_DOWNLOAD: {
    endpoint: "/decentro/read/search/transaction/ckyc",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CKYC_SEARCH_AND_DOWNLOAD_COUNT: {
    endpoint: "/decentro/read/count/transaction/ckyc",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_FACEMATCH: {
    endpoint: "/decentro/read/search/kyc/face-match",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_FACEMATCH_COUNT: {
    endpoint: "/decentro/read/count/kyc/face-match",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_TEXTMATCH: {
    endpoint: "/decentro/read/search/kyc/text-match",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_TEXTMATCH_COUNT: {
    endpoint: "/decentro/read/count/kyc/text-match",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_ID_CLASSIFICATION: {
    endpoint: "/decentro/read/search/kyc/id-classification",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_ID_CLASSIFICATION_COUNT: {
    endpoint: "/decentro/read/count/kyc/id-classification",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_IMAGE_QUALITY: {
    endpoint: "/decentro/read/search/kyc/image-quality",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_IMAGE_QUALITY_COUNT: {
    endpoint: "/decentro/read/count/kyc/image-quality",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_PHOTOCOPY_CHECK: {
    endpoint: "/decentro/read/search/kyc/photocopy-check",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_PHOTOCOPY_CHECK_COUNT: {
    endpoint: "/decentro/read/count/kyc/photocopy-check",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_LIVELINESS_CHECK: {
    endpoint: "/decentro/read/search/kyc/video-liveliness",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_LIVELINESS_CHECK_COUNT: {
    endpoint: "/decentro/read/count/kyc/video-liveliness",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_AADHAR_UID_MASKING: {
    endpoint: "/decentro/read/search/kyc/aadhaar-uid-masking",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_AADHAR_UID_MASKING_COUNT: {
    endpoint: "/decentro/read/count/kyc/aadhaar-uid-masking",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_DIGILOCKER: {
    endpoint: "/decentro/read/search/kyc/digilocker",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_DIGILOCKER_COUNT: {
    endpoint: "/decentro/read/count/kyc/digilocker",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FINANCIAL_SERVICES_CREDIT_REPORT: {
    endpoint: "/decentro/read/search/transaction/credit_reports",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FINANCIAL_SERVICES_CREDIT_REPORT_COUNT: {
    endpoint: "/decentro/read/count/transaction/credit_reports",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  ACCOUNTS_VA_BALANCES: {
    endpoint: "/decentro/read/fetch/commission_account_balance",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  // * DOWNLOAD REPORTS
  DOWNLOAD_REPORTS: {
    endpoint: "/decentro/read/reports/history",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  // * ACCOUNTS REPORTS CAS
  ACCOUNTS_REPORTS: {
    endpoint: "/v2/banking/account/consolidated_account_statement",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  // * ACCOUNTS REGISTERED REPORTS
  ACCOUNTS_REGISTERED_EMAIL_REPORTS: {
    endpoint: "/decentro/read/fetch/email_by_company_id",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  // * ACCOUNTS REPORTS PROVIDER
  ACCOUNTS_REPORTS_PROVIDER: {
    endpoint: "/decentro/read/fetch/provider/dropdown/reports",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },

  // * ACCOUNTS REPORTS TYPE PROVIDER
  ACCOUNTS_REPORTS_TYPE_PROVIDER: {
    endpoint: "/decentro/read/fetch/report_types",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },

  // * ACCOUNTS REPORTS MODULES
  ACCOUNTS_REPORTS_MODULES_: {
    endpoint: "/decentro/read/fetch/module_details_by_company",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  // * CREDIT CONSUMPTION REPORTS
  CREDIT_CONSUMPTION_REPORTS: {
    endpoint: "",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  // * BENEFICIARIES REPORTS
  REPORTS: {
    endpoint: "",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  // * PAYMENTS REPORTS
  PAYMENTS_REPORTS: {
    endpoint: "",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  MASTER_ALIAS_PROVIDER_ACCOUNTS: {
    endpoint: "/decentro/read/fetch/provider/dropdown/master_account_alias",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  RECURRING_COLLECTION_EMANDATE: {
    endpoint: "/decentro/read/search/emandate/registration",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  RECURRING_COLLECTION_EMANDATE_COUNT: {
    endpoint: "/decentro/read/count/emandate/registration",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  VIEW_CALLBACK: {
    endpoint: "/decentro/read/fetch/callback/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  VIEW_CALLBACK_COUNT: {
    endpoint: "/decentro/read/count/callback/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  PAYMENT_LINKS_TXN_STATUS: {
    endpoint: "/v2/payments/transaction/",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  PAYOUTS_STATUS: {
    endpoint: "/core_banking/money_transfer/get_status",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  PROVIDER_ACCOUNTS: {
    endpoint: "/decentro/read/fetch/provider/dropdown/accounts",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  VALIDATE_PROVIDER_ACCOUNTS: {
    endpoint: "/decentro/read/fetch/provider/dropdown/decentro_api",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  PROVIDER_COLLECTION: {
    endpoint: "/decentro/read/fetch/provider/dropdown/collections",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  PROVIDER_PAYMENTS: {
    endpoint: "/decentro/read/fetch/provider/payments",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CALLBACK_SUBSCRIPTION: {
    endpoint: "/decentro/read/search/callback/subscriptions",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  ALL_CALLBACKS: {
    endpoint: "/v2/company/callback",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CALLBACK_HTTP_METHOD_TYPE: {
    endpoint: "/decentro/read/fetch/http_method_type",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CALLBACK_TYPE: {
    endpoint: "/decentro/read/fetch/callback_type",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_ONBOARDING_GENERATE_LINK: {
    endpoint: "/kyc_onboarding/generate_link",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  USER_LOGOUT: {
    endpoint: "/user/logout",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  EMAIL_COMPANY_CREDENTIALS: {
    endpoint: "/v2/internal/email_company_credentials",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  PREPAID_CONSUMER: {
    endpoint: "/v2/prepaid/consumer",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  PREPAID_WALLET: {
    endpoint: "/v2/prepaid/wallet",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  DASHBOARD_API_SUMMARY: {
    endpoint: "/dashboard/api_summary",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  BASE_VIRTUAL_ACCOUNT: {
    endpoint: "/v2/banking/account/virtual/",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  INTERNAL_CALLBACK: {
    endpoint: "/v2/internal/company/callback",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  USER_REGISTRATION: {
    endpoint: "/user/register",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  FETCH_BENEFICIARY_COUNT: {
    endpoint: "/decentro/read/count/manage/beneficiary",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_BENEFICIARY: {
    endpoint: "/decentro/read/fetch/manage/beneficiary",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  MANAGE_BENEFICIARY_API: {
    endpoint: "/v2/banking/beneficiary",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  KYC_CREDITS: {
    endpoint: "/decentro/read/fetch/credits/kyc/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  KYC_CREDITS_COUNT: {
    endpoint: "/decentro/read/count/credits/kyc/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  PAYMENTS_CREDITS: {
    endpoint: "/decentro/read/fetch/credits/payments/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  PAYMENTS_CREDITS_COUNT: {
    endpoint: "/decentro/read/count/credits/payments/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  PPI_CREDITS: {
    endpoint: "/decentro/read/fetch/credits/ppi/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  PPI_CREDITS_COUNT: {
    endpoint: "/decentro/read/count/credits/ppi/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FINCANCIAL_CREDITS: {
    endpoint: "/decentro/read/fetch/credits/fs/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FINCANCIAL_CREDITS_COUNT: {
    endpoint: "/decentro/read/count/credits/fs/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FINANCIAL_DATA_PULL: {
    endpoint: "/decentro/read/fetch/fs/customer-data-pull",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FINANCIAL_DATA_PULL_COUNT: {
    endpoint: "/decentro/read/count/fs/customer-data-pull",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  EMANDATE: {
    endpoint: "/v2/payments/enach/mandate",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  EMANDATE_BANK_CODE: {
    endpoint: "/v2/payments/enach/bank_support?bank_code=",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  UPI_BENEFICIARY_LIST: {
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
    endpointUpi: "/decentro/read/fetch/dropdown/beneficiary-upi",
    endpointNonUpi: "/decentro/read/fetch/dropdown/beneficiary-non-upi",
  },
  TRANSFER_ACCOUNT_LIST: {
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
    endpoint: "/decentro/read/fetch/dropdown/va-number",
  },
  TRANSFER_TYPES_PAYOUTS: {
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
    endpoint: "/decentro/read/fetch/provider/payment_methods",
  },
  MFA_TOGGLE_DETAIL: {
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    endpoint: "/user/details",
  },
  MFA_PREFERENCE: {
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    endpoint: "/user/mfa_preference",
  },
  CKYC_FLAG: {
    endpoint: "/decentro/read/company/frontend_ckyc_allowed",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  EMAIL_LOG: {
    endpoint: "/decentro/read/search/company_contact",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_ALL_CREDIT_HISTORY: {
    endpoint: "/decentro/read/fetch/credits/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_ALL_CREDIT_HISTORY_COUNT: {
    endpoint: "/decentro/read/count/credits/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  GET_MODULES_BY_COMPANY: {
    endpoint: "/decentro/read/fetch/module_details_by_company",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
};

export default apiEndpointList;
