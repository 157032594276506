import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
// sentry tracing imports
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// styles imports
import "./index.scss";

// Below sentry code is temporary commented for performance purposes

// sentry tracing
// if(process.env.REACT_APP_READ_API_ENDPOINT === "https://in.decentro.tech"){
//   Sentry.init({
//     dsn: "https://a865c0a52c0b427ba9d73485ddfb0ed8@sentry.decentro.tech/4",
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 0.5,
//     environment:"production"
//   });
// }else{
//   Sentry.init({
//     dsn: "https://770525f0c50d47fd8304d0ec9a8bcd07@staging.sentry.decentro.tech/5",
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 0.5,
//     environment:"staging"
//   });
// }
// process.env.REACT_APP_READ_API_ENDPOINT === "https://in.decentro.tech" &&
//   Sentry.init({
//     dsn: "https://770525f0c50d47fd8304d0ec9a8bcd07@staging.sentry.decentro.tech/5",
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 0.5,
//   });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
