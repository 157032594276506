import React from "react";
//To create a user componet
//styles
import "./UpiPaymentLink.scss";
import UpiPaymentLinkForm from "../UpiPaymentLinkForm/UpiPaymentLinkForm";
import PaymentsLinkContext from "../../PaymentsLinkContext";
// Components
import FormDivider from "../../../UI/Divider/FormDivider";

function UpiPaymentLink({ goBackToTable }) {
  return (
    <>
      <div className="add-user-page">
        <div className="add-user-content">
          <div className="add-user-card">
            {/* {tableEditRow && (
              <div className="back-btn" onClick={()=>goBackToTable()}>
                <img src="/images/back.svg" alt="back-icon" />
                <h1 className="heading">
                  <span className="company-name-highlight">
                    Modify Beneficiary
                  </span>
                </h1>
              </div>
            )} */}

            <div className="back-btn">
              <img src="/images/back.svg" alt="back-icon" onClick={() => goBackToTable()} />
              <p className="heading">Generate Payment Link</p>
            </div>

            <FormDivider />
            <PaymentsLinkContext>
              <UpiPaymentLinkForm />
            </PaymentsLinkContext>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpiPaymentLink;
