import React, { useState } from "react";
import ReactDOM, { unmountComponentAtNode } from "react-dom";

import "./SkeletonEmptyDialogue.scss";

function SkeletonEmptyDialogue({ type, refreshTable }) {
  function refreshTheTable() {
    refreshTable();
  }
  return (
    <div className="main_dialogue">
      <div className="error-wrapper">
        <img src="/images/errormessage.svg" alt="error message" />
        <div className="error-text-wrapper">
          {type?.response?.status != undefined ? (
            <>
              <h3>Error : {type?.response?.status}</h3>
              {type?.response?.status === 404 && (
                <p>
                  <strong>Not found ,That should have not happened.</strong>
                  <br />
                  Please Trying Refreshing the page or login with right details
                  again
                </p>
              )}
              {type?.response?.status === 401 && (
                <p>
                  <strong>
                    Unauthorized , Seems like your session has ended.
                  </strong>
                  <br />
                  Please refresh the page or login again
                </p>
              )}
              {type?.response?.status === 500 && (
                <p>
                  <strong>Oops!, Something went wrong.</strong>
                  <br />
                  Please Give us some time or contact our support at
                  support@decentro.tech
                </p>
              )}
              {type?.response?.status === 504 && (
                <p>
                  <strong>Oops!, Something went wrong.</strong>
                  <br />
                  Please Give us some time or contact our support at
                  support@decentro.tech
                </p>
              )}
              {type?.response?.status === 502 && (
                <p>
                  <strong>Oops!, Something went wrong.</strong>
                  <br />
                  Please Give us some time or contact our support at
                  support@decentro.tech
                </p>
              )}
            </>
          ) : (
            <>
              <h3>Error : 000</h3>
              <p>
                <strong>Oops !, Something went wrong.</strong>
                <br />
                Please, Check your Internet Connection !
              </p>
            </>
          )}
          {/* <button className="refresh-btn" onClick={()=>refreshTheTable()}>refresh</button> */}
        </div>
      </div>
    </div>
  );
}

export default SkeletonEmptyDialogue;
