import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import "./UpiPaymentSuccessDialog.scss";
import SnackbarMessage from "../Shared/SnackbarMessage/SnackbarMessage";
import { PaymentsLinkContextProvider } from "../PaymentsLinkDataTable/PaymentsLinkContext";

let successDetails;
function UpiPaymentSuccessDialog(props) {
  // Refresh table was showing undefined here at all situations so I commented it and after clicking close popup, will be reloading the window using location to update the table
  // const { refreshTable } = useContext(PaymentsLinkContextProvider);
  const closeSuccessDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("payments-components")
    );
    window.location.reload();
    // refreshTable();
  };

  const copyToClipBoard = async (copyLink) => {
    try {
      await navigator.clipboard.writeText(copyLink);
      ReactDOM.render(
        <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
        document.getElementById("snackbar")
      );
    } catch (err) { }
  };

  successDetails = props.successResponse;
  return (
    <React.Fragment>
      <Dialog
        className="upi-payment-success"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-success-popup">
          <CloseIcon onClick={closeSuccessDialog} />
        </div>

        <DialogContent>
          <div className="dialog-heading">
            <p>Payment Link Generated</p>
          </div>
          <div className="dialog-content-container">
            <div className="success-message">
              <img src="/images/onsuccess-tick.svg" alt="success-icon" />
              <h3>Transaction initiated successfully!</h3>
              <p>{successDetails?.message}</p>
              {successDetails?.data?.encodedDynamicQrCode && <img className="payment-qr-code" src={"data:image/jpeg;base64," + successDetails.data.encodedDynamicQrCode} />}
            </div>
            <div className="success-account-details">
              <table>
                <tbody>
                  <tr>
                    <td className="accounts-heading">Transaction Status</td>
                    <td className="accounts-details">
                      {successDetails?.data?.transactionStatus}
                    </td>
                  </tr>
                  <tr>
                    <td className="accounts-heading">Transaction Id</td>
                    <td className="accounts-details">
                      {successDetails?.data?.transactionId}
                      <img
                        onClick={() =>
                          copyToClipBoard(successDetails?.data?.transactionId)
                        }
                        className="copy-link-icon"
                        src="/images/copy-icon.svg"
                        alt="copy-icon"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="accounts-heading">Generated Link</td>
                    <td className="accounts-details">
                      {successDetails?.data?.generatedLink}
                      <img
                        onClick={() =>
                          copyToClipBoard(successDetails?.data?.generatedLink)
                        }
                        className="copy-link-icon"
                        src="/images/copy-icon.svg"
                        alt="copy-icon"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="success-btn-container">
              <button onClick={closeSuccessDialog} className="success-btn-done">
                Done
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default UpiPaymentSuccessDialog;
