import React from "react";
import Tabs from "../Tabs/Tabs";
import "./TabPanel.scss";
import TabsStaging from "../Tabs/TabsStaging";

const TabPanel = () => {
  return (
    <div className="menu-container-dev">
      {process.env.REACT_APP_ENV_NAME === "production" ? (
        <Tabs />
      ) : (
        <TabsStaging />
      )}
    </div>
  );
};

export default TabPanel;
