let apiEndpointListPayments = {
    "UPI_PAYMENT_LINK": {
        "endpoint": "/v2/payments/upi/link",
        "baseUrl": process.env.REACT_APP_API_ENDPOINT
    },
    "UPI_COLLECTION_REQUEST": {
        "endpoint": "/v2/payments/collection",
        "baseUrl": process.env.REACT_APP_API_ENDPOINT
    }
}

export default apiEndpointListPayments;