import React from "react";
// router imports
import { Route } from "react-router-dom";
import Header from "../../components/Header/Header";
// dashboard layout components
import StagingDashboard from "../StagingDashboard/StagingDashboard";
import ProductionDashboard from "../ProductionDashboard/ProductionDashboard";
import QaDashboard from "../QaDashboard/QaDashboard";
// styles import
import "./Dashboard.scss";

const Dashboard = () => {
  return (
    <div className="dashboard">
      <Header />
      <Route path="/dashboard">
        {/* seperation of dashboard layouts  */}
        {process.env.REACT_APP_ENV_NAME === "production" ? (
          <ProductionDashboard />
        ) : (
          ""
        )}
        {process.env.REACT_APP_ENV_NAME === "staging" ? (
          <StagingDashboard />
        ) : (
          ""
        )}
        {process.env.REACT_APP_ENV_NAME === "qa" ? <QaDashboard /> : ""}

        {/* Old Logic */}
        {/* {process.env.REACT_APP_ENV_NAME === "production" ||
        process.env.REACT_APP_ENV_NAME === "qa" ? (
          <ProductionDashboard />
        ) : (
          <StagingDashboard />
        )} */}
      </Route>
    </div>
  );
};

export default Dashboard;
