import React from "react";
// Components
import ValidateAccountForm from "./ValidateAccountForm";
import FormDivider from "../../UI/Divider/FormDivider";


function ValidateAccount({ goBackToTable }) {


    return (
        <>
            <div className="add-user-page">
                <div className="add-user-content">
                    <div className="add-user-card">
                        <div className="back-btn">
                            <img src="/images/back.svg" alt="back-icon" onClick={() => goBackToTable()} />
                            <p className="heading">Validate Bank Account</p>
                        </div>

                        <FormDivider />
                        <ValidateAccountForm />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ValidateAccount;
