import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import Chart from 'react-apexcharts';
import moment from 'moment';

import './APIHitsGraph.scss';


const APIHitsGraph = (props) => {
    let dataTotal = []
    let dataSuccessful = []
    let dates = []
    let data = Array.from(props.data)
    for (let i = 0; i < data.length; i++) {
        dates.push(data[i].date)
        dataTotal.push(data[i].total)
        dataSuccessful.push(data[i].successful)
    }
    let chartConfig = {
        series: [{
            name: 'Total',
            data: dataTotal
        },
        {
            name: 'Successful',
            data: dataSuccessful
        }],
        options: {
            chart: {
                type: 'line',
                curve: 'smooth',
                stacked: false,
                height: 250,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: false
                },
                toolbar: {
                    autoSelected: 'zoom',
                    export: {
                        csv: {
                            filename: props.module === 'kyc-and-onboarding' ? "Validation_Trend_Data" : "Virtual_Accounts_Transactions_Data",
                            columnDelimiter: ',',
                            headerCategory: 'Date',
                            headerValue: 'Data',
                            dateFormatter(timestamp) {
                                return new moment(timestamp).format('YYYY-MM-DD')
                            }
                        }
                    }
                }
            },
            grid: {
                borderColor: '#EAF0F4',
                strokeDashArray: 1,
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                xaxis: {
                    lines: {
                        show: true
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 5,
            },
            yaxis: {
                min: 0,
                labels: {
                    style: {
                        colors: '#838383',
                        fontSize: '12px',
                        fontWeight: 400
                    },
                    offsetX: -5,
                    offsetY: 2,
                    formatter: function (value) {
                        return Math.round(value)
                    },
                },
            },
            xaxis: {
                type: 'datetime',
                categories: dates,
                labels: {
                    style: {
                        colors: '#838383',
                        fontSize: '12px',
                        fontWeight: 400
                    },
                    offsetY: 2,
                    rotate: 0,
                    rotateAlways: false,
                    formatter: function (val, timestamp) {
                        return moment(new Date(timestamp)).format("DD MMM")
                    },
                },
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 2,
                dashArray: 0,
            },
            fill: {
                colors: ['#ffffff'],
                opacity: 0
            },
            legend: {
                horizontalAlign: "center",
                position: "top"
            },
            tooltip: {
                shared: true,
            },
        }
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // event.target.checked = !event.target.checked;
    }
    return (
        <React.Fragment>
            <div className="api-hits-graph-menu">
                <div className="api-hits-graph-dropdown">
                    <div className="api-hits-graph-dropdown-content">
                        <form noValidate>
                            <ul>
                                <li>
                                    <Checkbox
                                        onChange={handleChange}
                                        color="default"
                                    /> Total
                                </li>
                                <li>
                                    <Checkbox
                                        onChange={handleChange}
                                        color="default"
                                    /> Successful
                                </li>
                            </ul>
                            <button className="apply-button">Apply Changes</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="api-hits-graph-block">
                {props.data.length > 0 ?
                    <Chart options={chartConfig.options} series={chartConfig.series} type="area" height={250} />
                    :
                    ""
                }
            </div>
        </React.Fragment>
    );
}

APIHitsGraph.propTypes = {
    data: PropTypes.any
};

APIHitsGraph.defaultProps = {
    data: {}
};

export default APIHitsGraph;
