import React from "react";

//component-import
import ToolTip from "../ToolTip/ToolTip";

//styles-import
import "./TextField.scss"

const TextFieldInput = ({ id, name, onChange, onBlur, value, touched, error, placeholder, label, required, disabled, maxLength, isToolTip, tooltipLink }) => (
  <div className={`ui-form-input-section ui-form-content-input`}>
    <input
      id={id}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      className={`ui-form-input-box ${touched && error ? "input-error" : ""
        }`}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      maxLength={maxLength}
    />
    <label className="ui-label" htmlFor={id}>
      {label}{" "}
      <ToolTip text={isToolTip} requiredField={required} isToolTip={isToolTip} link={tooltipLink} isLink={tooltipLink && true}>
        {(isToolTip && <div className="info-icon">
          <img
            src="/images/info-icon.svg"
            alt=""
          />
        </div>)}
      </ToolTip>
    </label>
    {touched && error ? (
      <img
        src="/images/invalid-input.svg"
        alt="Invalid Input"
        className="ui-invalid-field-icon"
      />
    ) : null}
  </div>
);

export default TextFieldInput;
