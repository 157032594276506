import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import KeyboardArrowDownSharp from "@material-ui/icons/KeyboardArrowDownSharp";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, TextField } from "@material-ui/core";
import { APIConfig } from "../../services/apiConfiguration";
import axios from "axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import apiEndpointList from "../../config/core_banking/endpoint";

import "./CreateVirtualAccountDetails.scss";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

function CreateVirtualAccountDetails(props) {
  const [verifyKyc, setVerifyKyc] = React.useState({
    kycVerifyToggle: +true,
    decentroKycToggle: +true,
  });

  const [selectedOption, setSelectedOption] = React.useState();
  const animatedComponents = makeAnimated();
  const [error, setError] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);
  let addressRegex = /^[^*|":<>[\]{}`\\()';@&$]+$/;
  const mobileRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

  const [providerApiData, setProviderApiData] = React.useState("");

  const cvaProvider = () => {
    APIConfig.API_Client.post(
      apiEndpointList.PROVIDER_ACCOUNTS.baseUrl +
        apiEndpointList.PROVIDER_ACCOUNTS.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setProviderApiData(response.data);
      })
      .catch((error) => {});
  };

  React.useEffect(() => {
    cvaProvider();
  }, []);

  const kycToggle = (e) => {
    const { name, checked } = e.target;
    setVerifyKyc({
      ...verifyKyc,
      [name]: +checked,
    });
  };

  const closeAccountDetailsDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("virtual-accounts-components")
    );
  };

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0092ff",
      },
    },
  });

  const classes = useStyles();

  const colourStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#A3A3A3",
        fontWeight: 450,
        fontFamily: "Montserrat",
        fontSize: "14px",
        letterSpacing: "-0.5px",
      };
    },
  };

  const [customerDetails, setCustomerDetails] = React.useState({
    nameInput: "",
    panInput: "",
    emailInput: "",
    mobileInput: "",
    addressInput: "",
    customerIdInput: "",
    minimumBalanceInput: "",
    transactionLimitInput: "",
  });

  let [inputData, setInputData] = React.useState({
    customerName: "",
    customerPan: "",
    customerEmail: "",
    mobileNumber: "",
    customerAddress: "",
    customerId: "",
    minimumBalance: "",
    transactionLimit: "",
  });

  const [inputValidation, setInputValidation] = React.useState({
    nameError: "",
    panError: "",
    emailError: "",
    mobileNoError: "",
    addressError: "",
    customerIdError: "",
    minimumBalanceError: "",
    transactionLimitError: "",
  });

  const [inputValidationBtn, setInputValidationBtn] = React.useState({
    nameError: "",
    panError: "",
    emailError: "",
    mobileNoError: "",
    addressError: "",
    customerIdError: "",
    minimumBalanceError: "",
    transactionLimitError: "",
  });

  const ref = useRef();
  const handleBlur = (event) => {
    let { name, value } = event.target;

    if (name == "customerEmail") {
      // console.log(event.target.validity.patternMismatch);
      if (event.target.validity.patternMismatch) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          emailError: "Invalid format",
        });
      }
    }
    if (name == "mobileNumber") {
      if (!value.match(mobileRegex)) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          mobileNoError: "Invalid format",
        });
      }
    }
    if (name == "customerPan") {
      // console.log("pan",event.target.validity.patternMismatch);
      if (event.target.validity.patternMismatch) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({ ...inputValidation, panError: "Invalid format" });
      }
    }
    if (name === "transactionLimit") {
      // console.log("limit",event.target.validity.patternMismatch);
      if (event.target.value >= "1") {
        if (event.target.validity.patternMismatch) {
          setInputData({ ...inputData, [name]: "" });
          setInputValidation({
            ...inputValidation,
            transactionLimitError: "Invalid format",
          });
        }
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          transactionLimitError: "Transaction Limit must be at least 1",
        });
      }
    }
    if (name === "minimumBalance") {
      // console.log("mini",event.target.validity.patternMismatch);
      if (event.target.value >= "1") {
        if (event.target.validity.patternMismatch) {
          setInputData({ ...inputData, [name]: "" });
          setInputValidation({
            ...inputValidation,
            minimumBalanceError: "Invalid format",
          });
        }
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          minimumBalanceError: "Minimum Balance must be at least 1",
        });
      }
    }
    if (name === "customerId") {
      // console.log("id",event.target.validity.patternMismatch);
      if (event.target.validity.patternMismatch) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          customerIdError: "Invalid format",
        });
      }
    }
    if (name === "customerName") {
      // console.log("id",event.target.validity.patternMismatch);
      if (event.target.validity.patternMismatch) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({ ...inputValidation, nameError: "Invalid format" });
      }
    }
    if (name === "customerAddress") {
      if (!value.match(addressRegex)) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          addressError: "Invalid format",
        });
      }
    }
  };

  const handleFocus = () => {
    if (error) {
      setShowErrorText(true);
    }
  };
  const handleContactDetails = (e) => {
    let { name, value } = e.target;

    if (name === "customerName") {
      setCustomerDetails({ ...customerDetails, nameInput: value });
      let nameRegex = /^[a-zA-Z ]+$/;

      if (value.match(nameRegex)) {
        setInputData({ ...inputData, [name]: value.trim() });
        setInputValidation({ ...inputValidation, nameError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidationBtn({
          ...inputValidationBtn,
          nameError: "Invalid format",
        });
      }
    }

    if (name === "customerPan") {
      setCustomerDetails({ ...customerDetails, panInput: value });
      let panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

      if (value.match(panRegex)) {
        setInputData({ ...inputData, [name]: value.toUpperCase() });
        setInputValidation({ ...inputValidation, panError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidationBtn({
          ...inputValidationBtn,
          panError: "Invalid format",
        });
      }
    }

    if (name === "customerEmail") {
      setCustomerDetails({ ...customerDetails, emailInput: value });
      let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (value.match(emailRegex)) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, emailError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidationBtn({
          ...inputValidationBtn,
          emailError: "Invalid format",
        });
      }
    }

    if (name === "mobileNumber") {
      setCustomerDetails({ ...customerDetails, mobileInput: value });

      if (value.match(mobileRegex)) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, mobileNoError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidationBtn({
          ...inputValidationBtn,
          mobileNoError: "Invalid format",
        });
      }
    }

    if (name === "customerAddress") {
      setCustomerDetails({ ...customerDetails, addressInput: value });

      if (value.match(addressRegex)) {
        setInputData({ ...inputData, [name]: value.trim() });
        setInputValidation({ ...inputValidation, addressError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidationBtn({
          ...inputValidationBtn,
          addressError: "Invalid format",
        });
      }
    }

    if (name === "customerId") {
      setCustomerDetails({ ...customerDetails, customerIdInput: value });
      setInputData({ ...inputData, [name]: value.trim() });
      let customerIdRegex = /^[a-zA-Z0-9]*$/;
      setInputData({ ...inputData, [name]: value.trim() });
      if (value.match(customerIdRegex)) {
        // setInputData({ ...inputData, [name]: value.trim() });
        setInputValidation({ ...inputValidation, customerIdError: "" });
      } else {
        // setInputData({ ...inputData, [name]: "" });
        setInputValidationBtn({
          ...inputValidationBtn,
          customerIdError: "Invalid format",
        });
      }
    }
    if (name === "minimumBalance") {
      setCustomerDetails({ ...customerDetails, minimumBalanceInput: value });
      let minBalRegex = /^[0-9]+$/;
      if (value >= "1") {
        if (value.match(minBalRegex)) {
          setInputData({ ...inputData, [name]: value });
          setInputValidation({ ...inputValidation, minimumBalanceError: "" });
        } else {
          setInputData({ ...inputData, [name]: "" });
          setInputValidationBtn({
            ...inputValidationBtn,
            minimumBalanceError: "Invalid format",
          });
        }
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidationBtn({
          ...inputValidationBtn,
          minimumBalanceError: "Minimum Balance must be at least 1",
        });
      }
    }
    if (name === "transactionLimit") {
      setCustomerDetails({ ...customerDetails, transactionLimitInput: value });
      let limitBalRegex = /^[0-9]+$/;
      if (value >= "1") {
        if (value.match(limitBalRegex)) {
          setInputData({ ...inputData, [name]: value });
          setInputValidation({ ...inputValidation, transactionLimitError: "" });
        } else {
          setInputData({ ...inputData, [name]: "" });
          setInputValidationBtn({
            ...inputValidationBtn,
            transactionLimitError: "Invalid format",
          });
        }
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidationBtn({
          ...inputValidationBtn,
          transactionLimitError: "Transaction Limit must be at least 1",
        });
      }
    }
  };

  const customerData = () => {
    let data = {
      ...verifyKyc,
      ...inputData,
      bankProvider: [selectedOption],
      setRefresh: true,
    };
    props.getPayloadData(data);
  };

  return (
    <React.Fragment>
      <Dialog
        className="virtual-accounts"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-popup">
          <CloseIcon onClick={closeAccountDetailsDialog} />
        </div>

        <DialogContent>
          <div className="virtual-accounts-heading">
            <p>Create Virtual Accounts</p>
          </div>
          <p className="virtual-accounts-subtitle">
            Here are some quick details you need to fill.
          </p>
          <span className="virtual-accounts-subtitle-all">
            All fields are mandatory
          </span>

          <div className="virtual-accounts-container">
            <div className="field-input">
              <label htmlFor="address-input">
                <span className="label">Bank Provider</span>
              </label>
              <div className="bank-option-menu">
                <VirtualAccountsTaskMenu
                  setSelectedProvider={setSelectedOption}
                  providerApiData={providerApiData}
                />
              </div>
            </div>

            <div className="field-input">
              <label htmlFor="customer-name-label">
                <span className="label">Customer Name</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.nameError) && classes.root
                }`}
                id="customer-name-label"
                name="customerName"
                variant="outlined"
                placeholder="Enter customer’s name"
                value={customerDetails.nameInput}
                error={Boolean(inputValidation.nameError)}
                autoComplete="off"
                inputProps={{
                  pattern: "^[a-zA-Z ]+$",
                }}
                spellCheck="false"
                onBlur={handleBlur}
                onChange={handleContactDetails}
                style={{ width: 315 }}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.nameError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid-icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="customer-email-label">
                <span className="label">Email</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.emailError) && classes.root
                }`}
                id="customer-email-label"
                name="customerEmail"
                variant="outlined"
                placeholder="Enter customer’s email"
                value={customerDetails.emailInput}
                error={Boolean(inputValidation.emailError)}
                autoComplete="off"
                spellCheck="false"
                ref={ref}
                inputProps={{
                  pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
                }}
                onBlur={handleBlur}
                onChange={handleContactDetails}
                style={{ width: 315 }}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.emailError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid-icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="panNo-label">
                <span className="label">PAN</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.panError) && classes.root
                }`}
                id="panNo-label"
                name="customerPan"
                variant="outlined"
                placeholder="Enter customer's PAN"
                value={customerDetails.panInput}
                error={Boolean(inputValidation.panError)}
                autoComplete="off"
                spellCheck="false"
                inputProps={{
                  maxLength: 10,
                  pattern: "^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$",
                }}
                onBlur={handleBlur}
                onChange={handleContactDetails}
                style={{ width: 315 }}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.panError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid-icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="mobile-number">
                <span className="label">Mobile Number</span>
              </label>
              <TextField
                className={`${!Boolean(
                  inputValidation.mobileNoError && classes.root
                )}`}
                id="mobile-number"
                name="mobileNumber"
                variant="outlined"
                placeholder="Enter customer’s mobile number"
                value={customerDetails.mobileInput}
                error={Boolean(inputValidation.mobileNoError)}
                autoComplete="off"
                inputProps={{
                  maxLength: 10,
                  pattern: mobileRegex,
                }}
                onChange={handleContactDetails}
                onBlur={handleBlur}
                ref={ref}
                style={{ width: 315 }}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.mobileNoError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid-icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="address-label">
                <span className="label">Address</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.addressError) && classes.root
                }`}
                id="address-label"
                name="customerAddress"
                variant="outlined"
                placeholder="Enter customer’s address"
                value={customerDetails.addressInput}
                error={Boolean(inputValidation.addressError)}
                autoComplete="off"
                spellCheck="false"
                onBlur={handleBlur}
                inputProps={{
                  pattern: addressRegex,
                }}
                onChange={handleContactDetails}
                style={{ width: 315 }}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.addressError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid-icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="customer-id">
                <span className="label">Customer ID</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.customerIdError) && classes.root
                }`}
                id="customer-id"
                name="customerId"
                variant="outlined"
                placeholder="Enter customer's ID"
                value={customerDetails.customerIdInput}
                error={Boolean(inputValidation.customerIdError)}
                autoComplete="off"
                spellCheck="false"
                inputProps={{
                  maxLength: 32,
                  pattern: "^[a-zA-Z0-9]*$",
                }}
                onBlur={handleBlur}
                onChange={handleContactDetails}
                style={{ width: 315 }}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.customerIdError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid-icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="minimum-balance">
                <span className="label">Minimum Balance</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.minimumBalanceError) && classes.root
                }`}
                id="minimum-balance"
                name="minimumBalance"
                variant="outlined"
                placeholder="Set minimum balance"
                value={customerDetails.minimumBalanceInput}
                error={Boolean(inputValidation.minimumBalanceError)}
                autoComplete="off"
                inputProps={{ maxLength: 7, pattern: "^[0-9]+$" }}
                onChange={handleContactDetails}
                onBlur={handleBlur}
                style={{ width: 315 }}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.minimumBalanceError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid-icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="transaction-limit">
                <span className="label">Transaction Limit</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.transactionLimitError) &&
                  classes.root
                }`}
                id="transaction-limit"
                name="transactionLimit"
                variant="outlined"
                placeholder="Set transaction limit"
                value={customerDetails.transactionLimitInput}
                error={Boolean(inputValidation.transactionLimitError)}
                autoComplete="off"
                inputProps={{
                  maxLength: 7,
                  pattern: "^[0-9]+$",
                }}
                onChange={handleContactDetails}
                onBlur={handleBlur}
                style={{ width: 315 }}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.transactionLimitError)
                    ? "show"
                    : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid-icon"
              />
            </div>

            <div className="kyc-radio-input">
              <div className="kyc-toggle-container">
                <span>KYC Verified</span>
                <div className="toggle-section">
                  <span
                    className={
                      !verifyKyc.kycVerifyToggle ? "text-toggle" : undefined
                    }
                  >
                    No
                  </span>
                  <input
                    id="switch-one"
                    className="checkbox"
                    name="kycVerifyToggle"
                    value={verifyKyc.kycVerifyToggle}
                    checked={verifyKyc.kycVerifyToggle}
                    onChange={kycToggle}
                    type="checkbox"
                  />
                  <label htmlFor="switch-one" className="toggle"></label>
                  <span
                    className={
                      verifyKyc.kycVerifyToggle ? "text-toggle" : undefined
                    }
                  >
                    Yes
                  </span>
                </div>
              </div>

              <div className="kyc-toggle-divider"></div>

              <div className="kyc-toggle-container">
                <span>Decentro KYC</span>
                <div className="toggle-section">
                  <span
                    className={
                      !verifyKyc.decentroKycToggle ? "text-toggle" : undefined
                    }
                  >
                    No
                  </span>
                  <input
                    id="switch-two"
                    className="checkbox"
                    name="decentroKycToggle"
                    value={verifyKyc.decentroKycToggle}
                    checked={verifyKyc.decentroKycToggle}
                    onChange={kycToggle}
                    type="checkbox"
                  />
                  <label htmlFor="switch-two" className="toggle"></label>
                  <span
                    className={
                      verifyKyc.decentroKycToggle ? "text-toggle" : undefined
                    }
                  >
                    Yes
                  </span>
                </div>
              </div>
            </div>

            <div className="submit-btn-container">
              <button
                disabled={
                  !inputData.customerName ||
                  !inputData.customerEmail ||
                  !inputData.customerPan ||
                  !inputData.mobileNumber ||
                  !inputData.customerId ||
                  !inputData.customerAddress ||
                  !inputData.minimumBalance ||
                  !inputData.transactionLimit ||
                  selectedOption.length === 0
                }
                className={`submit-account-details 
              ${
                !inputData.customerName ||
                !inputData.customerEmail ||
                !inputData.customerPan ||
                !inputData.mobileNumber ||
                !inputData.customerId ||
                !inputData.customerAddress ||
                !inputData.minimumBalance ||
                !inputData.transactionLimit ||
                selectedOption.length === 0
                  ? ""
                  : "active-submit-btn"
              }`}
                onClick={customerData}
              >
                Submit
                <span id="virtual-account-loader"></span>
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function VirtualAccountsTaskMenu(props) {
  const [TaskMenuItem, setTaskMenuItem] = React.useState("Select Bank");

  return (
    <div className="provider-dropdown">
      <span className="provider-show" onClick={() => ToggleActionMenu()}>
        {TaskMenuItem === "Select Bank" ? (
          <span className="placeholder-text">{TaskMenuItem}</span>
        ) : (
          <span className="provider-text">{TaskMenuItem}</span>
        )}
        <KeyboardArrowDownSharp />
      </span>
      <div className="provider-bank-option">
        <div className="provider-options">
          {props.providerApiData ? (
            props.providerApiData
              .filter((data) => data.bank_name !== TaskMenuItem)
              .map((data, index) => (
                <div key={index}>
                  <div
                    onClick={() => {
                      document
                        .querySelector(".provider-bank-option")
                        .classList.remove("active");
                      document
                        .querySelector(".provider-show")
                        .classList.remove("show-border");
                      setTaskMenuItem(data.bank_name);
                      props.setSelectedProvider(data.bank_code);
                    }}
                    className="option"
                  >
                    {data.bank_name}
                  </div>
                </div>
              ))
          ) : (
            <span style={{ padding: "10px 0", paddingLeft: "10px" }}>
              Loading...
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

function ToggleActionMenu() {
  document.querySelector(".provider-bank-option").classList.toggle("active");
  document.querySelector(".provider-show").classList.toggle("show-border");
}

export default CreateVirtualAccountDetails;
