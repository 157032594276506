// React Imports
import React, { useState, useEffect, useRef, useMemo } from "react";

// styles import
import "./DropDownSelectModules.scss";

function DropDownSelectModules({
    options = [],
    setSelectedOption = (e) => e,
    setSelectedModuleId,
    selectedOption,
    itemId,
    placeholder = "Select Option",
    searchable = false,
    style = {},
    className = "",
    showArrow,
    disabledField = false,
}) {
    function ToggleActionMenu() {
        document.querySelector(`.${itemId}`).classList.toggle("module-active-dropdown");
        document.querySelector(`.${itemId}-show`).classList.toggle("module-show-border");
        if (
            searchValue !== localSelectedOption &&
            localSelectedOption !== placeholder
        ) {
            setSearchValue(localSelectedOption);
        }
    }
    const [searchValue, setSearchValue] = useState("");
    const [localSelectedOption, setLocalSelectedOption] = React.useState(
        selectedOption ?? placeholder
    );
    const [MenuActive, setMenuActive] = React.useState(false);

    const optionsToUse = useMemo(() => {
        if (options.length < 1) {
            return "";
        }
        if (searchable) {
            return options
                .filter((item) =>
                    item.name?.toLowerCase().includes(searchValue?.toLowerCase())
                )
                .filter(
                    (item) =>
                        item.name !== searchValue ||
                        (item.name === searchValue &&
                            item.name !== localSelectedOption)
                );
        }

        return options.filter(
            (data) => data?.name !== localSelectedOption
        );
    }, [options, searchValue]);

    const itemRef = useRef(null);
    function handleOutsideClick(e) {
        if (e.target !== itemRef.current && !itemRef.current?.contains(e.target)) {
            document.querySelector(`.${itemId}`).classList.remove("module-active-dropdown");
            document.querySelector(`.${itemId}-show`).classList.remove("module-show-border");
            // * down arrow flip state
            setMenuActive(false);
            if (
                searchValue !== localSelectedOption &&
                localSelectedOption !== placeholder
            ) {
                setSearchValue(localSelectedOption);
            }
        }
    }

    function handleFilter(e) {
        setSearchValue(e.target.code);
        // ToggleActionMenu();
        if (
            document.querySelector(`.${itemId}`).classList.contains("module-active-dropdown")
        ) {
            return;
        }
        document.querySelector(`.${itemId}`).classList.add("module-active-dropdown");
        document.querySelector(`.${itemId}-show`).classList.add("module-show-border");
    }

    useEffect(() => {
        document.removeEventListener("click", handleOutsideClick);
        document.addEventListener("click", handleOutsideClick);
        return () => document.removeEventListener("click", handleOutsideClick);
    });

    useEffect(() => {
        document.removeEventListener("click", handleOutsideClick);
        document.addEventListener("click", handleOutsideClick);

        // Reset the default value when options are updated
        setLocalSelectedOption(placeholder);
        setSearchValue("");

        setSelectedOption(null);
        setSelectedModuleId(null);
    }, [options]);


    return (
        <div
            style={style}
            ref={itemRef}
            className={`module-dropdowns ${disabledField && "module-disable-dropdown"
                } ${className} ${MenuActive && `max-z-index`}`}
        >
            <span
                className={`${itemId}-show module-show`}
                onClick={() => {
                    setMenuActive(!MenuActive);
                    ToggleActionMenu();
                }}
            >
                {searchable ? (
                    <input
                        className="dropdown-input"
                        onChange={handleFilter}
                        style={{ width: "100%" }}
                        value={searchValue}
                        placeholder={placeholder}
                    />
                ) : localSelectedOption === placeholder ? (
                    <span className="placeholder-text">{localSelectedOption}</span>
                ) : (
                    <span className="module-text-er">{localSelectedOption}</span>
                )}
                {showArrow !== false ? (
                    <img
                        style={{
                            transform: MenuActive ? "rotate(180deg)" : "rotate(0deg)",
                        }}
                        src="/images/arrow-down.svg"
                        alt="dropdwon-arrow"
                    />
                ) : (
                    ""
                )}
            </span>
            <div className={`${itemId} module-bank-option`}>
                <div className="module-options">
                    {optionsToUse ? (
                        optionsToUse
                            .filter((data) => data?.name !== selectedOption)
                            .map((data, index) => (
                                <div key={index}>
                                    <div
                                        onClick={() => {
                                            setMenuActive(!MenuActive);
                                            setLocalSelectedOption(data?.name);
                                            setSelectedOption(data?.code);
                                            ToggleActionMenu();
                                            setSelectedModuleId(data?.id);
                                            if (searchable) {
                                                setSearchValue(data?.name);
                                            }
                                        }}
                                        className="module-each-option"
                                    >
                                        {data?.name}
                                    </div>
                                </div>
                            ))
                    ) : (
                        <span style={{ padding: "10px 0", paddingLeft: "10px" }}>
                            Loading...
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DropDownSelectModules;
