import React from "react";

import PropTypes from "prop-types";

import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import subMonths from "date-fns/subMonths";
import subYears from "date-fns/subYears";
import moment from "moment";

import "rsuite/dist/styles/rsuite-default.css";
import "./DateSelector.scss";

const { afterToday, before, combine } = DateRangePicker;
const Locale = {
  sunday: "Sun",
  monday: "Mon",
  tuesday: "Tue",
  wednesday: "Wed",
  thursday: "Thu",
  friday: "Fri",
  saturday: "Sat",
  ok: "Apply",
  formattedMonthPattern: "MMM YYYY",
};
const Ranges = [
  {
    label: "7 Days",
    value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
  },
  {
    label: "1 Month",
    value: [
      startOfDay(subMonths(new Date(), 1)),
      // endOfDay(subDays(endOfDay(new Date()), 1)),
      endOfDay(subDays(endOfDay(new Date()), 0)),
    ],
  },
  {
    label: "3 Months",
    value: [
      startOfDay(subMonths(new Date(), 3)),
      // endOfDay(subDays(endOfDay(new Date()), 1)),
      endOfDay(subDays(endOfDay(new Date()), 0)),
    ],
  },
];
let initialStartDate = new Date();
initialStartDate.setDate(initialStartDate.getDate() - 14);

function DateSelector(props) {
  let [selectedDateRangeValue, setSelectedDateRangeValue] = React.useState(
    props.selectedDateRange
  );
  var _date = moment();
  var _date2 = moment().date(0);

  if (props.restrictedDateFlag) {
    return (
      <DateRangePicker
        format="DD MMM 'YY"
        cleanable={false}
        value={selectedDateRangeValue}
        ranges={Ranges}
        appearance="default"
        defaultCalendarValue={[new Date(_date2), new Date(_date)]}
        locale={Locale}
        // disabledDate={combine(before(subMonths(new Date(), 6)), afterToday())}
        disabledDate={(date) =>
          date < new Date(2020, 3, 1) || date > new Date()
        }
        onChange={(item) => {
          setSelectedDateRangeValue(item);
          props.setSelectedDateRange(item);
        }}
      />
    );
  }

  return (
    <DateRangePicker
      format="DD MMM 'YY"
      cleanable={false}
      value={selectedDateRangeValue}
      ranges={Ranges}
      appearance="default"
      defaultCalendarValue={[new Date(_date2), new Date(_date)]}
      locale={Locale}
      disabledDate={combine(before(subMonths(new Date(), 6)), afterToday())}
      onChange={(item) => {
        setSelectedDateRangeValue(item);
        props.setSelectedDateRange(item);
      }}
    />
  );
}

DateSelector.propTypes = {
  selectedDateRange: PropTypes.any,
  setSelectedDateRange: PropTypes.any,
};

DateSelector.defaultProps = {
  selectedDateRange: [initialStartDate, new Date()],
  setSelectedDateRange: {},
};

export default DateSelector;
