import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Components
import StatusCell from "../../utilities/StatusCell/StatusCell";
import TimeCell from "../../utilities/TimeCell";
import BalanceCell from "../../utilities/BalanceCell";
import ReactDataTable from "../Common/ReactDataTable";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import SnackbarMessage from "../Shared/SnackbarMessage/SnackbarMessage";
// Utils
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/core_banking/endpoint";
import posthog from "posthog-js";
import ShowBlueValue from "../VirtualAccountDataTable/ShowBlueValue";
import { capturePosthogEvent } from "../../utilities/posthogUtils";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils";
// Contexts
import { VirtualAccountContextProvider } from "./VirtualAccountContext";
import CreateVirtualAccount from "./CreateVirtualAccountModal/CreateVirtualAccount/CreateVirtualAccount";
import ShowAccountModal from "./ShowAccountModal";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const VirtualAccountReactDatatable = () => {
  const {
    isError,
    errorResponse,
    tableData,
    totalCount,
    filterList,
    fetchData,
    firstApiCall,
    searchParameter,
    setSearchParameter,
    currentView,
    setCurrentView,
    childPayload,
    setChildPayload,
    childTableData,
    setChildTableData,
    setChildFilterList,
    childFilterList,
    fetchChildData,
    childTotalCount,
    accountNumber,
  } = useContext(VirtualAccountContextProvider);

  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "All Accounts View",
            {
              additionalInfo: "User viewed All Accounts page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);

  useEffect(() => {
    if (currentView === "child") {
      if (
        childTableData.length >= childTotalCount &&
        childTableData.length !== 0
      )
        return;
      const prefetchLimit = 200;
      const endIndex =
        childFilterList.currentPage * childFilterList.resultsPerPage;
      const limitConstant =
        Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
        2 * childFilterList?.resultsPerPage;
      if (childTotalCount > 0) {
        if (childTableData.length < limitConstant && firstApiCall === false) {
          fetchChildData(
            childTableData.length + 1,
            undefined,
            undefined,
            undefined,
            false,
            accountNumber
          );
        }
      } else if (
        childTotalCount === 0 &&
        childTableData.length === 0 &&
        firstApiCall === true
      ) {
        fetchChildData(
          childTableData.length + 1,
          undefined,
          undefined,
          undefined,
          false,
          accountNumber
        );
      }
    }
  }, [childFilterList.currentPage]);

  // based on currentView the Data Table is being displayed
  const USER_MANAGEMENT_CARD =
    currentView === "parent" ? <Parent /> : <Child />;
  return <>{USER_MANAGEMENT_CARD}</>;
};

// Initial Parent component that is being rendered.
const Parent = () => {
  const {
    isError,
    errorResponse,
    currentView,
    setCurrentView,
    setChildPayload,
    childPayload,
    tableData,
    fetchData,
    setfirstApiCall,
    fetchChildData,
    setFilterList,
    setSearchParameter,
    setCurrentSearchScope,
    showModalDialog,
    setShowModalDialog,
    refreshTable,
    accountNumber,
    setAccountNumber,
    setTableData,
    setIsLoading,
    setChildFilterList,
  } = useContext(VirtualAccountContextProvider);

  const changeView = (prop) => {
    setCurrentView("child");
    setFilterList({
      search: "",
      resultsPerPage: 10,
      currentPage: 1,
    });
    setChildFilterList({
      search: "",
      resultsPerPage: 10,
      currentPage: 1,
    });
    setCurrentSearchScope("");
    setSearchParameter({
      bank_ref_number: false,
      decentro_urn: false,
      customer_ref_number: false,
    });
    setAccountNumber(prop);
    setChildPayload((prev) => ({
      ...prev,
      account_number: prop,
    }));
    setTableData([]);
    setIsLoading(true);
    fetchChildData(
      tableData.length + 1,
      undefined,
      undefined,
      undefined,
      false,
      prop
    );
  };

  const transactionColumnList = [
    {
      name: "Consumer Id",
      accessor: "customer_id",
      cell: ShowAccountModal,
      showInitial: true,
    },
    {
      name: "Name",
      accessor: "account_name",
      showInitial: true,
    },
    {
      name: "Account Number",
      accessor: "account_number",
      showInitial: true,
      clickFn: changeView,
      cell: ShowBlueValue,
    },
    {
      name: "Created On",
      accessor: "created_on",
      showInitial: true,
      cell: TimeCell,
    },
    {
      name: "Current Balance",
      accessor: "current_balance",
      showInitial: true,
      cell: BalanceCell,
    },
    // {
    //   name: "Email",
    //   accessor: "email_address",
    //   showInitial: true,
    //   cell: (row) => (row?.getValue() === null ? "-" : row?.getValue()),
    // },
    // {
    //   name: "IFSC",
    //   accessor: "ifsc_code",
    //   showInitial: true,
    // },
    // {
    //   name: "Mobile No.",
    //   accessor: "mobile_number",
    //   showInitial: true,
    //   cell: (row) => (row?.getValue() === null ? "-" : row?.getValue()),
    // },
  ];

  const VirtualAccountsCTA = ({ openLink }) => {
    const { isLoading } = useContext(VirtualAccountContextProvider);
    return (
      <>
        <div className="payout-action-container">
          <button
            className={isLoading ? "btn-loading" : `payouts-action-btn`}
            style={{ backgroundColor: "#0092ff" }}
            onClick={() => {
              if (!isLoading) {
                if (
                  process.env.REACT_APP_ENV_NAME === "staging" ||
                  process.env.REACT_APP_ENV_NAME === "production"
                ) {
                  if (window?.location?.origin && window?.location?.pathname) {
                    try {
                      capturePosthogEventCustom(
                        window.location.origin,
                        window.location.pathname,
                        "Create Virtual Account CTA Click",
                        {
                          additionalInfo:
                            "User clicked Create Virtual Accounts button",
                        }
                      );
                    } catch (error) {
                      console.error("Error capturing Posthog event:", error);
                    }
                  } else {
                    console.warn(
                      "Unable to capture event: window.location properties are not fully defined"
                    );
                  }
                }
                openLink();
              }
            }}
          >
            Create Virtual Accounts
          </button>
        </div>
      </>
    );
  };

  function openLink() {
    setShowModalDialog(true);
  }

  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"All Accounts"}
          provider={VirtualAccountContextProvider}
          modalComponent={<VirtualAccountsCTA openLink={openLink} />}
          ModalDialog={<CreateVirtualAccount goBackToTable={refreshTable} />}
          isModalPresent={true}
          columnList={transactionColumnList}
          showDownloadBtn={false}
          showSearchParameter={{
            bank_ref_number: false,
            decentro_urn: false,
            customer_ref_number: false,
            account_number: true,
            decentro_transaction_id: false,
          }}
          showDateFilter={false}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

const Child = () => {
  const {
    isError,
    errorResponse,
    currentView,
    setCurrentView,
    setChildPayload,
    childPayload,
    accountNumber,
    refreshTable,
    fetchData,
    setFilterList,
    setCurrentSearchScope,
    setSearchParameter,
    tableData,
    setTableData,
    setChildTableData,
    setChildTotalCount,
    setIsLoading,
    setChildFilterList,
    childFilterList,
  } = useContext(VirtualAccountContextProvider);

  const getTxnStatus = (row, code) => {
    APIConfig.API_Client.defaults.headers.get["provider_code"] = code;

    const cell = document.getElementById(
      `status-cell-${row.original.decentro_urn}`
    );
    if (!cell) return;

    APIConfig.API_Client.get(
      apiEndpointList.PAYOUTS_STATUS.baseUrl +
        apiEndpointList.PAYOUTS_STATUS.endpoint +
        `?decentro_txn_id=${row.original.decentro_urn}`,

      { cancelToken: source.token }
    )
      .then((response) => {
        if (response.data.status === "success") {
          const successElement = <div className="success">SUCCESS</div>;
          cell.innerHTML = "";
          ReactDOM.render(successElement, cell);
          ReactDOM.render(
            <SnackbarMessage
              msgtype="success"
              msg={"Status updated to Success"}
            />,
            document.getElementById("snackbar")
          );
          return;
        } else if (response.data.status === "failure") {
          const failureElement = <div className="failure">FAILURE</div>;
          cell.innerHTML = "";
          ReactDOM.render(failureElement, cell);
          ReactDOM.render(
            <SnackbarMessage
              msgtype="error"
              msg={"Status updated to Failure"}
            />,
            document.getElementById("snackbar")
          );
          return;
        }
        ReactDOM.render(
          <SnackbarMessage msgtype="warning" msg={"Status not updated"} />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        console.log(error);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={`${error?.response?.data?.message} | Status: ${error?.response?.data?.status}`}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  const childTransactionColumnList = [
    {
      accessor: "decentro_transaction_id",
      name: "Decentro URN",
      showInitial: true,
    },
    {
      accessor: "bank_reference_number",
      name: "Bank Ref",
      showInitial: true,
    },
    {
      accessor: "timestamp",
      name: "Date-Time",
      cell: TimeCell,
      showInitial: true,
    },
    {
      accessor: "status",
      name: "status",
      hasPopup: true,
      showInitial: true,
    },
    {
      accessor: "provider",
      name: "provider",
      hasPopup: true,
      showInitial: true,
    },
    {
      accessor: "transaction_type",
      name: "txn type",
      hasPopup: true,
      showInitial: true,
    },
    {
      accessor: "remitter_consumer_bank_account_number",
      name: "Payee Account Number",
      showInitial: true,
    },
    {
      accessor: "remitter_ifsc",
      name: "Payee IFSC",
      showInitial: true,
    },
    {
      accessor: "beneficiary_consumer_bank_account_number",
      name: "Payer Account Number",
      showInitial: true,
    },
    {
      accessor: "beneficiary_ifsc",
      name: "Payer IFSC",
      showInitial: true,
    },
    {
      accessor: "remitter_vpa",
      name: "Payee VPA",
      showInitial: true,
    },
    {
      accessor: "beneficiary_vpa",
      name: "Payer VPA",
      showInitial: true,
    },
    { accessor: "credit_or_debit", name: "Credit/Debit", showInitial: true },
    { accessor: "amount", name: "amount", showInitial: true },
  ];

  // Adding styles here for title to not mutate common react table component.
  const Title = `
    <div>
    Transaction Summary for <span class='userURN' style="color:#0092ff !important; font-size: 17px !important; font-weight: 600 !important">${accountNumber}</span>
    </div>
    `;

  const takeBack = () => {
    setCurrentSearchScope("");
    setSearchParameter({
      bank_ref_number: false,
      decentro_urn: false,
      customer_ref_number: false,
    });
    setCurrentView("parent");
    const resetFilters = {
      search: "",
      resultsPerPage: 10,
      currentPage: 1,
    };
    setFilterList(resetFilters);
    setChildFilterList(resetFilters);
    setChildTableData([]);
    setChildTotalCount(0);
    fetchData(1, resetFilters);
    setIsLoading(true);
  };

  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={Title}
          isModalPresent={false}
          showDownloadBtn={false}
          columnList={childTransactionColumnList}
          provider={VirtualAccountContextProvider}
          backFunction={takeBack}
          // showDateFilter={false}
          // showSearchParameter={{
          //     bank_ref_number: false,
          //     decentro_urn: false,
          //     customer_ref_number: false,
          //     account_number: false,
          //     decentro_transaction_id: true
          // }}
          showAdvancedSearch={false}
          dualTable={true}
          child={true}
          showDateFilter={false}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default VirtualAccountReactDatatable;
