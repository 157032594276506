import React from 'react'
import './StickyFooter.scss'

const StickyFooter = () => (
    <div className="stickyFooter">
        <a href="https://decentro.tech/decentro-terms/" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
        <a href="https://decentro.tech/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        <a href="mailto:help@decentro.tech" target="_blank" rel="noopener noreferrer">Help & Support</a>
    </div>
);

export default StickyFooter;
