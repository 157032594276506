import React from "react";
import ReactDOM from "react-dom";
// Components
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";
import SnackbarMessage from "../../Shared/SnackbarMessage/SnackbarMessage";
// Utils
import "./ManageIPSuccessDialog.scss";

function ManageIPSuccessDialog(props) {
  const closeSuccessDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("payments-components")
    );
    window.location.reload();
  };

  const copyToClipBoard = async (copyLink) => {
    try {
      await navigator.clipboard.writeText(copyLink);
      ReactDOM.render(
        <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
        document.getElementById("snackbar")
      );
    } catch (err) { }
  };

  return (
    <React.Fragment>
      <Dialog
        className="manage-ip-success"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-success-popup">
          <CloseIcon onClick={closeSuccessDialog} />
        </div>

        <DialogContent>
          <div className="dialog-heading">
            <p>IP Added Successfully</p>
          </div>
          <div className="dialog-content-container">
            <div className="success-message">
              <img src="/images/onsuccess-tick.svg" alt="success-icon" />
              <h3>{props.successResponse.message}</h3>
            </div>
            <div className="success-account-details">
              <table>
                <tbody>
                  {props?.successResponse?.status &&
                    <tr>
                      <td className="accounts-heading">Status</td>
                      <td className="accounts-details title-case">
                        {props?.successResponse?.status || "-"}
                      </td>
                    </tr>
                  }

                  {/* Execution ID */}
                  {props?.successResponse?.execution_id && <tr>
                    <td className="accounts-heading">Execution ID</td>
                    <td className="accounts-details">
                      {props.successResponse.execution_id}
                      <img
                        onClick={() =>
                          copyToClipBoard(
                            props?.successResponse?.execution_id || "-"
                          )
                        }
                        className="copy-link-icon"
                        src="/images/copy-icon.svg"
                        title="Copy text"
                        alt="copy-icon"
                      />
                    </td>
                  </tr>}
                </tbody>
              </table>
            </div>
            <div className="success-btn-container">
              <button onClick={closeSuccessDialog} className="success-btn-done">
                Done
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default ManageIPSuccessDialog;
