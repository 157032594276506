import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Divider, TextField } from "@material-ui/core";
import KeyboardArrowDownSharp from "@material-ui/icons/KeyboardArrowDownSharp";
import DropdownSelect from "../RecurringCollection/Emandate/EmandateDataTable/components/SelectComponent/DropdownSelect";
import bankProviderList from "../../config/providers/bank/provider";
import track, { useTracking } from "react-tracking";
import { APIConfig } from "../../services/apiConfiguration";
import axios from "axios";
import apiEndpointList from "../../config/core_banking/endpoint";
import "./MoneyTransferBeneficiaryDetails.scss";

// temp options till api works

const upiOptions = [
  {
    beneficiary_name: "UPI 1",
    beneficiary_upi_id: "UPI 1",
    payee_email: "nonUpi1@upi2.co",
    payee_mobile: "91283912",
  },
];
const nonUpiOptions = [
  {
    beneficiary_name: "Non UPI 1",
    beneficiary_account: "Non UPI 1",
    ifsc: "HDFC0000542",
    payee_email: "nonUpi1@upi.co",
    payee_mobile: "91283912",
  },
  {
    beneficiary_name: "Non UPI 2",
    beneficiary_account: "Non UPI 2",
    ifsc: "HDFC0000542",
    payee_email: "nonUpi1@upi2.co",
    payee_mobile: "91283912",
  },
];
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

function MoneyTransferBeneficiaryDetails(props) {
  const { trackEvent } = useTracking();
  let username;
  let useremail;
  const closeAccountDetailsDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("virtual-accounts-components")
    );
  };

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0092ff",
      },
    },
  });

  const [beneficiaryOptions, setBeneficiaryOptions] = useState([]);
  const beneficiaryOptionsDropdownFormatted = useMemo(() => {
    if (props.customerInputData.transfer_type === "UPI") {
      return beneficiaryOptions.map((item) => ({
        label: item.beneficiary_name,
        value: item,
      }));
    } else {
      return beneficiaryOptions.map((item) => ({
        label: item.beneficiary_name,
        value: item,
      }));
    }
  }, [beneficiaryOptions]);
  const classes = useStyles();
  const fetchSelectOptions = () => {
    if (props.customerInputData.transfer_type === "UPI") {
      APIConfig.API_Client.post(
        apiEndpointList.UPI_BENEFICIARY_LIST.baseUrl +
          apiEndpointList.UPI_BENEFICIARY_LIST.endpointUpi
      ).then((res) => {
        setBeneficiaryOptions(res.data);
      });
    } else {
      APIConfig.API_Client.post(
        apiEndpointList.UPI_BENEFICIARY_LIST.baseUrl +
          apiEndpointList.UPI_BENEFICIARY_LIST.endpointNonUpi
      ).then((res) => {
        setBeneficiaryOptions(res.data);
      });
    }
  };
  useEffect(() => {
    let temp = JSON.parse(localStorage.getItem("user"));
    username = temp.name;
    useremail = temp.email;
    fetchSelectOptions();
  }, []);

  const [provider, setProvider] = React.useState("");

  const [providerApiData, setProviderApiData] = React.useState([]);

  const moneyTransferProvider = () => {
    APIConfig.API_Client.post(
      apiEndpointList.PROVIDER_ACCOUNTS.baseUrl +
        apiEndpointList.PROVIDER_ACCOUNTS.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setProviderApiData(response.data);
      })
      .catch((error) => {});
  };

  React.useEffect(() => {
    moneyTransferProvider();
  }, []);

  const [customerDetails, setCustomerDetails] = React.useState({
    emailInput: "",
    mobileInput: "",
    addressInput: "",
    ifscInput: "",
    payeeNameInput: "",
  });

  let [inputData, setInputData] = React.useState({
    email_address: "",
    mobile_number: "",
    address: "",
    country_code: "IN",
    payee_name: "",
    to_account: "",
  });

  const [inputValidation, setInputValidation] = React.useState({
    emailError: "",
    mobileNoError: "",
    addressError: "",
    ifscCodeError: "",
    payeeNameError: "",
    to_account: "",
  });
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);

  const buttonValidations = !((Object.keys(inputData).length ===
    props.customerInputData.transfer_type) ===
  "UPI"
    ? 6
    : 7 && !Object.values(inputData).includes("") && provider !== "");
  const handleBlur = (e) => {
    let { name, value } = e.target;

    if (name === "email_address") {
      let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!value.match(emailRegex)) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          emailError: "Invalid format",
        });
      }
    }

    if (name === "mobile_number") {
      let mobileRegex = /^[6-9]\d{9}$/;
      if (!value.match(mobileRegex)) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          mobileNoError: "Invalid format",
        });
      }
    }

    if (name === "ifsc_code") {
      let ifscRegex = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
      if (!value.match(ifscRegex)) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          ifscCodeError: "Invalid format",
        });
      }
    }

    if (name === "address") {
      // let addressRegex = /^[^*|":<>[\]{}`\\()';@&$]+$/;
      let addressRegex = /^[^*|":<>[\]{}`\\()';@&$?^!]+$/;
      if (!value.match(addressRegex)) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          addressError: "Invalid format",
        });
      }
    }

    if (name === "payee_name") {
      // let payeeNameRegex = /^[^*|":<>[\]{}`\\()';@&$]+$/;
      let payeeNameRegex = /^[A-Za-z ]+$/;
      if (!value.match(payeeNameRegex)) {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          payeeNameError: "Invalid format",
        });
      }
    }
  };

  const handleContactDetails = (e, isDropdown) => {
    if (isDropdown) {
      setInputValidation((old) => {
        let newObj = {};
        Object.keys(old).forEach((key) => {
          newObj[key] = "";
        });
        return newObj;
      });
      return;
    }
    let { name, value } = e.target;
    if (name === "to_input") {
      let regexTo = /^[A-Z0-9]*$/;
      if (props.customerInputData.transfer_type === "UPI") {
        regexTo = /^[\w.-]+@[\w.-]+$/;
      }
      setCustomerDetails({ ...customerDetails, to_account: value });

      if (value.match(regexTo)) {
        setInputData({ ...inputData, to_account: value });
        setInputValidation((old) => ({ ...old, to_account: "" }));
      } else {
        setInputData({ ...inputData, to_account: "" });
        setInputValidation((old) => ({
          ...old,
          to_account: "Please enter a valid value",
        }));
      }
    }

    if (name === "email_address") {
      setCustomerDetails({ ...customerDetails, emailInput: value });
      let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (value.match(emailRegex)) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, emailError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
      }
    }

    if (name === "mobile_number") {
      setCustomerDetails({ ...customerDetails, mobileInput: value });
      let mobileRegex = /^[6-9]\d{9}$/;

      if (value.match(mobileRegex)) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, mobileNoError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
      }
    }

    if (name === "ifsc_code") {
      setCustomerDetails({ ...customerDetails, ifscInput: value });
      let ifscRegex = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;

      if (value.match(ifscRegex)) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, ifscCodeError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
      }
    }

    if (name === "address") {
      setCustomerDetails({ ...customerDetails, addressInput: value });
      let addressRegex = /^[^*|":<>[\]{}`\\()';@&$?^!]+$/;

      if (value.match(addressRegex)) {
        setInputData({ ...inputData, [name]: value.trim() });
        setInputValidation({ ...inputValidation, addressError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
      }
    }

    if (name === "payee_name") {
      setCustomerDetails({ ...customerDetails, payeeNameInput: value });
      // let payeeNameRegex = /^[^*|":<>[\]{}`\\()';@&$]+$/;
      let payeeNameRegex = /^[A-Za-z ]+$/;

      if (value.match(payeeNameRegex)) {
        setInputData({ ...inputData, [name]: value.trim() });
        setInputValidation({ ...inputValidation, payeeNameError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
      }
    }
  };

  const customerBeneficiaryDetails = (e) => {
    trackEvent({
      action: "Submit button Clicked",
      username: username,
      useremail: useremail,
      Money_transfer_intiated_at: new Date(),
      entity: "SubmitBtn",
    });
    let payload = { ...inputData };
    if (selectedBeneficiary !== null) {
      payload = {
        ...inputData,
        isDropdownSelected: true,
        beneficiary_code: selectedBeneficiary.beneficiary_code,
      };
    }

    props.beneficiaryInputDetail(payload, provider);
  };

  return (
    <React.Fragment>
      <Dialog
        className="beneficiary-details"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-popup">
          <CloseIcon onClick={closeAccountDetailsDialog} />
        </div>

        <DialogContent>
          <div className="beneficiary-details-heading">
            <p>Money transfer</p>
          </div>
          <p className="beneficiary-details-subtitle">
            Fill beneficiary details .{" "}
            <p className="beneficiary-details-subtitle-all">
              All Fields are Mandatory
            </p>
          </p>

          <div className="beneficiary-details-container">
            <div className="field-input">
              <label>
                <span className="label">Select Beneficiary</span>
              </label>
              <DropdownSelect
                className="w-beneficiary-input"
                placeholder="Enter Beneficiary Name"
                searchable
                itemId="beneficiary-search-dropdown"
                options={beneficiaryOptionsDropdownFormatted}
                setSelectedOption={(e) => {
                  setSelectedBeneficiary(e);
                  handleContactDetails(null, true);
                  setCustomerDetails((old) => {
                    let newVal = { ...old };
                    const val = e;
                    newVal.emailInput = val.payee_email;
                    newVal.addressInput = "";
                    newVal.mobileInput = val.payee_mobile_number;
                    newVal.payeeNameInput = val.beneficiary_name;
                    newVal.to_account = val.beneficiary_account_number;
                    if (props.customerInputData.transfer_type !== "UPI") {
                      newVal.ifscInput = val.ifsc;
                    }
                    return newVal;
                  });
                  setInputData({
                    ...inputData,
                    mobile_number: e.payee_mobile_number,
                    address: "No Address",
                    email_address: e.payee_email,
                    country_code: "IN",
                    payee_name: e.beneficiary_name,
                    to_account: e.beneficiary_account_number,
                    ...(props.customerInputData.transfer_type !== "UPI" && {
                      ifsc_code: e.ifsc,
                    }),
                  });
                }}
              />
            </div>
            <div className="bordered-or">OR</div>
            <div className="field-input">
              <label htmlFor="address-label">
                <span className="label">
                  To{" "}
                  {props.customerInputData.transfer_type === "UPI"
                    ? "UPI"
                    : "Account"}
                </span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.addressError) && classes.root
                } w-beneficiary-input`}
                id="to_input"
                name="to_input"
                variant="outlined"
                placeholder="Enter Recepient"
                value={customerDetails.to_account}
                error={Boolean(inputValidation.to_account)}
                autoComplete="off"
                spellCheck="false"
                onBlur={handleBlur}
                onChange={handleContactDetails}
                disabled={selectedBeneficiary !== null}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.to_account) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid input icon"
              />
            </div>
            {props.customerInputData.transfer_type !== "UPI" && (
              <div className="field-input">
                <label htmlFor="ifsc-code">
                  <span className="label">IFSC</span>
                </label>
                <TextField
                  className={`${
                    !Boolean(inputValidation.ifscCodeError) && classes.root
                  } w-beneficiary-input`}
                  id="ifsc-code"
                  name="ifsc_code"
                  variant="outlined"
                  placeholder="Enter IFSC"
                  value={customerDetails.ifscInput}
                  error={Boolean(inputValidation.ifscCodeError)}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleContactDetails}
                  disabled={selectedBeneficiary !== null}
                />
                <img
                  className={`in_not ${
                    Boolean(inputValidation.ifscCodeError) ? "show" : "hide"
                  }`}
                  src="/images/invalid-input.svg"
                  alt="invalid input icon"
                />
              </div>
            )}

            <div className="field-input">
              <label htmlFor="payee-label">
                <span className="label">Payee Name</span>
              </label>
              <TextField
                disabled={selectedBeneficiary !== null}
                className={`${
                  !Boolean(inputValidation.payeeNameError) && classes.root
                } w-beneficiary-input`}
                id="payee-label"
                name="payee_name"
                variant="outlined"
                placeholder="Enter payee name"
                value={customerDetails.payeeNameInput}
                error={Boolean(inputValidation.payeeNameError)}
                autoComplete="off"
                spellCheck="false"
                onChange={handleContactDetails}
                onBlur={handleBlur}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.payeeNameError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid input icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="customer-email-label">
                <span className="label">Payee Email</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.emailError) && classes.root
                } w-beneficiary-input`}
                id="customer-email-label"
                name="email_address"
                variant="outlined"
                placeholder="Enter customer's email"
                value={customerDetails.emailInput}
                error={Boolean(inputValidation.emailError)}
                autoComplete="off"
                spellCheck="false"
                disabled={selectedBeneficiary !== null}
                onBlur={handleBlur}
                onChange={handleContactDetails}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.emailError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid input icon"
              />
            </div>

            <div className="field-input">
              <label htmlFor="mobile-number">
                <span className="label">Mobile Number</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.mobileNoError) && classes.root
                } w-beneficiary-input`}
                id="mobile-number"
                name="mobile_number"
                variant="outlined"
                placeholder="Enter customer's mobile number"
                value={customerDetails.mobileInput}
                error={Boolean(inputValidation.mobileNoError)}
                autoComplete="off"
                inputProps={{ maxLength: 10 }}
                onBlur={handleBlur}
                onChange={handleContactDetails}
                disabled={selectedBeneficiary !== null}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.mobileNoError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid input icon"
              />
            </div>

            <div className="beneficiary-provider-code">
              <label htmlFor="provider-code-dropmenu">
                <span className="label">Provider</span>
              </label>
              <DropdownSelect
                className="w-beneficiary-input"
                setSelectedOption={setProvider}
                options={providerApiData.map((item) => ({
                  label: item.bank_name,
                  value: { providerCode: item.bank_code },
                }))}
                itemId="Select-provider-dropdown"
                label="Select Provider"
              />
            </div>
            <div className="field-input">
              <label htmlFor="address-label">
                <span className="label">Address</span>
              </label>
              <TextField
                className={`${
                  !Boolean(inputValidation.addressError) && classes.root
                } w-beneficiary-input`}
                disabled={selectedBeneficiary !== null}
                id="address-label"
                name="address"
                variant="outlined"
                placeholder="Enter customer's address"
                value={customerDetails.addressInput}
                error={Boolean(inputValidation.addressError)}
                autoComplete="off"
                spellCheck="false"
                onBlur={handleBlur}
                onChange={handleContactDetails}
              />
              <img
                className={`in_not ${
                  Boolean(inputValidation.mobileNoError) ? "show" : "hide"
                }`}
                src="/images/invalid-input.svg"
                alt="invalid input icon"
              />
            </div>

            <div className="submit-btn-container">
              <button
                disabled={buttonValidations}
                onClick={customerBeneficiaryDetails}
                className={`submit-beneficiary-details
              ${buttonValidations ? "" : "active-beneficiary-btn"}`}
              >
                Submit
                <span id="money-transfer-loader"></span>
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function BeneficiaryBankProvider(props) {
  const [TaskMenuItem, setTaskMenuItem] = React.useState("Select Bank");
  return (
    <div className="beneficiary-provider-dropdown">
      <span className="provider-show" onClick={() => ToggleActionMenu()}>
        {TaskMenuItem === "Select Bank" ? (
          <span className="placeholder-text">{TaskMenuItem}</span>
        ) : (
          <span className="provider-text">{TaskMenuItem}</span>
        )}
        <KeyboardArrowDownSharp />
      </span>
      <div className="provider-bank-option">
        <div className="provider-options">
          {props.providerApiData ? (
            props.providerApiData
              .filter((data) => data.bank_name !== TaskMenuItem)
              .map((data, index) => (
                <div key={index}>
                  <div
                    onClick={() => {
                      document
                        .querySelector(".provider-bank-option")
                        .classList.remove("active");
                      document
                        .querySelector(".provider-show")
                        .classList.remove("show-border");
                      setTaskMenuItem(data.bank_name);
                      props.setProvider({ providerCode: data.bank_code });
                    }}
                    className="option"
                  >
                    {data.bank_name}
                  </div>
                </div>
              ))
          ) : (
            <span style={{ padding: "10px 0", paddingLeft: "10px" }}>
              Loading...
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

function ToggleActionMenu() {
  document.querySelector(".provider-bank-option").classList.toggle("active");
  document.querySelector(".provider-show").classList.toggle("show-border");
}

export default MoneyTransferBeneficiaryDetails;
export const TrackedMoneyTransferBeneficiaryDetails = track(
  { entity: "Moneyy Transfer Beneficiary Form" },
  { dispatchOnMount: (contextData) => ({ action: "entityLoaded" }) }
)(MoneyTransferBeneficiaryDetails);
