import React from "react";
import ReactDOM from "react-dom";
import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import "./FailureForCTA.scss";

function FailureForCTA(props) {
  const closeFailureDialog = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById("failure-popup"));
    // window.location.reload()
  };

  return (
    <React.Fragment>
      <Dialog
        className="failure-dialog"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-failure-popup">
          <CloseIcon onClick={closeFailureDialog} />
        </div>

        <DialogContent>
          <div className="failure-dialog-heading">
            <p>{props?.header || "-"}</p>
          </div>
          <div className="failure-dialog-container">
            <div className="failure-message">
              <img src="/images/onfailure-error.svg" alt="failure-icon" />
              <h3>Please try again!</h3>
              <p>
                {props?.msg?.message ??
                  props?.msg?.error?.message ??
                  props?.msg
                }
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default FailureForCTA;
