// React Imports
import { useContext, useEffect, useState } from "react";
// Contexts
import CallbackActionContext from "../../../../contexts/CallbackActionContext";
// Styles
import "./CallbackTypeDropdownStyles.scss";
// Utils
import apiEndpointList from "../../../../config/core_banking/endpoint";
import { APIConfig } from "../../../../services/apiConfiguration";
import HandleOutsideClick from "../../../Common/HandleOutsideClick/HandleOutsideClick";

const CallbackTypeDropdown = ({ setCallbackTypeName }) => {
  const [selected, setSelected] = useState("Choose Callback Type");
  const [isActive, setIsActive] = useState(false);
  const [callbackType, setCallbackType] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const { tableEditData } = useContext(CallbackActionContext);

  const callbackList = () => {
    const check = localStorage.getItem("callbackType");
    if (check) {
      setCallbackType(JSON.parse(check));
    } else {
      setIsLoading(true)
      APIConfig.API_Client.post(
        apiEndpointList.CALLBACK_TYPE.baseUrl +
        apiEndpointList.CALLBACK_TYPE.endpoint
      )
        .then((response) => {
          setIsLoading(false)
          setCallbackType(response.data);
        })
        .catch((error) => {
          setIsLoading(false)
          console.error("from CallbackTypeDropDown", error);
        });
    }
  };

  useEffect(() => {
    callbackList();
  }, []);

  useEffect(() => {
    if (tableEditData.edit) {
      setSelected(
        tableEditData?.item?.callback_type?.replace(/_/g, " ") ||
        "Choose Callback Type"
      );
      setCallbackTypeName(
        tableEditData?.item?.callback_type || "Choose Callback Type"
      );
    }
  }, [tableEditData]);
  if (isLoading) {
    return (
      <div className="callback-loader-skeleton">
        <span className="skeleton-loader"></span>
      </div>
    );
  }
  return (
    <>
      <div className={`dropdown-callback-type`}>
        <div className="dropdown-callback-label">Callback</div>
        <div
          className={`dropdown-btn ${tableEditData.edit || callbackType.length < 1 ? "disabled" : ""} ${selected === "Choose Callback Type" ? "text-light" : "text-dark"
            } ${isActive && " border-radius__invert"} 
        `}
          onClick={(e) => callbackType.length > 0 && setIsActive(!isActive)}
        >
          {callbackType.length > 0 ? selected : "No Data Available"}
          {callbackType.length > 0 && selected.length !== 1 && (
            <span className="dropdown-arrow">
              <DownArrow isActive={isActive} />
            </span>
          )}
        </div>
        <div id="callback-type__validation-message"></div>
        {isActive &&
          <HandleOutsideClick onClickOutside={() => setIsActive(false)}>
            (
            <div className={`callback-type-content`}>
              {callbackType.map((option) => (
                <div key={option.id}>
                  {option.name.replaceAll("_", " ") !== selected && (
                    <div
                      key={option.id}
                      onClick={(e) => {
                        setSelected(option.name.replaceAll("_", " "));
                        setIsActive(false);
                        // ! To ask later.
                        setCallbackTypeName(option.name);
                      }}
                      className="dropdown-item"
                    >
                      {option.name.replaceAll("_", " ")}
                    </div>
                  )}
                </div>
              ))}
            </div>
            )
          </HandleOutsideClick>
        }
      </div>
    </>
  );
};
function DownArrow({ isActive }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-6 w-6 dropdown-arrow ${isActive && "dropdown-arrow__rotate"
        }`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}
export default CallbackTypeDropdown;
