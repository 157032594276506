import React, { useState } from "react";
// styles import
import "./ToolTip.scss";

const ToolTip = ({
  text = "This is a tooltip",
  requiredField = false,
  isLink = false,
  link = "#",
  children,
  isToolTip
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => {
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };

  let infoLink = isLink ? (
    <a
      style={{ color: "#579DFF" }}
      href={link}
      rel="noopener noreferrer"
      target="_blank"
    >
      - Click here
    </a>
  ) : (
    ""
  );

  return (
    <div
      className="tooltip-container"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {requiredField && <span className="required-mark">* </span>}
      {children}
      {isToolTip && isTooltipVisible ? (
        <div className="tooltip">
          {text} {infoLink}
        </div>
      ): null}
    </div>
  );
};

export default ToolTip;