import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import "./SuccessDialog.scss";

function SuccessDialog(props) {
  return (
    <React.Fragment>
      <Dialog className="report-success" PaperProps={{ style: { borderRadius: 10 } }} open={true} aria-labelledby="form-dialog-title">
        <div className="close-popup">
          <CloseIcon onClick={props.closeDialog} />
        </div>

        <DialogContent>
          <div className="report-success-heading">
            <p>Report</p>
          </div>
          <div className="submit-success-content">
            <img src="/images/onsuccess-tick.svg" alt="success-icon" />
            <div>
              <h3>Your Report is being generated.</h3>
            </div>
            <div>
              <p>
                The report will be sent to the mentioned email addresses soon.<br />
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default SuccessDialog;
