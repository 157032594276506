import React, { useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import DecentroBanner from "../../components/Shared/DecentroBanner/DecentroBanner";
import StickyFooter from "../../components/StickyFooter/StickyFooter";
import SnackbarMessage from "../../components/Shared/SnackbarMessage/SnackbarMessage.js";
import {
  mobileRegexValidate,
  emailRegexValidate,
} from "../../utilities/validators/inputValidators";
import "./Signup.scss";
import apiEndpointList from "../../config/core_banking/endpoint.js";

const Signup = () => {
  let formHeading, signupScreen;
  const label = { light: "Let’s get you", bold: "started!" };
  const [signupMode, setSignupMode] = useState("signup");
  const userData = [
    {
      registered_company_name: undefined,
      brand_name: undefined,
      name: undefined,
      mobile: undefined,
      email: undefined,
      password: undefined,
    },
  ];

  const errors = {
    registered_company_name: "",
    brand_name: "",
    name: "",
    mobile: "",
    email: "",
    password: "",
    confirm_password: "",
    terms_n_conditions: "",
  };

  // let requiredSignupFields = [
  //     'registered_company_name',
  //     'brand_name',
  //     'name',
  //     'mobile',
  //     'email',
  //     'password'
  // ]

  const requiredFieldErrors = {
    registered_company_name: "Company name is required",
    brand_name: "Brand name is required",
    name: "Name is required",
    mobile: "Phone number is required",
    email: "Email is required",
    password: "Password is required",
    terms_n_conditions: "Please accept Terms and Conditions",
  };

  const invalidFieldErrors = {
    mobile: "Please enter valid phone number",
    email: "Please enter valid email",
    confirm_password: "Passwords do not match",
  };

  const handleChange = (event) => {
    userData[0][event.currentTarget.name] = event.currentTarget.value;
  };

  const validateInputs = () => {
    let valid = true;
    const inputElements = document.querySelectorAll("input");
    for (let i = 0; i < inputElements.length; i++) {
      if (inputElements[i].name === "mobile") {
        valid = mobileValidationSequence(inputElements[i].name, valid);
      } else if (inputElements[i].name === "email") {
        valid = emailValidationSequence(inputElements[i].name, valid);
      } else if (inputElements[i].name === "confirm_password") {
        valid = passwordConfirmationSequence(inputElements[i].name, valid);
      } else if (inputElements[i].name === "terms_n_conditions") {
        valid = termsCheckedValidation(inputElements[i].name, valid);
      } else {
        valid = checkRequired(inputElements[i].name, valid);
      }
    }
    return valid;
  };

  const mobileValidationSequence = (key, valid) => {
    if (userData[0][key] === undefined) {
      errors[key] = requiredFieldErrors[key];
      ReactDOM.render(errors[key], document.getElementById(key + "_error"));
      valid = false;
    } else if (!mobileRegexValidate(userData[0][key])) {
      errors[key] = invalidFieldErrors[key];
      ReactDOM.render(errors[key], document.getElementById(key + "_error"));
      valid = false;
    } else {
      errors[key] = undefined;
      ReactDOM.unmountComponentAtNode(document.getElementById(key + "_error"));
    }
    return valid;
  };

  const emailValidationSequence = (key, valid) => {
    if (userData[0][key] === undefined) {
      errors[key] = requiredFieldErrors[key];
      ReactDOM.render(errors[key], document.getElementById(key + "_error"));
      valid = false;
    } else if (!emailRegexValidate(userData[0][key])) {
      errors[key] = invalidFieldErrors[key];
      ReactDOM.render(errors[key], document.getElementById(key + "_error"));
      valid = false;
    } else {
      errors[key] = undefined;
      ReactDOM.unmountComponentAtNode(document.getElementById(key + "_error"));
    }
    return valid;
  };

  const passwordConfirmationSequence = (key, valid) => {
    if (
      document.getElementById(key).value === undefined ||
      document.getElementById(key).value !== userData[0]["password"]
    ) {
      errors[key] = invalidFieldErrors[key];
      ReactDOM.render(errors[key], document.getElementById(key + "_error"));
      valid = false;
    } else {
      errors[key] = undefined;
      ReactDOM.unmountComponentAtNode(document.getElementById(key + "_error"));
    }
    return valid;
  };

  const termsCheckedValidation = (key, valid) => {
    if (!document.getElementById(key).checked) {
      errors[key] = requiredFieldErrors[key];
      ReactDOM.render(errors[key], document.getElementById(key + "_error"));
      valid = false;
    } else {
      errors[key] = undefined;
      ReactDOM.unmountComponentAtNode(document.getElementById(key + "_error"));
    }
    return valid;
  };

  const checkRequired = (key, valid) => {
    if (userData[0][key] === undefined) {
      errors[key] = requiredFieldErrors[key];
      ReactDOM.render(errors[key], document.getElementById(key + "_error"));
      valid = false;
    } else {
      errors[key] = undefined;
      ReactDOM.unmountComponentAtNode(document.getElementById(key + "_error"));
    }
    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateInputs()) {
      axios
        .post(
          apiEndpointList.USER_REGISTRATION.baseUrl +
          apiEndpointList.USER_REGISTRATION.endpoint,
          userData[0]
          // APIConfig.BASE_URL + requests.USER_REGISTRATION, userData[0]
        )
        .then(
          (response) => {
            if (response.status === 200) {
              if (response.data.response_code === "S00000") {
                setSignupMode("verifyEmail");
                ReactDOM.render(
                  <SnackbarMessage
                    msgtype="success"
                    msg="Succesfully Registered"
                  />,
                  document.getElementById("snackbar")
                );
              } else {
                ReactDOM.render(
                  <SnackbarMessage
                    msgtype="error"
                    msg={response.data.message}
                  />,
                  document.getElementById("snackbar")
                );
              }
            }
          },
          (error) => {
            ReactDOM.render(
              <SnackbarMessage msgtype="error" msg="Registration Failed" />,
              document.getElementById("snackbar")
            );
          }
        );
    }
  };

  const SignupDetails = () => {
    return (
      <div className="input-section">
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="inputs">
            <input
              className="type1"
              type="text"
              name="registered_company_name"
              placeholder="Registered Company Name"
              onChange={handleChange}
              required
            />
            <span className="error" id="registered_company_name_error"></span>
            <input
              className="type1"
              type="text"
              name="brand_name"
              placeholder="Brand Name"
              onChange={handleChange}
              required
            />
            <span className="error" id="brand_name_error"></span>
            <input
              className="type1"
              type="text"
              name="name"
              placeholder="Name"
              onChange={handleChange}
              required
            />
            <span className="error" id="name_error"></span>
            <input
              className="type1"
              type="tel"
              name="mobile"
              placeholder="Phone Number"
              onChange={handleChange}
              required
            />
            <span className="error" id="mobile_error"></span>
            <input
              className="type1"
              type="email"
              name="email"
              placeholder="E-Mail"
              onChange={handleChange}
              required
            />
            <span className="error" id="email_error"></span>
            <input
              className="type1"
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
              required
            />
            <span className="error" id="password_error"></span>
            <input
              className="type1"
              type="password"
              name="confirm_password"
              placeholder="Confirm Password"
              id="confirm_password"
              required
            />
            <span className="error" id="confirm_password_error"></span>
          </div>
          <div className="agree-terms">
            <input
              type="checkbox"
              name="terms_n_conditions"
              id="terms_n_conditions"
              required
            />
            <label htmlFor="terms_n_conditions">
              I agree with{" "}
              <a
                href="https://decentro.tech/decentro-terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            </label>
          </div>
          <span className="error" id="terms_n_conditions_error"></span>
          <button className="confirm-button" type="submit">
            Confirm
          </button>
        </form>
        <div className="signin-message">
          <p>
            Already have an account? <Link to="/login">SIGN IN</Link>
          </p>
        </div>
      </div>
    );
  };

  const VerifyEmail = () => {
    return (
      <div className="verify-email-section">
        <label>
          A verification email has been sent to your email.
          <br />
          Please verify your email before login.
        </label>
        <Link to="/login">Done? Go to Login</Link>
      </div>
    );
  };

  if (signupMode === "signup") {
    formHeading = "Fill details to create your account.";
    signupScreen = <SignupDetails />;
  } else if (signupMode === "verifyEmail") {
    formHeading = "Verify Your Email";
    signupScreen = <VerifyEmail />;
  }

  return (
    <React.Fragment>
      <div className="signup">
        <DecentroBanner label={label} />
        <div className="signup-section">
          <div className="form-section">
            <img src="./images/logo.svg" alt="logo" />
            <h1>{formHeading}</h1>
            {signupScreen}
          </div>
        </div>
      </div>
      <StickyFooter />
    </React.Fragment>
  );
};

export default Signup;
