import React from 'react';
import './SkeletonElements.scss'

function Shimmer() {
  return (
    <div className='shimmer-wrapper'>
        <div className='shimmer'></div>
        <div className='shimmer'></div>
    </div>
  )
}

export default Shimmer