import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
//UI component imports
import DateRangePicker from "../DateSelector/DateSelector";
import { DateRangePicker as CustomDateRangePicker } from "rsuite";

import DataTable from "./components/DataTable/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import { Loader } from "rsuite";
import BaseCell from "./components/BaseCell";
import SkeletonDatable from "../SkeletonComponents/SkeletonDatable";
import HandleOutsideClick from "./HandleOutsideClick/HandleOutsideClick";
//Image imports
import ArrowDown from "../../assets/images/bluedownarrow.svg";
//Style imports
import "./ReactDataTable.scss";
import DateSelectorSingle from "../DateSelectorSingle/DateSelectorSingle";
import { useLocation } from "react-router-dom";
import { capturePosthogEvent } from "../../utilities/posthogUtils";

const ReactDataTableWithoutCustomerRef = ({
  columnList,
  isModalPresent,
  modalComponent,
  tableTitle,
  provider,
  ModalDialog,
  calendar,
  showDownloadBtn = true,
}) => {
  let {
    setDateFilters,
    setFilterList,
    setInitialLoading,
    setTableData,
    fetchData,
    setNoDataFound,
    isLazyFetching,
    isLoading,
    tableData,
    filterList,
    totalCount,
    noDataFound,
    initialLoading,
    selectedTransaction,
    dateFilters,
    setLastApiTime,
    lastApiTime,
    lastInterval,
    setTotalCount,
    setShowModalDialog,
    showModalDialog,
    setIsRefreshing,
    refreshing,
    setfirstApiCall,
    firstApiCall,
    searchParameter,
    setSearchParameter,
    currentSearchScope,
    setCurrentSearchScope,
  } = React.useContext(provider);

  //States to handle the functionalitites of the table sort
  const [sortState, setSortState] = useState({
    sortBy: null,
    sortOrder: null, // true == asc, false === desc, undefined == nosort
  });
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isFiltered, setFilter] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState({});
  //states to handle the toggle of tow tables
  const [toggleEntries, setToggleEntries] = useState(false);
  const [toggleColumn, setToggleColumn] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  //states to show the csv modal
  const [showModal, setShowModal] = useState(false);

  const [searchReadOnly, setSearchReadOnly] = useState(true);
  const [advancedSearchEnabled, setAdvancedSearchEnabled] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname != "/dashboard/payments/manage-beneficiaries" &&
      location.pathname != "/dashboard/accounts/view_credits" &&
      location.pathname != "/dashboard/payments/view_credits" &&
      location.pathname != "/dashboard/financial-services/credit-reports" &&
      location.pathname != "/dashboard/kyc/view_credits" &&
      location.pathname != "/dashboard/recurring-collection/emandate" &&
      location.pathname != "/dashboard/financial-services/data-pull" &&
      location.pathname != "/dashboard/financial-services/view_credits" &&
      location.pathname.split("/")[2] != "kyc"
    ) {
      setAdvancedSearchEnabled(true);
      if (currentSearchScope) {
        if (currentSearchScope.length > 0) {
          setSearchReadOnly(false);
        } else {
          setSearchReadOnly(true);
        }
      }
    } else {
      setAdvancedSearchEnabled(false);
      setSearchReadOnly(false);
    }
  }, []);

  //variable to hold the column list prop
  const transactionColumnList = columnList;
  const columnHelper = createColumnHelper();
  //function to show the csvmodal
  const showCsvModal = () => {
    setShowModal(true);
  };
  function showConsumerId(id, original) {
    setShowPopup(true);
    setSelectedAccount(original);
  }
  //function to hide the modal
  const hideCsvModal = () => {
    setShowModal(false);
  };
  const [columnsToShow, setColumnsToShow] = useState(
    transactionColumnList.filter((item) => item.showInitial).map((x) => x.name)
  );
  //sorting function based on key
  function getSortResult(a, b, key) {
    return a[key]?.toString().localeCompare(b[key], undefined, {
      numeric: true,
      sensitivity: "base",
    });
  }
  //sorting on separate headers on the table
  function headerFn(key) {
    setSortState(({ sortBy, sortOrder }) => {
      if (sortBy === key) {
        return {
          sortBy,
          sortOrder:
            sortOrder === null ? false : sortOrder === false ? true : null,
        };
      } else {
        return {
          sortBy: key,
          sortOrder: false,
        };
      }
    });
  }
  //adding custom click events and UI components on the columns and cells
  const columns = useMemo(() => {
    const arr = transactionColumnList;
    return arr
      .filter((x) => columnsToShow.includes(x.name))
      .map((x) =>
        columnHelper.accessor(x?.accessor ?? x.name, {
          cell: x?.cell ?? BaseCell,
          header: x?.header ?? x.name,
          enableSorting: x.enableSorting ?? true,
          clickFn: x.clickFn ?? null,
          filterFn: x.filterFn,
          hasPopup: x.hasPopup,
          headerFn: x.enableSorting !== false ? headerFn : undefined,
          sortState:
            sortState.sortBy === x.accessor
              ? sortState.sortOrder === false
                ? "desc"
                : sortState.sortOrder === true
                  ? "asc"
                  : undefined
              : undefined,
          ...x,
        })
      );
  }, [columnsToShow, sortState]);
  //function to make the csv file for download
  const initializeCsv = () => {
    const csvHeaders = columnsToShow.join(",");
    const columnData = transactionColumnList;
    // Add a npm package if needed in future (explore for better speed of conversion)
    const dataTypes = columnData.filter((x) => columnsToShow.includes(x.name));

    const rows = tableData.map((x) => {
      return dataTypes.map((y) => x[y.accessor]).join(",");
    });
    const csvData =
      "data:text/csv;charset=utf-8," + [csvHeaders, ...rows].join("\n");
    const encodedUri = encodeURI(csvData);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "table-data.csv");
    document.body.appendChild(link);
    link.click();
    hideCsvModal();
  };
  const swapValue = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (!obj[key]) {
        obj[key] = "-";
      }
    });
    return obj;
  };
  const searchAllTransactions = (term) => {
    let noDataPresentForSearch = false;
    let searchData;
    if (term !== "") {
      searchData = dataToShow.filter((x) => {
        const string = term.toLowerCase();
        setNoDataFound(false);
        return JSON.stringify(x).toLowerCase().includes(string);
      });
      setIsRefreshing(false);
    }

    if (searchData.length === 0) {
      setfirstApiCall(true);
      setIsRefreshing(true);
      fetchData(
        1,
        {
          ...filterList,
          currentPage: 1,
          search: term,
        },
        undefined,
        true,
        false
      );
    }
    return noDataPresentForSearch;
  };

  //main function to use the table data and render the datatable
  const { dataToShow, maxPages, startIndex, endIndex, possibleOptions } =
    useMemo(() => {
      //to remove the null values and add '-' instead

      //search operation to strignfy the data and filter based on the input
      let data = tableData.map((d) => swapValue(d));
      if (filterList.search !== "") {
        data = data.filter((x) => {
          const string = filterList.search.toLowerCase();
          if (JSON.stringify(x).toLowerCase().includes(string)) {
            setNoDataFound(false);
            return JSON.stringify(x).toLowerCase().includes(string);
          } else {
            setNoDataFound(true);
          }
        });
      }
      let obj = {};

      //to show the headers filter option by using the keys and the respective values
      data.forEach((item) => {
        for (const [itemKey, itemValue] of Object.entries(item)) {
          if (obj[itemKey] !== undefined) {
            obj[itemKey].push(itemValue);
          } else {
            obj[itemKey] = [itemValue];
          }
        }
      });
      //filtering the values from the table to show the unique ones
      Object.keys(obj).forEach((item) => {
        obj[item] = obj[item].filter((a, i) => a && obj[item].indexOf(a) === i);
        if (obj[item]?.length > 30) {
          obj[item] = [];
        }
      });
      const possibleOptions = obj;

      Object.keys(filteredOptions).forEach((item) => {
        if (filteredOptions[item]) {
          data = data.filter(
            (fullItem) => fullItem[item] === filteredOptions[item]
          );
        }
      });
      //sorting logic based on key
      if (sortState.sortBy !== null && sortState.sortOrder !== null) {
        data = data.sort((a, b) => {
          const key = sortState.sortBy;
          if (sortState.sortOrder === false) {
            return getSortResult(a, b, key) * -1;
          }
          if (sortState.sortOrder === true) {
            return getSortResult(a, b, key);
          }
        });
      }
      //maximum pages on the table
      const maxPages = Math.ceil(data.length / filterList.resultsPerPage);
      const startIndex =
        (filterList.currentPage - 1) * filterList.resultsPerPage;
      const endIndex = startIndex + filterList.resultsPerPage;

      const paginatedData = data?.filter(
        (x, i) => i >= startIndex && i < endIndex
      );

      return {
        dataToShow: paginatedData,
        maxPages,
        startIndex,
        endIndex,
        possibleOptions,
      };
    }, [
      tableData,
      filterList,
      noDataFound,
      isFiltered,
      sortState,
      filteredOptions,
    ]);
  //function to handle the toggling between two tables
  const allColumns = useMemo(() => transactionColumnList, [showTransactions]);
  const toggleEntrie = (e) => {
    if (typeof e === "boolean") {
      setToggleEntries(e);
      return;
    }
    setToggleEntries(!toggleEntries);
  };
  const toggleColum = (e) => {
    if (typeof e === "boolean") {
      setToggleColumn(e);
      return;
    }
    setToggleColumn(!toggleColumn);
  };
  //deciding which column to show
  const handleColumnCheck = (name) => {
    if (columnsToShow.includes(name)) {
      const index = columnsToShow.findIndex((x) => x === name);
      setColumnsToShow((prev) => {
        const items = [...prev];
        items.splice(index, 1);
        return [...items];
      });
    } else {
      setColumnsToShow([...columnsToShow, name]);
    }
  };
  //the timer to show how stale the data is
  function Timer() {
    useEffect(() => { }, [lastApiTime]);
    return (
      <>
        <div className="timer" style={{ fontSize: 12, lineHeight: "20px" }}>
          {/* Updated : {lastApiTime} Min ago */}
          Refreshed {lastApiTime} Min ago
        </div>
      </>
    );
  }

  const toggleTransactions = (e) => {
    setFilterList({
      ...filterList,
      currentPage: 1,
      search: "",
    });
    setFilter(false);
    setSortState({
      sortBy: null,
      sortOrder: null, // true == asc, false === desc, undefined == nosort
    });
    setTableData([]);
    setShowTransactions(e);
    setNoDataFound(false);
    if (e) {
      setColumnsToShow(transactionColumnList.map((x) => x.name));
      return;
    }
    setInitialLoading(true);
    setColumnsToShow(transactionColumnList.map((x) => x.name));
  };
  //filtering based on the sorting and selection in the headers
  const handleFilter = (key, val) => {
    setFilteredOptions((old) => {
      let newVal = { ...old };
      newVal[key] = val;
      return newVal;
    });
  };

  // Advanced Search Option Dropdown
  function ToggleActionMenu() {
    document
      .querySelector(".advanced-search-option")
      .classList.toggle("active-cv");
    document
      .querySelector(".search-filter-wrapper")
      .classList.toggle("show-border-cv");
  }
  return (
    <>
      {showModalDialog ? (
        ModalDialog
      ) : (
        <div className="ButtonWrapper">
          <div
            style={{
              overflowX: "scroll",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* {isLoading ? (
              <SkeletonDatable />
            ) : ( */}
            <>
              <div className="total-count">
                {!showTransactions && (
                  <div className="title">
                    {tableTitle}{" "}
                    {isLoading ? (
                      <Loader className="loader default ml-2 mt-2" size="sm" />
                    ) : (
                      <span className="entriesFoundContainer">
                        {totalCount} Entries Found
                      </span>
                    )}
                  </div>
                )}

                {isModalPresent === true && (
                  <div className="virtual-accounts-btnkl">{modalComponent}</div>
                )}
              </div>

              {/* data table filters */}
              <div className="filter-data-wrapper">
                <div className="search-container">
                  {advancedSearchEnabled && currentSearchScope && (
                    <p className="search-scope">
                      Search for: <span>{currentSearchScope}</span>
                    </p>
                  )}
                  <div className="search-filter-wrapper">
                    <div className="advanced-search-container">
                      <input
                        className={
                          currentSearchScope == false ? "cursor-pointer" : ""
                        }
                        onClick={
                          currentSearchScope == false ? ToggleActionMenu : null
                        }
                        type="search"
                        placeholder="Search"
                        value={filterList.search}
                        readOnly={searchReadOnly}
                        onChange={(e) => {
                          setNoDataFound(false);
                          let val = e.target.value;
                          if (
                            e.target.value === "" &&
                            dataToShow.length === 0
                          ) {
                            setIsRefreshing(true);
                            fetchData(
                              1,
                              {
                                ...filterList,
                                currentPage: 1,
                                search: e.target.value,
                              },
                              undefined,
                              true,
                              false
                            );
                          } else if (e.target.value.length > 4) {
                            setTimeout(() => {
                              searchAllTransactions(val);
                            }, 300);
                          }
                          setFilterList({
                            ...filterList,
                            currentPage: 1,
                            search: e.target.value,
                          });
                        }}
                      />
                      {advancedSearchEnabled &&
                        filterList.search.length == 0 && (
                          <img
                            className="search-dropdown-icon"
                            src="/images/arrow-down.svg"
                            onClick={ToggleActionMenu}
                          />
                        )}
                    </div>
                    <div className="advanced-search-option">
                      <div className="options">
                        <div>
                          <div
                            onClick={() => {
                              document
                                .querySelector(".advanced-search-option")
                                .classList.remove("active-cv");
                              setSearchParameter({
                                bank_ref_number: false,
                                decentro_urn: true,
                                customer_ref_number: false,
                              });
                              setCurrentSearchScope("Decentro URN");
                              document
                                .querySelector(".search-filter-wrapper")
                                .classList.toggle("show-border-cv");
                              setSearchReadOnly(false);
                            }}
                            className="option-cv"
                          >
                            Decentro URN
                          </div>
                        </div>
                        <div>
                          <div
                            onClick={() => {
                              document
                                .querySelector(".advanced-search-option")
                                .classList.remove("active-cv");
                              setSearchParameter({
                                bank_ref_number: true,
                                decentro_urn: false,
                                customer_ref_number: false,
                              });
                              setCurrentSearchScope("Bank Ref Number");
                              document
                                .querySelector(".search-filter-wrapper")
                                .classList.toggle("show-border-cv");
                              setSearchReadOnly(false);
                            }}
                            className="option-cv"
                          >
                            Bank Ref Number
                          </div>
                        </div>
                        {/* <div>
                          <div
                            onClick={() => {
                              document
                                .querySelector(".advanced-search-option")
                                .classList.remove("active-cv");
                              setSearchParameter({
                                bank_ref_number: false,
                                decentro_urn: false,
                                customer_ref_number: true,
                              });
                              setCurrentSearchScope("Customer Ref xxNumber");
                              document
                                .querySelector(".search-filter-wrapper")
                                .classList.toggle("show-border-cv");
                              setSearchReadOnly(false);
                            }}
                            className="option-cv"
                          >
                            Customer Ref Number
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div id="datecont-wrapper">
                  {isLoading ? (
                    <>
                      <CustomDateRangePicker
                        format="DD MMM 'YY"
                        cleanable={false}
                        value={dateFilters}
                        disabled={true}
                        appearance="default"
                      />
                    </>
                  ) : (
                    <div>
                      {calendar === "single" ? (
                        <DateSelectorSingle
                          selectedDate={dateFilters}
                          format="DD MMM 'YY"
                          cleanable={false}
                          setSelectedDate={(e) => {
                            setNoDataFound(false);
                            setTableData([]);
                            setDateFilters(e);
                            setInitialLoading(true);
                            clearInterval(lastInterval);
                            setLastApiTime(0);
                            setFilterList({ ...filterList, currentPage: 1 });
                            setfirstApiCall(true);
                            fetchData(
                              1,
                              { ...filterList, currentPage: 1 },
                              e,
                              undefined,
                              false
                            );
                          }}
                        />
                      ) : (
                        <DateRangePicker
                          selectedDateRange={dateFilters}
                          format="DD MMM 'YY"
                          cleanable={false}
                          setSelectedDateRange={(e) => {
                            setNoDataFound(false);
                            setTableData([]);
                            setDateFilters(e);
                            setInitialLoading(true);
                            clearInterval(lastInterval);
                            setLastApiTime(0);
                            setFilterList({ ...filterList, currentPage: 1 });
                            setfirstApiCall(true);
                            fetchData(
                              1,
                              { ...filterList, currentPage: 1 },
                              e,
                              undefined,
                              false
                            );
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>

                {/* entries in column */}

                <div
                  className={
                    isLoading
                      ? "show-entries-wrapper-main-loading"
                      : "show-entries-wrapper-main"
                  }
                >
                  <HandleOutsideClick
                    onClickOutside={() => toggleEntrie(false)}
                  >
                    <div className="entries-wrapper-main">
                      <button onClick={isLoading ? () => { } : toggleEntrie}>
                        {/* Show {filterList.resultsPerPage} Entries
                         */}
                        {filterList.resultsPerPage} Entries
                        <img
                          className="entries-dropdown-icon"
                          src="/images/arrow-down.svg"
                          style={
                            isLoading
                              ? {
                                filter:
                                  "brightness(135%) sepia(100%) hue-rotate(200deg)",
                              }
                              : {}
                          }
                        />
                      </button>
                      <div
                        onMouseLeave={toggleEntrie}
                        className={toggleEntries ? "block-main" : "hidden"}
                      >
                        <ul>
                          {[10, 20, 30, 50].map((entr) => (
                            <li
                              onClick={() => {
                                setFilterList({
                                  ...filterList,
                                  resultsPerPage: entr,
                                  currentPage: 1,
                                });
                                toggleEntrie();
                              }}
                              key={entr}
                            >
                              {entr}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </HandleOutsideClick>
                </div>
                <div
                  className={
                    isLoading
                      ? "show-column-wrapper-main-loading"
                      : "show-column-wrapper-main"
                  }
                >
                  <HandleOutsideClick onClickOutside={() => toggleColum(false)}>
                    <div className="column-wrapper">
                      <button onClick={isLoading ? () => { } : toggleColum}>
                        {/* Manage Column */}
                        Columns
                        <span>
                          <img
                            src={ArrowDown}
                            alt="Arrow Down"
                            style={
                              isLoading
                                ? {
                                  filter:
                                    "brightness(135%) sepia(100%) hue-rotate(200deg)",
                                }
                                : {}
                            }
                          />
                        </span>
                      </button>
                      <div
                        onMouseLeave={toggleColum}
                        className={toggleColumn ? "block" : "hidden"}
                      >
                        <ul>
                          {allColumns.map((colm) => {
                            return (
                              <li key={colm.name}>
                                <label htmlFor={colm.name}>
                                  {/* <span className="checkboxIcon">
                                      {columnsToShow.includes(colm.name) && (
                                        <span className="checkIcon">✔</span>
                                      )}
                                      <input
                                        hidden
                                        onChange={() =>
                                          handleColumnCheck(colm.name)
                                        }
                                        checked={columnsToShow.includes(
                                          colm.name
                                        )}
                                        id={colm.name}
                                        type="checkbox"
                                      />
                                    </span> */}
                                  <span>{colm.name}</span>
                                  <input
                                    type="checkbox"
                                    onChange={() =>
                                      handleColumnCheck(colm.name)
                                    }
                                    checked={columnsToShow.includes(colm.name)}
                                    id={colm.name}
                                  />
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </HandleOutsideClick>
                </div>
                {showDownloadBtn ? (
                  <div
                    className={
                      isLoading
                        ? "download-csv-main-loading"
                        : "download-csv-main"
                    }
                  >
                    <button onClick={isLoading ? () => { } : showCsvModal}>
                      Download
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <div className="ml-auto self-center">{/* <Timer /> */}</div>
                <div
                  className={
                    isLoading
                      ? "download-csv-main-disable"
                      : `download-csv-main ${refreshing && "refreshing-btn"}`
                  }
                >
                  <button
                    onClick={
                      isLoading
                        ? () => { }
                        : () => {
                          capturePosthogEvent(
                            window.location.origin,
                            window.location.pathname,
                            "refresh"
                          );
                          setNoDataFound(false);
                          setIsRefreshing(true);
                          clearInterval(lastInterval);
                          setLastApiTime(0);
                          setfirstApiCall(true);
                          setFilterList({ ...filterList, currentPage: 1 });
                          fetchData(
                            1,
                            { ...filterList, currentPage: 1 },
                            undefined,
                            false
                          );
                        }
                    }
                  >
                    {refreshing || isLoading ? (
                      <>
                        <span>
                          <img
                            src="/images/Refresh-green.svg"
                            alt="refresh icon"
                          />
                        </span>
                        Fetching Data
                      </>
                    ) : (
                      <>
                        <span>
                          <img
                            src="/images/refresh-icon.svg"
                            alt="refresh icon"
                          />
                        </span>
                        {/* Refresh Table */}
                        <Timer />
                      </>
                    )}
                  </button>
                </div>

                {showModal && (
                  <div id="myModal" className="modal" onClick={hideCsvModal}>
                    <div className="modal-content">
                      <div className="modal-header">
                        <p>Download CSV</p>
                        <span className="close"></span>
                      </div>
                      <div className="modal-body">
                        <p className="main_msg">Please note !</p>
                        <p className="message">
                          Only the entries displayed in the table will be
                          downloaded.
                        </p>
                      </div>
                      <div className="button-holder">
                        <button onClick={hideCsvModal} className="cancel">
                          Cancel
                        </button>
                        <button
                          onClick={initializeCsv}
                          className="button_container_csv"
                        >
                          Download Now
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <DataTable
                optionList={possibleOptions}
                filteredOptions={filteredOptions}
                columns={columns}
                setFilter={handleFilter}
                isFiltered={isFiltered}
                data={isLoading ? null : dataToShow}
                enablePagination
                isLoading={isLoading}
                pageIndex={filterList?.currentPage}
                totalMaxPages={Math.ceil(
                  totalCount / filterList?.resultsPerPage
                )}
                maxPages={maxPages}
                // refreshing={refreshing}
                refreshing={false}
                setPageIndex={(e) => {
                  if (
                    e === maxPages - 2 ||
                    e === maxPages - 1 ||
                    e === maxPages
                  ) {
                    setfirstApiCall(false);
                  } else {
                    setfirstApiCall(true);
                  }
                  setFilterList({
                    ...filterList,
                    currentPage: e,
                  });
                }}
              />
            </>
            {/* )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default ReactDataTableWithoutCustomerRef;
