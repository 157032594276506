const REGEXP = {
  ipAddressRegex:
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  beneficiaryNamePayoutsRegex: /^[A-Za-z. ]+$/,
  panRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  customerIdRegex: /^[a-zA-Z0-9]*$/,
  nameRegex: /^[a-zA-Z]+(\s[a-zA-Z]+)*$/,
  UPIIDRegex: /^[\w.-]+@[\w.-]+$/,
  referenceIdRegex: /^[A-Za-z0-9-]+$/,
  expiryRegex: /^\d*(\.\d+)?$/,
  payerAccountRegex: /^[A-Za-z0-9]{9,18}$/,
  amountRegex: /^\d*(\.\d+)?$/,
  messageRegex: /^[^*|":<>[\]{}`\\()';@&$]+$/,
  phoneNumberRegex: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
  emailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  urlRegex:
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9|-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#|-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  ifscRegex: /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/,
  accountNumberRegex: /^[a-zA-Z0-9]{9,18}$/,
  alphanumericRegex: /^[a-zA-Z0-9]+$/,
  authCodeRegex: /^[0-9]{6}$/,
  vaRegex: /^[0-9]{9,18}$/,
  clientIDRegex: /\s/g,
  addressRegex: /^[a-zA-Z0-9.,\-/ ]+$/,
  gstRegex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  cityRegex: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  pincodeRegex: /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
  stateRegex: /^[a-zA-Z]+$/,
  districtRegex: /^[a-zA-Z]+$/,
  countryRegex: /^[a-zA-Z]+$/,
  numberRegex: /^\d+$/,
  categoryCodeRegex: /^\d{3,4}$/,
  categoryRegex: /^[a-zA-Z0-9\s&/–-]+$/,
  UPIRegex: /^[a-zA-Z0-9.-]{2,256}@[a-zA-Z][a-zA-Z]{2,64}$/,
  alphanumericWithWhitespaceRegex: /^[a-zA-Z0-9 ]+$/,
};

const numberRegexValidate = (number) => {
  if (number.match(REGEXP.numberRegex)) {
    return true;
  }
  return false;
};

const merchantCategoryCodeRegexValidate = (number) => {
  if (number.match(REGEXP.categoryCodeRegex)) {
    return true;
  }
  return false;
};

const merchantCategoryRegexValidate = (number) => {
  if (number.match(REGEXP.categoryRegex)) {
    return true;
  }
  return false;
};

const countryRegexValidate = (country) => {
  if (country.match(REGEXP.countryRegex)) {
    return true;
  }
  return false;
};

const districtRegexValidate = (district) => {
  if (district.match(REGEXP.districtRegex)) {
    return true;
  }
  return false;
};

const stateRegexValidate = (state) => {
  if (state.match(REGEXP.stateRegex)) {
    return true;
  }
  return false;
};

const pincodeRegexValidate = (pincode) => {
  if (pincode.match(REGEXP.pincodeRegex)) {
    return true;
  }
  return false;
};

const cityRegexValidate = (cityName) => {
  if (cityName.match(REGEXP.cityRegex)) {
    return true;
  }
  return false;
};

const gstRegexValidate = (gstNumber) => {
  if (gstNumber.match(REGEXP.gstRegex)) {
    return true;
  }
  return false;
};

const addressRegexValidate = (address) => {
  if (address.match(REGEXP.addressRegex)) {
    return true;
  }
  return false;
};

const mobileRegexValidate = (phoneNumber) => {
  if (phoneNumber.match(REGEXP.phoneNumberRegex)) {
    return true;
  }
  return false;
};

const clientIDRegexValidate = (clientID) => {
  if (clientID.match(REGEXP.clientIDRegex)) {
    return false;
  }
  return true;
};

const emailRegexValidate = (email) => {
  if (email.match(REGEXP.emailRegex)) {
    return true;
  }
  return false;
};

const urlRegexValidate = (url) => {
  if (url.match(REGEXP.urlRegex)) {
    return true;
  }
  return false;
};

const ifscValidation = (value) => {
  if (value.match(REGEXP.ifscRegex)) {
    return true;
  }
  return false;
};

const accountNumberValidation = (value) => {
  if (value.match(REGEXP.accountNumberRegex)) {
    return true;
  }
  return false;
};

const alphanumericRegexValidate = (value) => {
  if (value.match(REGEXP.alphanumericRegex)) {
    return true;
  }
  return false;
};

export {
  mobileRegexValidate,
  emailRegexValidate,
  urlRegexValidate,
  ifscValidation,
  accountNumberValidation,
  alphanumericRegexValidate,
  REGEXP,
  clientIDRegexValidate,
  addressRegexValidate,
  gstRegexValidate,
  cityRegexValidate,
  numberRegexValidate,
  countryRegexValidate,
  stateRegexValidate,
  pincodeRegexValidate,
  districtRegexValidate,
  merchantCategoryCodeRegexValidate,
  merchantCategoryRegexValidate,
};