import React, { useState, useEffect, useRef, useMemo } from "react";

// styles import
import "./DropDownSelectReports.scss";

function DropDownSelectReports({
  options = [],
  setSelectedOption = (e) => e,
  setSelectedProviderId,
  selectedOption,
  itemId,
  placeholder = "Select Option",
  searchable = false,
  style = {},
  className = "",
  showArrow,
  disabledField = false,
  loading = false,
}) {
  function ToggleActionMenu() {
    document.querySelector(`.${itemId}`).classList.toggle("active-dropdown");
    document.querySelector(`.${itemId}-show`).classList.toggle("show-border");
    if (
      searchValue !== localSelectedOption &&
      localSelectedOption !== placeholder
    ) {
      setSearchValue(localSelectedOption);
    }
  }
  const [searchValue, setSearchValue] = useState("");
  const [localSelectedOption, setLocalSelectedOption] = React.useState(
    selectedOption ?? placeholder
  );
  const [MenuActive, setMenuActive] = React.useState(false);

  const optionsToUse = useMemo(() => {
    if (options.length < 1) {
      return "";
    }
    if (searchable) {
      return options
        .filter((item) =>
          item.provider_name?.toLowerCase().includes(searchValue?.toLowerCase())
        )
        .filter(
          (item) =>
            item.provider_name !== searchValue ||
            (item.provider_name === searchValue &&
              item.provider_name !== localSelectedOption)
        );
    }

    return options.filter(
      (data) => data?.provider_name !== localSelectedOption
    );
  }, [options, searchValue]);

  const itemRef = useRef(null);
  function handleOutsideClick(e) {
    if (e.target !== itemRef.current && !itemRef.current?.contains(e.target)) {
      document.querySelector(`.${itemId}`).classList.remove("active-dropdown");
      document.querySelector(`.${itemId}-show`).classList.remove("show-border");
      // * down arrow flip state
      setMenuActive(false);
      if (
        searchValue !== localSelectedOption &&
        localSelectedOption !== placeholder
      ) {
        setSearchValue(localSelectedOption);
      }
    }
  }

  function handleFilter(e) {
    setSearchValue(e.target.four_character_bank_code);
    // ToggleActionMenu();
    if (
      document.querySelector(`.${itemId}`).classList.contains("active-dropdown")
    ) {
      return;
    }
    document.querySelector(`.${itemId}`).classList.add("active-dropdown");
    document.querySelector(`.${itemId}-show`).classList.add("show-border");
  }

  useEffect(() => {
    document.removeEventListener("click", handleOutsideClick);
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  });

  useEffect(() => {
    document.removeEventListener("click", handleOutsideClick);
    document.addEventListener("click", handleOutsideClick);

    // Reset the default value when options are updated
    setLocalSelectedOption(placeholder);
    setSearchValue("");

    setSelectedOption(null);
    setSelectedProviderId(null);
  }, [options]);

  return (
    <div
      style={style}
      ref={itemRef}
      className={`provider-dropdowns ${
        disabledField && "disable-dropdown"
      } ${className} ${MenuActive && `max-z-index`}`}
    >
      <span
        className={`${itemId}-show provider-show`}
        onClick={() => {
          setMenuActive(!MenuActive);
          ToggleActionMenu();
        }}
      >
        {searchable ? (
          <input
            className="dropdown-input"
            onChange={handleFilter}
            style={{ width: "100%" }}
            value={searchValue}
            placeholder={placeholder}
          />
        ) : localSelectedOption === placeholder ? (
          <span className="placeholder-text">{localSelectedOption}</span>
        ) : (
          <span className="provider-text-er">{localSelectedOption}</span>
        )}
        {showArrow !== false ? (
          <img
            style={{
              transform: MenuActive ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="/images/arrow-down.svg"
            alt="dropdwon-arrow"
          />
        ) : (
          ""
        )}
      </span>
      <div className={`${itemId} provider-bank-option`}>
        <div className="provider-options">
          {loading === true ? (
            <span style={{ padding: "10px 0", paddingLeft: "10px" }}>
              Loading...
            </span>
          ) : optionsToUse ? (
            optionsToUse
              .filter((data) => data?.provider_name !== selectedOption)
              .map((data, index) => (
                <div key={index}>
                  <div
                    onClick={() => {
                      setMenuActive(!MenuActive);
                      setLocalSelectedOption(data?.provider_name);
                      setSelectedOption(data?.four_character_bank_code);
                      ToggleActionMenu();
                      setSelectedProviderId?.(data?.provider_id);
                      if (searchable) {
                        setSearchValue(data?.provider_name);
                      }
                    }}
                    className="option"
                  >
                    {data?.provider_name}
                  </div>
                </div>
              ))
          ) : (
            <span
              style={{
                padding: "20px 10px",
                paddingLeft: "10px",
                fontSize: "13px",
                fontWeight: 400,
              }}
            >
              No results Found
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default DropDownSelectReports;
