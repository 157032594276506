import React from "react";
import  ReactDOM  from 'react-dom';
import DataTableModal from './DataTableModal/DataTableModalCollection';

const ShowTransactionModalCell = (props) => {
  const rowDatadata = props.row.original
  const value = props.getValue();
  
  
  function openTransactionDialog(){
    ReactDOM.render(
        <DataTableModal data={rowDatadata} />,
        document.getElementById("virtual-accounts-components")
      );
  }
  return (
    <div style={{"color":"#0092ff"}} onClick={openTransactionDialog}>
      {value}
    </div>
  );
};

export default ShowTransactionModalCell;
