import React from "react";
import ReactDOM from "react-dom";
//ui elements
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";
//styles
import "./ManageBeneficiarySuccess.scss";
import SnackbarMessage from "../../Shared/SnackbarMessage/SnackbarMessage";

let successResponseData;

function ManageBeneficiarySuccess(props) {
  const closeSuccessDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("manage-beneficiary-components")
    );
    window.location.reload();
  };

  function copyResponseDetail() {
    const elTable = document.querySelector(".configure-account-data");
    let range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
          document.getElementById("snackbar")
        );
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }
    sel.removeAllRanges();
  }
  successResponseData = props.data;
  return (
    <React.Fragment>
      <Dialog
        className="user-config-success"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-success-popup">
          <CloseIcon onClick={closeSuccessDialog} />
        </div>

        <DialogContent>
          <div className="dialog-heading">
            <p>Manage Beneficiary</p>
          </div>
          <div className="dialog-content-container">
            <div className="success-message">
              <img
                src="/images/onsuccess-tick.svg"
                className="img-copy"
                alt="success-icon"
              />
              {props?.type === "ADD" ? (
                <h3>{props?.headingMsg ?? "Beneficiary Added successfully!"}</h3>
              ) : (
                <h3>{props?.headingMsg ?? "Beneficiary Modified successfully!!"}</h3>
              )}
            </div>
            <div className="success-account-details">
              <table className="configure-account-data">
                <tbody>
                  {/* {successResponseData?.beneficiaryName != undefined &&
                    successResponseData?.beneficiaryName != null && (
                      <tr>
                        <td className="detail-heading">Beneficiary Name</td>
                        <td className="detail-value title-case">
                          {successResponseData.beneficiaryName}
                        </td>
                      </tr>
                    )} */}

                  {/* <tr>
                    <td className="detail-heading">Beneficiary Code</td>
                    <td className="detail-value">
                      {successResponseData.beneficiaryCode}
                    </td>
                  </tr> */}

                  {/* <tr>
                    <td className="detail-heading">IFSC</td>
                    <td className="detail-value">{successResponseData.ifsc}</td>
                  </tr>

                  <tr>
                    <td className="detail-heading">Account Number</td>
                    <td className="detail-value">
                      {successResponseData.accountNumber}
                    </td> */}
                  <tr>
                    <td className="detail-heading">Beneficiary Code</td>
                    <td className="detail-value">
                      {successResponseData.beneficiaryCode}
                    </td>
                  </tr>
                  {/* </tr> */}
                </tbody>
              </table>
            </div>
            <div className="success-btn-container">
              <button
                className="copy-account-details"
                onClick={copyResponseDetail}
                type="button"
              >
                <img src="/images/copy-icon.svg" alt="copy-icon" />
                Copy
              </button>
              <button onClick={closeSuccessDialog} className="success-btn-done">
                Done
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default ManageBeneficiarySuccess;
