import React, { useState } from "react";
import ReactDOM from "react-dom";

import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/core_banking/endpoint";
import unMountPreviousComponent from "../../utilities/unMountPreviousComponent";
import { Loader } from "rsuite";

import FailureDialog from "../Shared/FailureDialog/FailureDialog";
import MoneyTransferDetails from "../MoneyTransferDetails/MoneyTransferDetails";
import MoneyTransferSuccessDialog from "../MoneyTransferSuccessDialog/MoneyTransferSuccessDialog";
import MoneyTransferBeneficiaryDetails, {
  TrackedMoneyTransferBeneficiaryDetails,
} from "../MoneyTransferBeneficiaryDetails/MoneyTransferBeneficiaryDetails";

import "./MoneyTransfer.scss";
import FailureForCTA from "../Shared/FailureForCTA/FailureForCTA";
import track, { useTracking } from "react-tracking";
import { useEffect } from "react";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

function MoneyTransfer(props) {
  let customerInputData;

  const customerInputDetail = (inputData) => {
    customerInputData = inputData;
    ReactDOM.render(
      <TrackedMoneyTransferBeneficiaryDetails
        customerInputData={customerInputData}
        beneficiaryInputDetail={beneficiaryInputDetail}
      />,
      document.getElementById("virtual-accounts-components")
    );
  };

  let beneficiaryInputData;
  let bankProvider;

  const beneficiaryInputDetail = (inputData, provider) => {
    beneficiaryInputData = inputData;
    bankProvider = provider;
    initiateMoneyTransferApi();
  };

  const onTrigger = (event) => {
    props.setRefreshCall(true);
  };
  const initiateMoneyTransferApi = () => {
    const isDropdownSelected = beneficiaryInputData?.isDropdownSelected;
    const to_account = beneficiaryInputData.to_account;
    delete beneficiaryInputData.to_account;
    let payloadData = {
      ...customerInputData,
      to_account,
      ...(isDropdownSelected
        ? { beneficiary_code: beneficiaryInputData.beneficiary_code }
        : { beneficiary_details: beneficiaryInputData }),
    };

    ReactDOM.render(
      <Loader />,
      document.getElementById("money-transfer-loader")
    );

    APIConfig.API_Client.defaults.headers.post["provider_code"] =
      bankProvider.providerCode;

    APIConfig.API_Client.post(
      apiEndpointList.MONEY_TRANSFER.baseUrl +
        apiEndpointList.MONEY_TRANSFER.endpoint,
      payloadData,
      { cancelToken: source.token }
    )
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("money-transfer-loader")
        );

        unMountPreviousComponent("virtual-accounts-components");

        ReactDOM.render(
          <MoneyTransferSuccessDialog data={response.data} />,
          document.getElementById("virtual-accounts-components")
        );
        setTimeout(onTrigger(), 5000);
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("money-transfer-loader")
        );

        unMountPreviousComponent("virtual-accounts-components");

        try {
          onTrigger();
          ReactDOM.render(
            <FailureForCTA
              header={"Money Transfer"}
              msg={error.response.data.error.message}
            />,
            document.getElementById("failure-popup")
          );
        } catch (error) {
          ReactDOM.render(
            <FailureForCTA
              header={"Money Transfer"}
              msg={"Something went wrong please try again later"}
            />,
            document.getElementById("failure-popup")
          );
        }
      });
  };
  useEffect(() => {
    let temp = JSON.parse(localStorage.getItem("user"));
  }, []);

  const mountMoneyTransfer = () => {
    ReactDOM.render(
      <MoneyTransferDetails customerInputDetail={customerInputDetail} />,
      document.getElementById("virtual-accounts-components")
    );
  };

  return (
    <React.Fragment>
      <div onClick={mountMoneyTransfer} className="money-transfer-btn">
        Initiate Payout
      </div>
    </React.Fragment>
  );
}

export default MoneyTransfer;

export const TrackedMoneyTransfer = track(
  { entity: "Moneyy Transfer CTA" },
  { dispatchOnMount: (contextData) => ({ action: "entityLoaded" }) }
)(MoneyTransfer);
