import React, { useState, useContext, useEffect } from "react";
import KeyboardArrowDownSharp from "@material-ui/icons/KeyboardArrowDownSharp";
import axios from "axios";
import "./KycOnboardingForm.scss";
import { APIConfig } from "../../../../services/apiConfiguration";
import ReactDOM from "react-dom";
import { Loader } from "rsuite";
import Select from "react-select";
import { makeStyles } from "@material-ui/core/styles";
import makeAnimated from "react-select/animated";
import apiEndpointList from "../../../../config/core_banking/endpoint";
import SnackbarMessage from "../../../Shared/SnackbarMessage/SnackbarMessage";
import { KycContextProvider } from "../../KycContext";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0092ff",
    },
  },
});
function KycOnboardingForm({ data, tableEditRow }) {
  const { refreshTable } = useContext(KycContextProvider);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const [selectedOption, setSelectedOption] = React.useState("");
  const [providerApiData, setProviderApiData] = React.useState("");
  const cvaProvider = () => {
    APIConfig.API_Client.post(
      apiEndpointList.PROVIDER_ACCOUNTS.baseUrl +
        apiEndpointList.PROVIDER_ACCOUNTS.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setProviderApiData(response.data);
      })
      .catch((error) => {});
  };
  let addressRegex = /^[^*|":<>[\]{}`\\()';@&$]+$/;
  const animatedComponents = makeAnimated();
  const options = [
    { value: "PAN", label: "PAN" },
    { value: "GSTIN", label: "GSTIN" },
    { value: "Driving_License", label: "Driving License" },
    { value: "CIN", label: "CIN" },
    { value: "LLPIN", label: "LLPIN" },
    { value: "RC", label: "RC" },
  ];
  const [error, setError] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);
  const [verifyKyc, setVerifyKyc] = React.useState({
    kycVerifyToggle: +true,
    decentroKycToggle: +true,
  });
  //regex for validation
  let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  let mobileRegex = /^[6-9]\d{9}$/;
  const usernameRegex = /^[a-zA-Z ]+$/;
  let ifscRegex = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;

  //states

  let upiCollectionInputData;
  let bankProvider;
  const [inputValidationBtn, setInputValidationBtn] = React.useState({
    nameError: "",
    panError: "",
    emailError: "",
    mobileNoError: "",
    addressError: "",
    customerIdError: "",
    minimumBalanceError: "",
    transactionLimitError: "",
  });
  const [kycInputDetails, setKycInputDetails] = React.useState({
    nameInput: "",
    emailInput: "",
  });

  const [inputValidation, setInputValidation] = React.useState({
    nameError: "",
    emailError: "",
  });
  const [btnValidationData, setBtnValidationData] = React.useState({
    customerName: "",
    customerEmail: "",
  });
  async function kycOnboardingLinkApi() {
    ReactDOM.render(<Loader />, document.getElementById("user-config-loader"));
    await APIConfig.API_Client.post(
      apiEndpointList.KYC_ONBOARDING_GENERATE_LINK.baseUrl +
        apiEndpointList.KYC_ONBOARDING_GENERATE_LINK.endpoint,
      // APIConfig.BASE_URL + "/kyc_onboarding/generate_link",
      {
        name: payloadData.name,
        email: payloadData.email,
        documents: payloadData.documents,
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={"Link Generated Successfully"}
          />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        try {
          ReactDOM.render(
            <SnackbarMessage
              msgtype="error"
              msg={error.response.data.message}
            />,
            document.getElementById("snackbar")
          );
        } catch (error) {
          ReactDOM.render(
            <SnackbarMessage
              msgtype="error"
              msg={"Something went wrong please try again later"}
            />,
            document.getElementById("snackbar")
          );
        }
      });
  }

  let payloadData;
  const classes = useStyles();
  const handleBlur = (e) => {
    let { name, value } = e.target;

    if (name === "customerName") {
      let nameRegex = /^[a-zA-Z ]+$/;
      if (!value.match(nameRegex)) {
        setBtnValidationData({ ...btnValidationData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          nameError: "Invalid format",
        });
      }
    }
    if (name === "customerEmail") {
      let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!value.match(emailRegex)) {
        setBtnValidationData({ ...btnValidationData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          emailError: "Invalid format",
        });
      }
    }
  };

  const handleContactDetails = (e) => {
    let { name, value } = e.target;

    if (name === "customerName") {
      setKycInputDetails({ ...kycInputDetails, nameInput: value });
      let nameRegex = /^[a-zA-Z ]+$/;

      if (value.match(nameRegex)) {
        setBtnValidationData({ ...btnValidationData, [name]: value });
        setInputValidation({ ...inputValidation, nameError: "" });
      } else {
        setBtnValidationData({ ...btnValidationData, [name]: "" });
      }
    }
    if (name === "customerEmail") {
      setKycInputDetails({ ...kycInputDetails, emailInput: value });
      let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (value.match(emailRegex)) {
        setBtnValidationData({ ...btnValidationData, [name]: value });
        setInputValidation({ ...inputValidation, emailError: "" });
      } else {
        setBtnValidationData({ ...btnValidationData, [name]: "" });
      }
    }
  };

  const submitCustomerData = () => {
    let filteredDocuments = selectedOption.map((item) => {
      return item.value;
    });

    let customerInfo = {
      name: kycInputDetails.nameInput,
      email: kycInputDetails.emailInput,
      documents: filteredDocuments,
    };
    kycCustomerDetails(customerInfo);
  };

  const kycCustomerDetails = (data) => {
    payloadData = data;
    kycOnboardingLinkApi();
  };

  const boardingSubmitDetail = (e) => {
    e.preventDefault();
  };

  const closeOnboardingDetailDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("kyc-onboarding-components")
    );
    refreshTable();
  };

  let [submitBtnValidation, setSubmitBtnValidation] = useState(true);

  useEffect(() => {
    if (
      kycInputDetails.emailInput !== "" &&
      kycInputDetails.nameInput !== "" &&
      inputValidation.emailError === "" &&
      inputValidation.nameError === "" &&
      selectedOption !== ""
    ) {
      setSubmitBtnValidation(false);
    } else {
      setSubmitBtnValidation(true);
    }
  }, [kycInputDetails, selectedOption]);
  // React.useEffect(() => {
  //   cvaProvider();
  // }, []);
  return (
    <>
      <div className="main-card-container">
        {/* The company and user type search */}

        {/* main form */}
        <div className="input-user-form-fields">
          <div className="email-name-main">
            <div className="label email">
              <div className="label-tag">Customer Name</div>
              <div
                className={`user-input ${
                  Boolean(inputValidation.nameError) && "error-border"
                }`}
              >
                <input
                  className={`user-input-field`}
                  type="text"
                  id="name"
                  name="customerName"
                  placeholder="Enter Customers Name"
                  value={kycInputDetails.nameInput}
                  onChange={handleContactDetails}
                  onBlur={handleBlur}
                  autoComplete="false"
                />
              </div>
            </div>
          </div>
          <div className="user-name-main">
            <div className="label name">
              <div className="label-tag">Customer's Email</div>
              <div
                className={`user-input ${
                  Boolean(inputValidation.emailError) && "error-border"
                }`}
              >
                <input
                  className={`user-input-field`}
                  id="sender-label"
                  name="customerEmail"
                  variant="outlined"
                  // disabled={tableEditRow}
                  placeholder="Enter customer’s email"
                  value={kycInputDetails.emailInput}
                  onChange={handleContactDetails}
                  onBlur={handleBlur}
                  autoComplete="false"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="input-user-form-fields">
          <div className="user-name-main">
            <div className="label name">
              <div className="label-tag" style={{ display: "inline" }}>
                Select Documents
              </div>
              <div
                className={`user-input ${
                  Boolean(inputValidation.emailError) && "error-border"
                }`}
              >
                <Select
                  id="document-label"
                  className="react-select"
                  closeMenuOnSelect={false}
                  isSearchable={false}
                  noOptionsMessage={() => null}
                  components={animatedComponents}
                  defaultValue={""}
                  placeholder="Select all required documents"
                  isMulti
                  onChange={setSelectedOption}
                  options={options}
                />
              </div>
            </div>
          </div>

          <div className="user-name-main"></div>
        </div>

        <div className="input-user-form-fields">
          <div className="button-container">
            <button
              onClick={submitCustomerData}
              type="button"
              disabled={submitBtnValidation}
              className={`submit-btn ${
                submitBtnValidation === true ? "" : "active-kyc"
              }`}
            >
              Submit
              <span id="user-config-loader" style={{ display: "flex" }}></span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
function VirtualAccountsTaskMenu(props) {
  const [TaskMenuItem, setTaskMenuItem] = React.useState("Select Bank");

  return (
    <div className="provider-dropdown-cv">
      <span className="provider-show-cv" onClick={ToggleActionMenu}>
        {TaskMenuItem === "Select Bank" ? (
          <span className="placeholder-text-cv">{TaskMenuItem}</span>
        ) : (
          <span className="provider-text-cv">{TaskMenuItem}</span>
        )}
        <KeyboardArrowDownSharp />
      </span>
      <div className="provider-bank-option-cv">
        <div className="provider-options-cv">
          {props.providerApiData ? (
            props.providerApiData
              .filter((data) => data.bank_name !== TaskMenuItem)
              .map((data, index) => (
                <div key={index}>
                  <div
                    onClick={() => {
                      document
                        .querySelector(".provider-bank-option-cv")
                        .classList.remove("active-cv");
                      document
                        .querySelector(".provider-show-cv")
                        .classList.remove("show-border-cv");
                      setTaskMenuItem(data.bank_name);
                      props.setSelectedProvider(data.bank_code);
                    }}
                    className="option-cv"
                  >
                    {data.bank_name}
                  </div>
                </div>
              ))
          ) : (
            <span style={{ padding: "10px 0", paddingLeft: "10px" }}>
              Loading...
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
function TransferType(props) {
  let initialTask;
  let transferTypeArray = ["RTGS", "NEFT", "UPI", "IMPS"];
  if (props.editable) {
    initialTask = props.transfer_type_data;
  } else {
    initialTask = "Select Transfer type";
  }
  const [TaskMenuItem, setTaskMenuItem] = React.useState(initialTask);
  let { transfer_type } = props.userInputData;
  if (props.editable) {
    transfer_type = props.transfer_type_data;
  }
  React.useEffect(() => {
    props.setUserDetails({
      ...props.userDetails,
      // toAccountInput: "",
      transfer_type: transfer_type,
    });
    // delete props.inputData["beneficiary_account_number"];
    delete props.userInputData["to_upi"];
  }, [transfer_type]);

  return (
    <div className={`provider-dropdown ${props.editable && "disabled"}`}>
      <span
        className={`provider-text ${props.editable && "disabled"}`}
        onClick={ToggleActionMenuTransfer}
      >
        {TaskMenuItem === "Select Transfer type" ? (
          <span className="placeholder-text">{TaskMenuItem}</span>
        ) : (
          <span className="provider-text-span">{TaskMenuItem}</span>
        )}
        {!props.editable && <KeyboardArrowDownSharp />}
      </span>

      <div className="provider-bank-option">
        <div className="provider-options">
          {transferTypeArray
            .filter((data) => data !== TaskMenuItem)
            .map((data, index) => (
              <div
                key={index}
                onClick={() => {
                  document
                    .querySelector(".provider-text")
                    .classList.remove("showl");
                  document
                    .querySelector(".provider-bank-option")
                    .classList.remove("active-kyc");

                  setTaskMenuItem(data);
                  props.setUSerInputData({
                    ...props.userInputData,
                    transfer_type: data,
                  });
                }}
                className="option"
              >
                {data}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
function ToggleActionMenu() {
  document
    .querySelector(".provider-bank-option-cv")
    .classList.toggle("active-cv");
  document
    .querySelector(".provider-show-cv")
    .classList.toggle("show-border-cv");
}
function ToggleActionMenuTransfer() {
  document
    .querySelector(".provider-bank-option")
    .classList.toggle("active-kyc");
  document.querySelector(".provider-text").classList.add("showl");
}

export default KycOnboardingForm;
