import React from 'react';
import SkeletonElements from './SkeletonElements';
import Shimmer from './Shimmer';

function SkeletonAnalytics() {
    return (
      <div className='analytics'>
          <div className='title-holder'>
            <SkeletonElements type="big_card"/>
          </div>
          <div className='analytics-wrapper'>
              {[1,2,3].map((n)=><SkeletonElements type="card" key={n}/>)}
              
          </div>
          <Shimmer/>
         
          
  
  
      </div>
    )
  }
  
  export default SkeletonAnalytics