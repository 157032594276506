const DownloadCell = (value) => {

    if (value.row.original.download_url !== "-") {

        // Convert value to a Date object
        const dateTime = new Date(value.row.original.created_on);

        // Calculate the current date
        const currentDate = new Date();

        // Calculate the difference in milliseconds between current date and transaction date
        const differenceInMillis = currentDate.getTime() - dateTime.getTime();

        // Convert milliseconds to days
        const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24);

        // Check if the difference is less than 30 days
        const canDownload = differenceInDays < 30;

        const Downloadable = <a href={value.row.original.download_url}><img className="download-report-icon" src="/images/download_item.svg" alt="download-icon" /></a>
        const UnDownloadable = <div className="report-download-expired">EXPIRED</div>;
        return <div className="reports-download-cta">{canDownload ? Downloadable : UnDownloadable}</div>;
    } else {
        return <div className="reports-download-cta"><div className="blank-report">-</div></div>;
    }
};

export default DownloadCell