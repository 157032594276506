import React from 'react';
import './Loader.scss';

const Loader = () => (
    <div className="loader-div">
        <img src="/images/loader.gif" alt="loader" />
    </div>
);

export default Loader;
