import React, { useContext } from "react";
import ReactDOM from "react-dom";
// Utils
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";
// Styles
import "./ValidateAccountSuccessDialog.scss";

let successDetails;

function ValidateAccountSuccessDialog(props) {
    const closeSuccessDialog = () => {
        ReactDOM.unmountComponentAtNode(
            document.getElementById("payments-components")
        );
        if (successDetails?.status === "success") {
            window.location.reload();
        }
    };

    successDetails = props.successResponse;

    let responseTexts = {
        success: `${successDetails?.validationType ? `${successDetails?.validationType} is successful!` : successDetails?.status}`,
        failure: `${successDetails?.validationType ? `${successDetails?.validationType} failed!` : successDetails?.status}`,
        pending: `${successDetails?.validationType ? `${successDetails?.validationType} status is pending` : successDetails?.status}`
    }

    return (
        <React.Fragment>
            <Dialog
                className="validate-account-success"
                PaperProps={{ style: { borderRadius: 10 } }}
                open={true}
                aria-labelledby="form-dialog-title"
            >
                <div className="close-success-popup">
                    <CloseIcon onClick={closeSuccessDialog} />
                </div>

                <DialogContent>
                    <div className="dialog-heading">
                        <p>{successDetails?.status || ""}</p>
                    </div>
                    <div className="dialog-content-container">
                        <div className="success-message">
                            {successDetails?.status === "success" ?
                                <>
                                    <img src="/images/Success.svg" alt="success-icon" />
                                    <h3 className="response-text">{responseTexts.success}</h3>
                                </>
                                :
                                successDetails?.status === "failure" ?
                                    <>
                                        <img src="/images/Failed.svg" alt="failed-icon" />
                                        <h3 className="response-text">{responseTexts.failure}</h3>
                                    </>
                                    :
                                    successDetails?.status === "pending" ?
                                        <>
                                            <img src="/images/Pending.svg" alt="pending-icon" />
                                            <h3 className="response-text">{responseTexts.pending}</h3>
                                        </>
                                        :
                                        null
                            }
                            <p>{successDetails?.message}</p>
                        </div>
                        <div className="success-account-details">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="accounts-heading">Decentro URN</td>
                                        <td className="accounts-details title-case">
                                            {successDetails?.decentroTxnId || ""}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="accounts-heading">Account Status</td>
                                        <td className="accounts-details title-case">
                                            {successDetails?.accountStatus || ""}
                                        </td>
                                    </tr>
                                    {successDetails?.beneficiaryName && <tr>
                                        <td className="accounts-heading">Beneficiary Name</td>
                                        <td className="accounts-details title-case">
                                            {successDetails?.beneficiaryName || ""}
                                        </td>
                                    </tr>}
                                    <tr>
                                        <td className="accounts-heading">Bank Ref No.</td>
                                        <td className="accounts-details title-case">
                                            {successDetails?.bankReferenceNumber || ""}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="success-btn-container">
                            <button onClick={closeSuccessDialog} className="success-btn-done">
                                Done
                            </button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default ValidateAccountSuccessDialog;
