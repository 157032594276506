import React from "react";
import  ReactDOM  from 'react-dom';
import DataTableModal from './DataTableModal/DataTableModal';

const ShowBlueValue = (props) => {
 
  const value = props.getValue();
  
  
  return (
    <div style={{"color":"#0092ff","cursor":"pointer"}} >
      {value}
    </div>
  );
};

export default ShowBlueValue;
